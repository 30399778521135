import { UploadFile } from '@mui/icons-material'
import * as S from './styles'
import { ChangeEvent, DragEvent, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

interface DragAndDropFileProps {
  file: File | null
  setFile: (event: File) => void
}

export function DragAndDropFile({ file, setFile }: DragAndDropFileProps) {
  function handleSelectFile(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target

    if (!files) {
      return
    }

    setFile(files[0])
  }

  function handleDrop(event: DragEvent<HTMLDivElement | HTMLLabelElement>) {
    event.stopPropagation()
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0]
      if (file.type !== 'text/xml') {
        return toast.error('Apenas arquivos XML')
      }
      setFile(file)
    }
  }

  const fileName = useMemo(() => (file?.name ? file.name : null), [file])

  return (
    <S.DragAndDropContainer onDrop={handleDrop}>
      <label htmlFor="file">
        <UploadFile />
        <span>
          {fileName
            ? fileName
            : 'Arraste e solte o arquivo aqui ou clique para selecionar um'}
        </span>
      </label>
      <input
        type="file"
        name="file"
        id="file"
        accept=".xml"
        hidden
        onChange={handleSelectFile}
      />
    </S.DragAndDropContainer>
  )
}

import { Col, Modal, Row, Stack } from 'react-bootstrap'
import { useGlobalInfos } from '../../../../../hooks/useGlobalInfos'
import Button from '../../../../../Components/Button'
import { useExportInventoryItems } from './hooks'
import TextField from '../../../../../Components/Forms/TextField'
import Select from '../../../../../Components/Forms/Select'
import { useProducts } from '../../../../Products/hooks/useProducts'
import { useEffect, useMemo } from 'react'
import { formatProductsToAutocomplete } from '../../../../InventoryMovements/formaters'
import { Autocomplete } from '../../../../../Components/Forms/Autocomplete'

interface ExportInventoryItemsModalProps {
  isOpen: boolean
  handleCloseModal: () => void
}

export function ExportInventoryItemsModal({
  isOpen,
  handleCloseModal,
}: ExportInventoryItemsModalProps) {
  const {
    isExportingInventoryItems,
    handleExportInventoryItems,
    exportInventoryItemsMethods: {
      handleSubmit,
      control,
      reset,
      watch,
      setValue,
    },
  } = useExportInventoryItems()
  const { units } = useGlobalInfos()
  const { products, setSearchProductText, isLoadingProductsByName } =
    useProducts()

  const PRODUCT_NAME = watch('product_name')
  const INITIAL_DATE = watch('initial_date')
  const FINAL_NAME = watch('final_date')

  useEffect(() => {
    if (INITIAL_DATE === '') setValue('initial_date', null)
  }, [INITIAL_DATE, setValue])

  useEffect(() => {
    if (FINAL_NAME === '') setValue('final_date', null)
  }, [FINAL_NAME, setValue])

  useEffect(() => {
    if (PRODUCT_NAME) setSearchProductText(PRODUCT_NAME)
  }, [PRODUCT_NAME, setSearchProductText])

  const productsOptions = useMemo(
    () => formatProductsToAutocomplete(PRODUCT_NAME || '', products) || [],
    [PRODUCT_NAME, products],
  )

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Gerar Relatório de inventário</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(handleExportInventoryItems)}>
        <Modal.Body>
          <Stack gap={3} direction="vertical">
            <Row>
              <Col>
                <TextField
                  control={control}
                  label="Data inicial"
                  name="initial_date"
                  type="date"
                />
              </Col>

              <Col>
                <TextField
                  control={control}
                  label="Data final"
                  name="final_date"
                  type="date"
                />
              </Col>
            </Row>
            <Row>
              <Select
                control={control}
                label="Unidade"
                name="unit_id"
                options={units.map((unit) => ({
                  label: unit.nome,
                  value: unit.id,
                }))}
              />
            </Row>
            <Row>
              <Autocomplete
                control={control}
                hintText="Pesquise pelo nome do produto"
                name="product_name"
                onSelectOption={(value: number) =>
                  setValue('product_id', value)
                }
                label="Produto"
                isLoadingInfo={isLoadingProductsByName}
                options={productsOptions || []}
                placeholder="Escolha o nome produto"
              />
            </Row>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              reset()
              handleCloseModal()
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            color="green"
            loading={isExportingInventoryItems}
            disabled={isExportingInventoryItems}
          >
            Exportar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

import Button from '../../../Components/Button'
import Table from '../../../Components/Table'
import AddIcon from '@mui/icons-material/Add'
import { CreateInventoryModal } from './components/CreateInventoryModal'
import { DeleteInventoryModal } from './components/DeleteInventoryModal'
import { ExportInventoryItemsModal } from './components/ExportModal'
import { TableColumns } from './components/TableColumns'
import { useInventoryList } from './hooks/useInventoryList'
import Loading from '../../../Components/Loading'

export function InventoryList() {
  const {
    inventories,
    handleControlAddInventoryModal,
    isOpenDeleteInventoryModal,
    setIsOpenDeleteInventoryModal,
    isOpenCreateNewInventoryModal,
    inventoryToDelete,
    setInventoryToDelete,
    handleDeleteInventory,
    isOpenExportInventoryModal,
    setIsOpenExportInventoryModal,
    paginateQueryInfo,
    setPaginateQueryInfo,
    isLoadingInventories,
    onExportSingleInventory,
  } = useInventoryList()

  const tableOptions = {
    filter: false,
    viewColumns: false,
    serverSide: true,
    rowsPerPageOptions: [50, 100, 150],
    page: paginateQueryInfo.page - 1,
    count: inventories.amount || 1,
    rowsPerPage: paginateQueryInfo.rowsPerPage,
    onChangePage: (page: number) =>
      setPaginateQueryInfo((prevstate) => ({ ...prevstate, page: page + 1 })),
    onChangeRowsPerPage: (rowsPerPage: number) =>
      setPaginateQueryInfo((prevstate) => ({ ...prevstate, rowsPerPage })),
    customToolbar: () => (
      <Button onClick={handleControlAddInventoryModal}>
        <AddIcon />
        Novo Inventário
      </Button>
    ),
  }

  const onDeleteInventory = (id: number) => {
    setIsOpenDeleteInventoryModal(true)
    setInventoryToDelete(id)
  }

  const columns = TableColumns({
    onDeleteInventory,
    inventories: inventories.data,
    onExportSingleInventory,
  })

  return (
    <>
      <div style={{ height: '100%', display: 'flex', overflow: 'auto' }}>
        {isLoadingInventories ? (
          <Loading />
        ) : (
          <Table
            columns={columns}
            data={inventories.data}
            showSearchComponent={false}
            options={tableOptions}
          />
        )}
      </div>
      {/* <Button onClick={() => setIsOpenExportInventoryModal(true)}>
        Gerar relatório
      </Button> */}
      {isOpenCreateNewInventoryModal && (
        <CreateInventoryModal
          isOpen={isOpenCreateNewInventoryModal}
          handleCloseModal={handleControlAddInventoryModal}
        />
      )}
      {isOpenDeleteInventoryModal && (
        <DeleteInventoryModal
          handleCloseModal={() => setIsOpenDeleteInventoryModal(false)}
          isOpen={isOpenDeleteInventoryModal}
          inventory={inventoryToDelete || 0}
          handleDeleteInventory={(inventory: number) =>
            handleDeleteInventory(inventory)
          }
        />
      )}
      {isOpenExportInventoryModal && (
        <ExportInventoryItemsModal
          isOpen={isOpenExportInventoryModal}
          handleCloseModal={() => setIsOpenExportInventoryModal(false)}
        />
      )}
    </>
  )
}

import { FieldError, useFieldArray, useFormContext } from 'react-hook-form'
import { CreateInventoryFromXml } from '../../../../types'
import { Col, Row, Stack } from 'react-bootstrap'
import { Separator } from '../../../../../../Components/Separator'
import TextField from '../../../../../../Components/Forms/TextField'
import InputMoney from '../../../../../../Components/Forms/InputMoney'
import { Fragment } from 'react'
import { Delete } from '@material-ui/icons'
import { SelectProduct } from './components/SelectProduct'

export function ReviewProductsFromXmlImport() {
  const { control, formState } = useFormContext<CreateInventoryFromXml>()

  const { fields, remove } = useFieldArray({
    control,
    name: 'products',
  })

  return (
    <Stack gap={3} direction="vertical">
      {fields.map((item, index) => {
        const isLastItem = index + 1 === fields.length
        return (
          <Fragment key={item.gtin}>
            <span style={{ fontSize: '14px' }}>
              {' '}
              <b>Produto comprado: </b> {item.name}
            </span>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Col md={11}>
                <Stack gap={2} direction="vertical">
                  <Row>
                    <Col md={8}>
                      <SelectProduct index={index} />
                    </Col>

                    <Col>
                      <TextField
                        control={control}
                        name={`products.${index}.amount`}
                        type="number"
                        label="Qtd Total"
                        error={
                          (formState?.errors?.products?.[index]
                            ?.amount as FieldError) ?? ''
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextField
                        control={control}
                        name={`products.${index}.type`}
                        label="Tipo"
                        disabled
                      />
                    </Col>
                    <Col>
                      <InputMoney
                        control={control}
                        name={`products.${index}.cost`}
                        label="Custo"
                        disabled
                      />
                    </Col>
                    <Col>
                      <InputMoney
                        control={control}
                        name={`products.${index}.total`}
                        label="Valor total"
                        disabled
                      />
                    </Col>
                  </Row>
                </Stack>
              </Col>
              <Col md={1}>
                <Delete
                  color="error"
                  style={{ cursor: 'pointer' }}
                  onClick={() => remove(index)}
                />
              </Col>
            </Row>

            {!isLastItem && <Separator />}
          </Fragment>
        )
      })}
    </Stack>
  )
}

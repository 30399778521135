interface ITabContent {
  children: React.ReactNode
  value: number
  index: number
}

export function TabContent({ children, value, index }: ITabContent) {
  return (
    value === index && (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {children}
      </div>
    )
  )
}

import * as S from './styles'
import Button from 'Components/Button'
import Table from 'Components/Table'
import { usePackingList } from './hooks/usePackingList'
import { useEffect, useMemo, useState } from 'react'
import { TableColumns } from './components/TableColumns'
import useAuth from 'hooks/useAuth'
import { useGlobalInfos } from 'hooks/useGlobalInfos'
import CustomToolbar from './components/CustomToolbar'
import { useParams } from 'hooks/useParams'
import { Spinner } from 'react-bootstrap'

export function PackingList() {
  const { params, navigate } = useParams()
  const { isAdmin } = useAuth()
  const { categories } = useGlobalInfos()
  const {
    getPackingList,
    isLoadingPackingListItems,
    packingListItems,
    handleDeleteProduct,
    onChangeControl,
    onChangeRepositionAmount,
    onChangeAvailableAmount,
    handleFinishPacking,
    handlePrint,
    onChangeStatus,
    handleControllCheck,
    hasSomeProductUnchecked,
    onUpdatePackingListItem,
    isSavingPackingList,
  } = usePackingList()

  const filteredProductName = params?.get('name') ?? ''
  const filteredProductCategory = params?.get('category') ?? ''

  const [searchText, setSearchText] = useState(filteredProductName)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText) {
        params.set('name', searchText)
      } else {
        params.delete('name')
      }
      navigate(`?${params.toString()}`)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  const filteredProducts = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let products = packingListItems?.items

    if (filteredProductCategory) {
      const currentCategory = categories.find(
        (category) => category.id === Number(filteredProductCategory),
      )
      products = products?.filter(
        (product) => product.category_name === currentCategory?.nome,
      )
    }

    if (filteredProductName) {
      products = products?.filter((product) =>
        product['name']
          ?.toLowerCase()
          ?.includes(filteredProductName.toLowerCase()),
      )
    }

    return products
  }, [
    filteredProductName,
    filteredProductCategory,
    packingListItems?.items,
    categories,
  ])

  const tableOptions = {
    customToolbar: () => {
      return <CustomToolbar categories={categories} params={params} />
    },
    searchText,
    filter: false,
    viewColumns: false,
    rowsPerPage: 9999,
    // serverSide: false,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: isLoadingPackingListItems ? (
          <Spinner />
        ) : (
          'Ops... Nenhum registro encontrado.'
        ),
      },
    },
  }

  useEffect(() => {
    getPackingList()
  }, [getPackingList])

  const tableColumns = TableColumns({
    onChangeControl,
    onChangeRepositionAmount,
    onChangeAvailableAmount,
    handleDeleteProduct,
    onChangeStatus,
    onUpdatePackingListItem,
    filteredProducts,
    isAdmin,
  })

  function onRequestSavePacking() {
    const hasConfirmed = window.confirm(
      'Tem certeza que deseja finalizar o Romaneio? Essa ação não tem volta.',
    )

    if (hasConfirmed) {
      return handleFinishPacking()
    }
  }

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={tableColumns}
          data={isLoadingPackingListItems ? [] : filteredProducts ?? []}
          options={tableOptions}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Pesquise pelo nome do produto"
          onClear={() => {
            setSearchText('')
            params.delete('name')
            navigate(`?${params.toString()}`)
          }}
        />
      </S.TableWrapper>
      <S.ButtonsWrapper>
        <Button onClick={handleControllCheck}>
          {hasSomeProductUnchecked ? 'Marcar' : 'Desmarcar'} todos
        </Button>

        <div>
          <Button onClick={handlePrint}>Imprimir</Button>
          <Button
            onClick={onRequestSavePacking}
            color="green"
            loading={isSavingPackingList}
            disabled={isSavingPackingList}
          >
            Finalizar Romaneio
          </Button>
        </div>
      </S.ButtonsWrapper>
    </>
  )
}

import axios from 'axios'
import { getToken } from '../../contexts/auth'

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACKEND,
})

api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `${token}`
  }

  return config
})

export default api

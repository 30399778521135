import { createTheme } from '@mui/material/styles'

export const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            background: '#f4f4f4',
            // minWidth: '600px',
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            span: {
              display: 'flex',
              alignItems: 'center',
            },
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center'
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            margin: '0',
            overflow: 'hidden',
          },
          responsiveBase: {
            // backgroundColor: 'red'
            borderRadius: '12px',
            border: '1px solid #ccc',
            // borderWidth: '1px 0 1px 1px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            overflow: 'hidden',
          },
          head: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: 'rgb(74, 74, 74)',
            overflow: 'hidden',
          },
          footer: {
            padding: '0!important',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize!important',
            borderRadius: '14px',
          },
          MuiIconButton: {
            label: {
              backgroundColor: 'red!important',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 'none',
            fieldset: {
              // boxShadow: '0px 0px 11px 0px rgba(0, 0, 0, 0.5) !important',
              // background: '#f4f4f4',
              padding: '12px 18px',
              span: {
                color: 'black',
              },
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            maxWidth: '300px',
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            p: { margin: 0 },
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          contentWrapper: {
            // textTransform: 'capitalize',
            content: '""',
            // borderRight: '1px solid #ccc',
            height: '16px',
            paddingRght: '0.75rem',
          },
          fixedHeader: {
            // backgroundColor: '#f5f5f5',
            whiteSpace: 'nowrap',
            border: 'none',
            padding: '0.75rem 0 0.75rem 0.75rem',
            outline: '1px solid #ccc',
          },
          toolButton: {
            width: '100%',
            justifyContent: 'flex-start',
          },
          data: {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: 'rgb(74, 74, 74)',
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          actions: {
            display: 'flex',
            flexDirection: 'row',
            flex: 'initial',
            alignItems: 'center',
            textAlign: 'initial',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: 'red',
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
          },
        },
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            marginBottom: '16px',
          },
        },
      },
    },
    // typography: {
    //   fontFamily: 'Raleway, Arial',
    // },
  })

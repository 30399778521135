import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import {
  GenerateCompleteInventoryItemsReportDTO,
  exportCompleteInventoryReport,
} from '../../../services'

const current_date = new Date()
const INITIAL_DATE = moment(current_date).startOf('month').format('yyyy-MM-DD')
const FINAL_DATE = moment(current_date).endOf('month').format('yyyy-MM-DD')

export function useExportInventoryItems() {
  const [isExportingInventoryItems, setIsExportingInventoryItems] =
    useState(false)

  const exportInventoryItemsMethods =
    useForm<GenerateCompleteInventoryItemsReportDTO>({
      mode: 'onBlur',
      defaultValues: {
        initial_date: INITIAL_DATE,
        final_date: FINAL_DATE,
        unit_id: null,
        product_id: null,
        product_name: null,
      },
      reValidateMode: 'onBlur',
    })

  async function handleExportInventoryItems(
    data: GenerateCompleteInventoryItemsReportDTO,
  ) {
    if (!data.initial_date || !data.final_date)
      return toast.warning('É necessário informar a data de início e fim')

    if (!data.unit_id) return toast.warning('É necessário informar a unidade')

    setIsExportingInventoryItems(true)
    try {
      const response = await exportCompleteInventoryReport(data)
      saveAs(
        `data:application/vnd.ms-excel;base64,${response.csv}`,
        `Relatório de inventário.csv`,
      )
      setIsExportingInventoryItems(false)
    } catch (error: any) {
      setIsExportingInventoryItems(false)
      toast.error(
        error?.message || 'Erro ao exportar o relatório de inventário',
      )
    }
  }

  return {
    isExportingInventoryItems,
    handleExportInventoryItems,
    exportInventoryItemsMethods,
  }
}

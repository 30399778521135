import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Formik } from 'formik'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import * as yup from 'yup'
import Button from '../../Components/Button'
import api from '../../services/api'

interface Props {
  categoryData: {
    id: number
    nome: string
    descricao: string
  }
  show: boolean
  onHide: () => void
  parentCallback: () => Promise<void>
  edit?: boolean
}

export default function CategoryModal(props: Props) {
  const schema = yup.object({
    nome: yup.string().required().min(4),
    descricao: yup.string(),
  })

  const handleSubmit = (event: any) => {
    function postCategories() {
      api.post('/categories', event).then(() => {
        // console.log(response)
        props.parentCallback()
      })
    }

    function patchCategories() {
      api.patch(`/categories/${props.categoryData.id}`, event).then(() => {
        // console.log(response)
        props.parentCallback()
      })
    }

    if (props.categoryData.id === -1) postCategories()
    else patchCategories()
    props.onHide()
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.edit ? 'Editar Categoria' : 'Cadastrar Categoria'}
        </Modal.Title>
      </Modal.Header>
      <div style={styles.modal}>
        <Modal.Body>
          <Formik
            enableReinitialize
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={props.categoryData}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate id="categoryForm" onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md="12" controlId="validacaoNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      placeholder="Nome"
                      type="text"
                      name="nome"
                      value={values.nome}
                      onChange={handleChange}
                      isInvalid={!!errors.nome}
                      isValid={touched.nome && !errors.nome}
                    />
                    <Form.Control.Feedback type="invalid">
                      Nome é um campo obrigatório!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validacaoDescricao">
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                      name="descricao"
                      as="textarea"
                      rows={3}
                      placeholder="Descrição"
                      value={values?.descricao}
                      onChange={handleChange}
                      isValid={touched.descricao && !errors.descricao}
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancelar</Button>
        <Button form="categoryForm" type="submit">
          <SaveAsIcon /> Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const styles = {
  modal: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

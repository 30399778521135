import styled from 'styled-components'

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const AutocompleteOptionsContainer = styled.div`
  background-color: white;
  width: 100%;
  position: absolute;
  top: 70px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.35);
  max-height: 145px;
  overflow: auto;
  transform: translateY(-50%, -50%);
  z-index: 999;

  > p {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0;
  }
`

export const AutocompleteOptionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 4px 0;

  > li {
    color: #212529;
    list-style-type: none;
    padding: 8px 8px;
    cursor: pointer;
    font-size: 14px;
  }

  li:hover {
    background-color: #e8e8e8;
  }
`

import { useQuery } from '@tanstack/react-query'
import { getAllSuppliers } from '../services'
import { useState } from 'react'
import { ISupplier } from '../types'

interface useSuppliersProps {
  fetchSuppliers?: boolean
}

export function useSuppliers({ fetchSuppliers = true }: useSuppliersProps) {
  const [isOpenSupplierModal, setIsOpenSupplierModal] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier | null>(
    null,
  )
  const [isOpenDeleteSupplierModal, setIsOpenDeleteSupplierModal] =
    useState(false)
  const { data, isLoading } = useQuery({
    queryKey: ['/backoffice/suppliers'],
    queryFn: async () => getAllSuppliers(),
    retry: false,
    enabled: fetchSuppliers,
  })

  return {
    suppliers: data,
    isLoading,
    isOpenSupplierModal,
    setIsOpenSupplierModal,
    selectedSupplier,
    setSelectedSupplier,
    isOpenDeleteSupplierModal,
    setIsOpenDeleteSupplierModal,
  }
}

import { MUIDataTableOptions } from 'mui-datatables'
import Table from '../../Components/Table'
import { useEffect } from 'react'
import InventoryMovementsTableColumns from './components/CustomFilters'
import { IconButton } from '@mui/material'
import { Add } from '@material-ui/icons'
import { theme } from '../../styles/theme'
import InventoryMovementModal from './components/CreateInventoryModal'
import { useInventoryMovements } from './hooks/useInventoryMovements'
import * as S from './styles'
import Button from '../../Components/Button'
import { ExportMovementsModal } from './components/ExportMovementsModal'
import { CustomTableFilter } from '../../Components/CustomTableFilter'
import InventoryMovementsFilter from './components/InventoryMovementsFilters'
import { Spinner } from 'react-bootstrap'
import { useParams } from '../../hooks/useParams'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'

export default function InventoryMovements() {
  const { location, navigate, params } = useParams()
  const {
    getAllInventoryMovements,
    movements,
    isOpenExportInventoryMovementsModal,
    setIsOpenExportInventoryMovementsModal,
    isLoadingMovements,
    isOpenInventoryMovementsModal,
    setIsOpenInventoryMovementsModal,
    createInventoryMovement,
    createInventoryFromProductList,
  } = useInventoryMovements()

  useEffect(() => {
    const { search } = window.location
    getAllInventoryMovements(search)
  }, [getAllInventoryMovements, location.search])

  const inventoryMovementsTableOptions: MUIDataTableOptions = {
    customToolbar: () => (
      <S.TableTopBar>
        <IconButton
          style={{
            fontSize: '16px',
            borderRadius: '12px',
            backgroundColor: theme.colors.primaryColor,
            color: theme.colors.whiteColor,
          }}
          onClick={() => setIsOpenInventoryMovementsModal(true)}
        >
          <Add />
          Nova movimentação
        </IconButton>
        <CustomTableFilter>
          <InventoryMovementsFilter params={params} />
        </CustomTableFilter>
      </S.TableTopBar>
    ),
    page: Number(params.get('page') ?? 1) - 1,
    count: movements.amount || 0,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoadingMovements ? (
          <Spinner />
        ) : (
          'Ops... Nenhum registro encontrado.'
        ),
      },
    },
  }

  const columns = InventoryMovementsTableColumns()

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={columns}
          data={isLoadingMovements ? [] : movements.data}
          options={inventoryMovementsTableOptions}
          showSearchComponent={false}
        />
      </S.TableWrapper>
      <Button onClick={() => setIsOpenExportInventoryMovementsModal(true)}>
        Exportar movimentações
      </Button>
      {isOpenInventoryMovementsModal && (
        <InventoryMovementModal
          isOpen={isOpenInventoryMovementsModal}
          handleCloseModal={() => setIsOpenInventoryMovementsModal(false)}
          handleCreateNewInventory={createInventoryMovement}
          onImportXmlFile={createInventoryFromProductList}
        />
      )}
      {isOpenExportInventoryMovementsModal && (
        <ExportMovementsModal
          isOpenExportModal={isOpenExportInventoryMovementsModal}
          handleCloseExportModal={() =>
            setIsOpenExportInventoryMovementsModal(false)
          }
        />
      )}
    </>
  )
}

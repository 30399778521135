import { Form } from 'react-bootstrap'
import * as S from './styles'
import { ICategory } from 'pages/Categories/types'
import { useNavigate } from 'react-router-dom'

interface Props {
  categories: ICategory[]
  params: URLSearchParams
}

export default function CustomToolbar({ categories, params }: Props) {
  const navigate = useNavigate()
  return (
    <S.ButtonsWrapper>
      <Form.Control
        style={{ width: '200px' }}
        as="select"
        value={params.get('category') || ''}
        onChange={(e) => {
          if (e?.target.value === '') {
            params.delete('category')
          } else {
            params.set('category', e?.target.value)
          }
          navigate(`?${params.toString()}`)
        }}
      >
        <option value="">Todas as categorias</option>
        {categories.map((category) => (
          <option value={category.id} key={category.id}>
            {category.nome}
          </option>
        ))}
      </Form.Control>
    </S.ButtonsWrapper>
  )
}

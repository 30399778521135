import { toast } from 'react-toastify'

const html2pdf = require('html2pdf.js')

export async function handleDownloadPdf() {
  try {
    const item = document.querySelector('#printQRCodes')
    const opt = {
      filename: `etiquetas.pdf`,
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'l' },
      pagebreak: { after: '.html2pdf__page-break' },
    }

    html2pdf().set(opt).from(item).save()
    await html2pdf()
      .set(opt)
      .from(item)
      .toPdf()
      .get('pdf')
      .then(function (pdf: {
        output: (arg0: string) => string | URL | undefined
      }) {
        window.open(pdf.output('bloburl'), '_blank')
      })
  } catch (error) {
    console.error('Erro ao baixar PDF', error)
    toast('Erro ao baixar o QR Code')
  }
}

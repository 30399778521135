import { Stack, Row, Col } from 'react-bootstrap'
import { ORIGIN_OPTIONS, STOCK_OPTIONS, TYPE_OPTIONS } from '../../constants'
import { Autocomplete } from '../../../../Components/Forms/Autocomplete'
import { useGlobalInfos } from '../../../../hooks/useGlobalInfos'
import { useNavigate } from 'react-router-dom'
import { useProducts } from '../../../Products/hooks/useProducts'
import { formatProductsToAutocomplete } from '../../formaters'
import { useFormContext } from 'react-hook-form'
import Select from '../../../../Components/Forms/Select'
import { useEffect } from 'react'
import TextField from '../../../../Components/Forms/TextField'

interface InventoryMovementsModal {
  params: URLSearchParams
}

export default function InventoryMovementsFilter({
  params,
}: InventoryMovementsModal) {
  const { units } = useGlobalInfos()
  const {
    setSearchProductText,
    products = [],
    isLoadingProductsByName,
  } = useProducts()
  const navigate = useNavigate()

  const { control, watch } = useFormContext()

  const productName = watch('product_name')

  useEffect(() => {
    setSearchProductText(productName)
  }, [productName, setSearchProductText])

  return (
    <Stack gap={3} direction="vertical">
      <Autocomplete
        control={control}
        label="Produto"
        name="product_name"
        placeholder="Escolha o produto"
        onSelectOption={(option: number) => {
          params.set('product', String(option))
          params.set('page', '1')
          navigate(`?${params.toString()}`)
        }}
        options={formatProductsToAutocomplete(productName || '', products)}
        hintText="Digite o nome do produto..."
        isLoadingInfo={isLoadingProductsByName}
      />
      <Row>
        <Col>
          <TextField
            control={control}
            label="Data inicial"
            name="initial_date"
            type="date"
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="Data final"
            name="final_date"
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            name="type"
            control={control}
            label="Tipo"
            options={[{ value: '', label: 'Todos os tipos' }, ...TYPE_OPTIONS]}
            error={null}
          />
        </Col>
        <Col>
          <Select
            name="origin"
            control={control}
            label="Origem"
            options={[
              { value: '', label: 'Todas as origens' },
              ...ORIGIN_OPTIONS,
            ]}
            error={null}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            name="stock"
            control={control}
            label="Estoque"
            options={[
              { value: '', label: 'Todos os estoques' },
              ...STOCK_OPTIONS,
            ]}
            error={null}
          />
        </Col>

        <Col>
          <Select
            name="unit"
            control={control}
            label="Unidade"
            options={[
              { value: '', label: 'Todas as unidades' },
              ...units.map((unit) => ({ value: unit.id, label: unit.nome })),
            ]}
            error={null}
          />
        </Col>
      </Row>
    </Stack>
  )
}

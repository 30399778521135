import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { ExportInventoryMovementsDTO, MovementStockEnum } from '../../../types'
import { handleExportInventoryMovements } from '../../../services'
import { saveAs } from 'file-saver'
import moment from 'moment'

const current_date = new Date()
const INITIAL_DATE = moment(current_date).startOf('month').format('yyyy-MM-DD')
const FINAL_DATE = moment(current_date).endOf('month').format('yyyy-MM-DD')

export function useExportInventoryMovements() {
  const [isExportingInventoryMovements, setIsExportingInventoryMovements] =
    useState(false)
  const exportInventoryMovementFormMethods =
    useForm<ExportInventoryMovementsDTO>({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
        type: null,
        origin: null,
        stock: null,
        initial_date: INITIAL_DATE,
        final_date: FINAL_DATE,
        units: [],
      },
    })

  const isUnitStock =
    exportInventoryMovementFormMethods.watch('stock') ===
    ('unit' as MovementStockEnum)

  async function handleExportInventoryMovments(
    payload: ExportInventoryMovementsDTO,
  ) {
    setIsExportingInventoryMovements(true)
    try {
      const response = await handleExportInventoryMovements(payload)

      saveAs(
        `data:application/vnd.ms-excel;base64,${response}`,
        'Movimentacoes de estoque.csv',
      )
      setIsExportingInventoryMovements(false)
    } catch (error: any) {
      setIsExportingInventoryMovements(false)
      return toast.error(error.message)
    }
  }

  return {
    exportInventoryMovementFormMethods,
    handleExportInventoryMovments,
    isExportingInventoryMovements,
    isUnitStock,
  }
}

import { Modal, FormText } from 'react-bootstrap'
import Button from '../../../Components/Button'
import { IProduct } from '../types'

interface RemoveProductFromUnitModalProps {
  isOpenRemoveProductFromUnitModal: boolean
  setIsOpenRemoveProductFromUnitModal: (state: boolean) => void
  handleDelete: () => void
  product?: IProduct | null
  unitToRemove: any
}

export default function RemoveProductFromUnitModal({
  isOpenRemoveProductFromUnitModal,
  setIsOpenRemoveProductFromUnitModal,
  handleDelete,
  product,
  unitToRemove,
}: RemoveProductFromUnitModalProps) {
  return (
    <Modal
      backdropClassName="remove-product-from-unit-backdrop"
      show={isOpenRemoveProductFromUnitModal}
      onHide={() => setIsOpenRemoveProductFromUnitModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Tem certeza?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormText>
          Deseja remover o produto: <b>{product?.name}</b> da unidade:{' '}
          <b>{unitToRemove}</b>
        </FormText>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setIsOpenRemoveProductFromUnitModal(false)}>
          Cancelar
        </Button>
        <Button color="error" onClick={handleDelete}>
          Remover
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import { theme } from '../../../../styles/theme'
import { Form } from 'react-bootstrap'
import { IUnit } from '../../../Units/types'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'
import { ICategory } from '../../../Categories/types'

interface Props {
  handleCreate: () => void
  units: IUnit[]
  params: URLSearchParams
  categories: ICategory[]
}

export default function CustomToolbar({
  handleCreate,
  units,
  categories,
  params,
}: Props) {
  const navigate = useNavigate()
  return (
    <S.ButtonsWrapper>
      <Form.Control
        style={{ width: '200px' }}
        as="select"
        value={params.get('category') || ''}
        onChange={(e) => {
          params.set('category', e.target.value)
          params.delete('page')
          navigate(`?${params.toString()}`)
        }}
      >
        <option value="">Todas as categorias</option>
        {categories.map((category) => (
          <option value={category.id} key={category.id}>
            {category.nome}
          </option>
        ))}
      </Form.Control>
      <Form.Control
        style={{ width: '200px' }}
        as="select"
        value={params.get('unit') || ''}
        onChange={(e) => {
          params.set('unit', e.target.value)
          params.delete('page')
          navigate(`?${params.toString()}`)
        }}
      >
        <option value="">Todas as unidades</option>
        {units.map((unit) => (
          <option value={unit.id} key={unit.id}>
            {unit.nome}
          </option>
        ))}
      </Form.Control>
      <IconButton
        style={{
          fontSize: '16px',
          borderRadius: '12px',
          backgroundColor: theme.colors.primaryColor,
          color: theme.colors.whiteColor,
        }}
        onClick={handleCreate}
      >
        <AddIcon />
        Adicionar Produto
      </IconButton>
    </S.ButtonsWrapper>
  )
}

import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { MUIDataTableOptions } from 'mui-datatables'
import api from '../../services/api'
import TableColumns from './ReportsTableColumns'
import { CSVLink } from 'react-csv'
import Button from '../../Components/Button'
import Table from '../../Components/Table'
import { CustomTableFilter } from '../../Components/CustomTableFilter'
import { ReportsFilter } from './components/ReportsFilter'
import { useParams } from '../../hooks/useParams'
import { convertCentsToLocale } from '../../utils/money.formater'
import { REPORTS_EXCEL_LABELS } from './constants'

export const Reports = () => {
  const { location, params } = useParams()
  const [reports, setReports] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  async function loadData() {
    try {
      setLoading(false)
      setReports([])

      const res = await api.get(`/monthly-report?${params.toString()}`)
      setReports(res.data)
      setLoading(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    loadData()
  }, [location.search])

  const options: MUIDataTableOptions = {
    search: false,
    viewColumns: false,
    customToolbar: () => (
      <CustomTableFilter setPage={false}>
        <ReportsFilter />
      </CustomTableFilter>
    ),
  }
  const columns = TableColumns()

  function formatDataToExcel() {
    return reports.map((item) => ({
      unidade_nome: String(item?.unidade_nome)
        .replace('UNIDADE', '')
        .replace(' ', ''),
      formated_date:
        String(item.formated_date).charAt(0).toUpperCase() +
        item.formated_date.slice(1),
      total: convertCentsToLocale(item.total),
    }))
  }

  return !loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '90%',
          display: 'flex',
        }}
      >
        <Table
          data={reports}
          columns={columns}
          options={options}
          showSearchComponent={false}
        />
      </div>
      <Button>
        <CSVLink
          data={formatDataToExcel()}
          headers={REPORTS_EXCEL_LABELS}
          filename="Relatório de vendas.csv"
          style={{ color: '#fff', textTransform: 'capitalize' }}
          separator=";"
        >
          Fazer download da tabela
        </CSVLink>
      </Button>
    </>
  )
}

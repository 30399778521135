import { useQuery } from '@tanstack/react-query'
import { getAllDoorAccess } from '../services'

export function useAccessControl() {
  const { search } = window.location

  const { data, isLoading } = useQuery({
    queryKey: ['ACCESS_DOOR', search],
    queryFn: async () => getAllDoorAccess(search),
  })

  return {
    data,
    isLoading,
  }
}

import { saveAs } from 'file-saver'
import moment from 'moment/moment'
import { useState } from 'react'
// import { Button } from 'react-bootstrap';
import { Buffer } from 'buffer'
import Button from '../../Components/Button'
import api from '../../services/api'

const current_date = new Date()

const INITIAL_DATE = moment(current_date).startOf('month').format('yyyy-MM-DD')
const FINAL_DATE = moment(current_date).endOf('month').format('yyyy-MM-DD')

export const Invoices = () => {
  const [exportDates, setExportDates] = useState({
    initial: INITIAL_DATE,
    final: FINAL_DATE,
  })
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)

  async function exportXml() {
    setIsLoadingDownload(true)
    try {
      const response = await api.get(
        `/notafiscal/get-by-period?initial_date=${exportDates.initial}&final_date=${exportDates.final}`,
        {
          responseType: 'arraybuffer',
        },
      )

      const content = Buffer.from(response.data, 'hex')
      const blob = new Blob([content], { type: 'application/zip' })
      saveAs(blob, 'Xml.zip')
      setIsLoadingDownload(false)
    } catch (error) {
      console.log(error)
      setIsLoadingDownload(false)
    }
  }

  function handleChangeDate(event: { target: { value: any; name: any } }) {
    const { value, name } = event.target

    setExportDates((prevstate) => ({
      ...prevstate,
      [name]: value,
    }))
  }

  return (
    <div style={{ display: 'flex', gap: '16px', margin: '16px' }}>
      <div style={{ display: 'flex', gap: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="initial_date">Data inicial</label>
          <input
            name="initial"
            type="date"
            value={exportDates.initial}
            onChange={handleChangeDate}
          />
        </div>
        {/* <div style={{ marginLeft: '10px', marginRight: '10px' }} /> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor="final_date">Data final</label>
          <input
            name="final"
            type="date"
            value={exportDates.final}
            onChange={handleChangeDate}
          />
        </div>
      </div>
      <Button
        // width={'fullWidth'}
        onClick={exportXml}
        disabled={isLoadingDownload}
      >
        {isLoadingDownload ? 'Exportando...' : 'Exportar'}
      </Button>
    </div>
  )
}

import { MUIDataTableOptions } from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import DeleteModal from '../../Components/Modal/DeleteModalUsers'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import api from '../../services/api'
import UserModal from './UsersModal'
import TableColumns from './UsersTableColumns'
import Table from '../../Components/Table'
import { string } from 'yup'
import { format } from 'date-fns'
import { useParams } from '../../hooks/useParams'
import * as S from './styles'
import { CustomTableFilter } from '../../Components/CustomTableFilter'
import UsersFilter from './components/UsersFilter'

const userInit = {
  id: 0,
  nome: '',
  sobrenome: '',
  CPF: '',
  unidade_id: 0,
  dataNasc: string,
  telefone: string,
}

export const Users = () => {
  const { location, params, navigate } = useParams()
  const [users, setUsers] = useState<any>([])
  const [userData, setUserData] = useState<any>(userInit)
  const [modalShow, setModalShow] = useState(false)
  const [delModalShow, setDelModalShow] = useState(false)
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)

  async function loadData() {
    setIsLoadingUsers(true)
    const { search } = window.location
    try {
      setUsers([])

      const res = await api.get(`/users${search}`)
      setUsers(res.data)
      setIsLoadingUsers(false)
    } catch (err) {
      setIsLoadingUsers(false)
      console.log(err)
    }
  }

  useEffect(() => {
    loadData()
  }, [location.search])

  const handleEdit = (id: number) => {
    const resId = users.data.findIndex((res: any) => res.id === id)
    const tmp = users.data[resId]
    setUserData({
      dataNasc: format(new Date(tmp.data_nascimento), 'yyyy-MM-dd'),
      ...tmp,
    })
    setModalShow(true)
  }

  const handleDel = (id: number) => {
    const resId = users.data.findIndex((res: any) => res.id === id)
    const tmp = users.data[resId]
    setUserData(tmp)
    setDelModalShow(true)
  }

  const options: MUIDataTableOptions = {
    customToolbar: () => (
      <S.TableTopBar>
        <CustomTableFilter>
          <UsersFilter params={params} />
        </CustomTableFilter>
      </S.TableTopBar>
    ),
    page: Number(params.get('page') ?? 1) - 1,
    count: users.amount || 0,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoadingUsers ? (
          <Spinner />
        ) : (
          'Ops... Nenhum registro encontrado.'
        ),
      },
    },
  }

  const columns = TableColumns(handleEdit, handleDel)
  return (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
        }}
      >
        <Table
          data={isLoadingUsers ? [] : users.data}
          columns={columns}
          options={options}
          searchPlaceholder="Pesquise pelo nome ou CPF do usuário"
          showSearchComponent={false}
        />
      </div>
      <DeleteModal
        data={{ type: 'Usuário', text: 'o usuário com nome', values: userData }}
        show={delModalShow}
        onHide={() => setDelModalShow(false)}
        parentCallback={loadData}
      />
      <UserModal
        userData={userData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        parentCallback={loadData}
      />
    </>
  )
}

import { useContext } from 'react'
import { AuthContext } from '../contexts/auth'

const useAuth = () => {
  const context = useContext(AuthContext)
  const permission = context?.user?.data?.permission

  const isAdmin = permission === 999
  return { ...context, isAdmin }
}

export default useAuth

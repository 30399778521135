import {
  MovementOriginEnum,
  MovementStockEnum,
  MovementTypeEnum,
} from '../types'

export const TYPE_OPTIONS = [
  { label: MovementTypeEnum.input, value: 'input' },
  { label: MovementTypeEnum.output, value: 'output' },
]

export const ORIGIN_OPTIONS = [
  { label: MovementOriginEnum.reposition, value: 'reposition' },
  { label: MovementOriginEnum.manual, value: 'manual' },
  { label: MovementOriginEnum.sell, value: 'sell' },
]

export const STOCK_OPTIONS = [
  {
    label: MovementStockEnum.global,
    value: 'global',
  },
  {
    label: MovementStockEnum.unit,
    value: 'unit',
  },
]

export const DEFAULT_INVENTORY_MOVEMENT_FIELDS = {
  product_id: null,
  quantity: null,
  type: null,
  origin: null,
  stock: null,
}

import { ReactNode, useEffect, useState } from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import * as S from './styles'
import { Tooltip } from '@material-ui/core'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from '../../hooks/useParams'

interface CustomTableFilterProps {
  children: ReactNode
  setPage?: boolean | null
}

export function CustomTableFilter({
  children,
  setPage = true,
}: CustomTableFilterProps) {
  const { navigate, params } = useParams()
  const [isOpen, setIsOpen] = useState(false)

  const methods = useForm()

  const handleClearFilters = () => {
    const paramsKeys = Array.from(params.keys())
    for (const param of paramsKeys) {
      params.delete(param)
    }

    navigate(``)
    setIsOpen(false)
  }

  const onApplyFilters = () => {
    const allFields = methods.watch()

    console.log('allFields', allFields)

    Object.entries(allFields)
      .filter(([, value]) => value !== undefined)
      .forEach(([key, value]) => {
        if (value === '') {
          params.delete(key)
        } else {
          console.log(key, value)
          params.set(key, value)
        }
      })

    if (setPage) params.set('page', '1')
    return navigate(`?${params.toString()}`)
  }

  useEffect(() => {
    const paramsKeys = Array.from(params.entries()).filter(
      (item) => item[0] !== 'page',
    )
    for (const param of paramsKeys) {
      const isArrayField = param[0] === 'units'
      const arrayValue = param[1].split(',').map((item) => Number(item))
      methods.setValue(param[0], isArrayField ? arrayValue : param[1])
    }
  }, [params, methods])

  return (
    <S.FilterContainer>
      <Tooltip title="Filtrar">
        <IconButton onClick={() => setIsOpen(!isOpen)}>
          <FilterAltIcon />
        </IconButton>
      </Tooltip>

      {isOpen && (
        <S.FilterPopover>
          <S.FilterTitle>
            <strong>Filtros</strong>
            <IconButton onClick={() => setIsOpen(false)}>
              <Close />
            </IconButton>
          </S.FilterTitle>

          <FormProvider {...methods}>
            <S.FilterFields>{children}</S.FilterFields>
          </FormProvider>

          <S.FilterActionButtons>
            <button onClick={handleClearFilters}>Limpar filtros</button>
            <button onClick={onApplyFilters}>Aplicar filtros</button>
          </S.FilterActionButtons>
        </S.FilterPopover>
      )}
    </S.FilterContainer>
  )
}

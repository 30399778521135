import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import api from '../../services/api'
import Button from '../Button'

function sleep(ms: number | undefined) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export default function DeleteModal(props: any) {
  const handleDelete = async () => {
    try {
      const res = await api.post(`/hik-adm-delete-user/${props.data.values.id}`)
      // console.log(res)
      if (res.data) {
        props.onHide()
        await sleep(2000)
        toast.success('Usuário excluído com sucesso.')
        props.parentCallback()
      }
    } catch (error) {
      toast.error('Houve um erro ao excluir o usuário.')
    }
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Excluir usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja excluir o usuário com nome{' '}
        {props.data.values.nome} e CPF {props.data.values.CPF}?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancelar</Button>
        <Button color="error" onClick={handleDelete}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { Form } from 'react-bootstrap'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import { IUnit } from 'pages/Units/types'

interface Props {
  units: IUnit[]
  params: URLSearchParams
}

export default function CustomToolbar({ units, params }: Props) {
  const navigate = useNavigate()
  return (
    <S.ButtonsWrapper>
      <Form.Control
        style={{ width: '200px' }}
        as="select"
        value={params.get('unit') || ''}
        onChange={(e) => {
          if (e?.target.value === '') {
            params.delete('unit')
          } else {
            params.set('unit', e?.target.value)
          }
          navigate(`?${params.toString()}`)
        }}
      >
        <option value="">Todas as unidades</option>
        {units.map((unit) => (
          <option value={unit.id} key={unit.id}>
            {unit.nome}
          </option>
        ))}
      </Form.Control>
    </S.ButtonsWrapper>
  )
}

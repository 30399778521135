import QRCode from 'qrcode.react'

export default function QrGroup({ novoArray }: any) {
  return (
    <div style={{ marginTop: '120px' }} className="" id="printQRCodes">
      {novoArray?.map((array, idx) => (
        <div
          key={idx}
          className={'html2pdf__page-break'}
          style={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '15px 0',
          }}
        >
          {array?.map((product, index) => (
            <div
              key={index}
              style={{
                border: '1px dotted #ccc',
                minHeight: '140px',
                maxHeight: '140px',
                overflow: 'hidden',
              }}
            >
              <div
                id="qrCode"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '0',
                  width: '264px',
                  padding: '10px 5px',
                }}
              >
                <QRCode
                  value={`/products/scan/${product.id}`}
                  level="H"
                  size={90}
                />
                <h3
                  style={{
                    fontSize: product.name.length < 45 ? '14px' : '12px',
                    textAlign: 'center',
                    marginTop: '5px',
                  }}
                >
                  {product.name}
                </h3>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

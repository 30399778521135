import { useCallback, useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { PaginatedInventoryListResponse } from '../types'
import {
  createInventory,
  deleteInventory,
  exportSingleInventory,
  getAllInventories,
} from '../services'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { PaginationQuery } from '../../../InventoryMovements/types'

export function useInventoryList() {
  const navigate = useNavigate()
  const [inventories, setInventories] =
    useState<PaginatedInventoryListResponse>(
      {} as PaginatedInventoryListResponse,
    )
  const [isOpenCreateNewInventoryModal, setIsOpenCreateNewInventoryModal] =
    useState(false)
  const [isOpenDeleteInventoryModal, setIsOpenDeleteInventoryModal] =
    useState(false)
  const [inventoryToDelete, setInventoryToDelete] = useState<number | null>(
    null,
  )
  const [isCreatingNewInventory, setIsCreatingNewInventory] = useState(false)
  const [isOpenExportInventoryModal, setIsOpenExportInventoryModal] =
    useState(false)
  const [paginateQueryInfo, setPaginateQueryInfo] = useState<PaginationQuery>({
    page: 1,
    rowsPerPage: 50,
  })
  const [isLoadingInventories, setIsLoadingInventories] = useState(false)

  const getInventoriesData = useCallback(async () => {
    setIsLoadingInventories(true)
    try {
      const data = await getAllInventories(paginateQueryInfo)
      setInventories(data)
      setIsLoadingInventories(false)
    } catch (error: any) {
      setIsLoadingInventories(false)
      return toast.error(error.message)
    }
  }, [paginateQueryInfo])

  async function handleCreateNewInventory(unit_id: string) {
    setIsCreatingNewInventory(true)
    try {
      const response = await createInventory(unit_id)
      navigate(`${response.inventory}`)
      setIsCreatingNewInventory(false)
    } catch (error: any) {
      setIsCreatingNewInventory(false)
      return toast.error(error.message)
    }
  }

  async function handleDeleteInventory(id: number) {
    try {
      await deleteInventory(id)
      getInventoriesData()
      setInventoryToDelete(null)
      setIsOpenDeleteInventoryModal(false)
      toast.success('Inventário excluído com sucesso')
    } catch (error: any) {
      return toast.error(error.message)
    }
  }

  async function onExportSingleInventory(id: number) {
    try {
      const response = await exportSingleInventory(id)
      saveAs(
        `data:application/vnd.ms-excel;base64,${response.csv}`,
        `Relatório de inventário - ${response.unit_name} - ${response.data}.csv`,
      )
      toast.success('Relatório de inventário baixado com sucesso')
    } catch (error: any) {
      return toast.error(error.message)
    }
  }

  useEffect(() => {
    getInventoriesData()
  }, [getInventoriesData])

  return {
    inventories,
    inventoryToDelete,
    isOpenCreateNewInventoryModal,
    handleControlAddInventoryModal: () =>
      setIsOpenCreateNewInventoryModal(!isOpenCreateNewInventoryModal),
    isCreatingNewInventory,
    handleCreateNewInventory,
    handleDeleteInventory,
    isOpenDeleteInventoryModal,
    setIsOpenDeleteInventoryModal,
    setInventoryToDelete,
    isOpenExportInventoryModal,
    setIsOpenExportInventoryModal,
    isLoadingInventories,
    paginateQueryInfo,
    setPaginateQueryInfo,
    onExportSingleInventory,
  }
}

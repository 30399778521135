import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import QrCodeIcon from '@mui/icons-material/QrCode'
import WarningIcon from '@mui/icons-material/Warning'
import basket from '../../../assets/images/basketIcon.png'
import { convertCentsToLocale } from '../../../utils/money.formater'
import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@mui/icons-material'
import { IProductUnits } from '../types'
import { getAverageValue } from '../utils/getAverageValue'

const renderTooltipInfos = (units: IProductUnits[]) => {
  const tooltipInfo = units.map((unit) => (
    <p key={unit.id} style={{ margin: '7px 0', fontSize: '14px' }}>
      {unit.unit_name} - {unit.amount} disponíveis
    </p>
  ))

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <span>{units.length}</span>
      <Tooltip title={tooltipInfo}>
        <InfoOutlined />
      </Tooltip>
    </div>
  )
}

export default function TableColumns(
  filteredTableData: any[],
  handleEdit: { (id: any): void; (arg0: any): void },
  handleDel: { (id: any): void; (arg0: any): void },
  handleQR: { (id: any): void; (arg0: any): void },
  params: URLSearchParams,
) {
  const isFilterByUnit = params.get('unit')
  const styles = {
    tableText: {
      fontSize: 14,
    },
    btnTable: {
      border: 'none',
      background: 'none',
      paddingTop: '5px',
    },
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'image_url',
      label: 'Imagem',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
          const rowName = tableMeta.rowData[2]
          return <img src={value || basket} alt={rowName} height="50px" />
        },
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        viewColumns: false,
        setCellProps: () => ({ style: { minWidth: '200px' } }),
        customBodyRender: (_, tableMeta: { rowData: any[] }) => {
          const currentTable = filteredTableData.find(
            (data) => data.id === tableMeta.rowData[0],
          )
          return (
            <span>
              {currentTable?.name}
              {Boolean(currentTable.is_product_for_adult) && (
                <span style={{ marginLeft: '3px', fontSize: '12px' }}>
                  <WarningIcon sx={{ color: '#e39400', width: '16px' }} />
                  (+18)
                </span>
              )}
            </span>
          )
        },
      },
    },
    { name: 'description', label: 'Descrição', options: { filter: false } },
    {
      name: 'category_name',
      label: 'Categoria',
      options: {
        filter: false,
      },
    },
    {
      name: 'cost',
      label: 'Custo',
      options: {
        customBodyRender: (value: number) => convertCentsToLocale(value),
      },
    },
    {
      name: 'units',
      label: isFilterByUnit ? 'Preço' : 'Preço médio',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (units: IProductUnits[]) =>
          isFilterByUnit
            ? convertCentsToLocale(units[0]?.value || 0)
            : getAverageValue(units),
      },
    },
    {
      name: 'units',
      label: 'Margem',
      options: {
        customBodyRender: (units: IProductUnits[]) =>
          units[0]?.margin && `${units[0]?.margin}%`,
        display: Boolean(isFilterByUnit),
      },
    },
    {
      name: 'global_amount',
      label: 'Quantidade CD',
      options: {
        display: !isFilterByUnit,
        viewColumns: !isFilterByUnit,
      },
    },
    {
      name: 'units',
      label: isFilterByUnit ? 'Quantidade disponível' : 'Unidades',
      options: {
        customBodyRender: (units: IProductUnits[]) => {
          return isFilterByUnit ? units[0]?.amount : renderTooltipInfos(units)
        },
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (id: string) => {
          return (
            <div
              style={{
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <button
                type="button"
                onClick={() => handleEdit(id)}
                style={styles.btnTable}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                onClick={() => handleDel(id)}
                style={styles.btnTable}
              >
                <DeleteIcon sx={{ color: '#e30000' }} />
              </button>
              <button
                type="button"
                onClick={() => handleQR(id)}
                style={styles.btnTable}
              >
                <QrCodeIcon />
              </button>
            </div>
          )
        },
      },
    },
  ]

  return columns
}

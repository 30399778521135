import { useState } from 'react'
import { Form } from 'react-bootstrap'
import * as S from './styles'

interface AutocompleteOption {
  value: number
  label: string
}

interface AutocompleteProps {
  label?: string
  name: string
  placeholder: string
  onSelectOption: (value: number) => void
  options: AutocompleteOption[]
  hintText: string
  isLoadingInfo: boolean
  onBlur: () => void
  onChange: (value: any) => void
  value: string
  error?: any
}

export function UncontrolledAutocomplete({
  label,
  name,
  placeholder,
  onSelectOption,
  options,
  hintText = 'Digite alguma coisa para continuar',
  isLoadingInfo,
  onChange,
  onBlur,
  value,
  error,
  ...rest
}: AutocompleteProps) {
  const [isOpenSelectOption, setIsOpenSelectOption] = useState(false)

  const handleSelectOption = (option: AutocompleteOption) => {
    onChange(option.label)
    onSelectOption(option.value)
    setIsOpenSelectOption(false)
  }

  const noOptionText =
    options.length === 0 && value ? 'Nenhuma opção encontrada...' : hintText

  return (
    <S.AutocompleteWrapper
      onBlur={() => options.length === 0 && setIsOpenSelectOption(false)}
    >
      <Form.Group>
        {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
        <Form.Control
          name={name}
          isInvalid={!!error}
          onFocus={() => setIsOpenSelectOption(true)}
          onBlur={onBlur}
          {...rest}
          type="text"
          size="sm"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        {!!error && (
          <Form.Control.Feedback type="invalid">
            {error.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {isOpenSelectOption && (
        <S.AutocompleteOptionsContainer>
          {isLoadingInfo && <p>Carregando...</p>}
          {options.length === 0 && !isLoadingInfo && <p>{noOptionText}</p>}
          {options.length > 0 && !isLoadingInfo && (
            <S.AutocompleteOptionsList>
              {options.map((item) => (
                <li key={item.value} onClick={() => handleSelectOption(item)}>
                  {item.label}
                </li>
              ))}
            </S.AutocompleteOptionsList>
          )}
        </S.AutocompleteOptionsContainer>
      )}
    </S.AutocompleteWrapper>
  )
}

import styled from 'styled-components'

export const FilterContainer = styled.div`
  position: relative;
`

export const FilterPopover = styled.div`
  min-width: 430px;
  position: absolute;
  top: 50px;
  right: -10px;

  padding: 14px 16px;
  z-index: 101;
  border-radius: 6px;
  box-shadow: 2px 2px 12px -3px rgba(0, 0, 0, 0.8);
  background: white;
`

export const FilterTitle = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    margin: 0;
    padding: 0;
  }

  > button > svg {
    width: 20px;
  }
`

export const FilterFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  margin: 10px 0;
`

export const FilterActionButtons = styled.footer`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    cursor: pointer;
    padding: 4px 8px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
  }

  button:nth-of-type(1) {
    color: red;
  }
`

import { type InputHTMLAttributes } from 'react'
import { Form } from 'react-bootstrap'
import { type Control, Controller, FieldError, Path } from 'react-hook-form'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
}

export default function Checkbox({
  name,
  control,
  label,
  error,
  ...rest
}: TextFieldProps) {
  return (
    <Form.Group>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Form.Check
            isInvalid={!!error}
            {...field}
            {...rest}
            checked={field.value}
            type="checkbox"
            label={label}
          />
        )}
      />
      {!!error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { ProSidebarProvider } from 'react-pro-sidebar'
// import './assets/scss/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ProSidebarProvider>
      <App />
      <ToastContainer />
    </ProSidebarProvider>
  </Provider>,
)

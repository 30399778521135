import { saveAs } from 'file-saver'
import { MUIDataTableOptions } from 'mui-datatables'
import { useEffect, useState } from 'react'
import Button from '../../Components/Button'
import OpenedCartDetailsModal from './components/OpenedCartDetailsModal'
import TableColumns from './components/OpenedCartsTableColumns'
import Table from '../../Components/Table'
import api from '../../services/api'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'hooks/useParams'
import { useOpenedCarts } from './hooks/useOpenedCarts'
import CustomToolbar from './components/CustomToolbar'
import { useGlobalInfos } from 'hooks/useGlobalInfos'

export const OpenedCarts = () => {
  const { navigate, params } = useParams()
  const { units } = useGlobalInfos()
  const {
    getAllOpenedCarts,
    loading,
    isDeletingCart,
    isExportingCarts,
    isOpenCartDetails,
    openedCarts,
    selectedCart,
    setIsDeletingCart,
    setIsExportingCarts,
    setIsOpenCartDetails,
    setSelectedCart,
  } = useOpenedCarts()
  const [searchText, setSearchText] = useState(params?.get('name') ?? '')

  useEffect(() => {
    getAllOpenedCarts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllOpenedCarts, location.search])

  function handleShowOpenedCartDetails(row: any) {
    setSelectedCart(row)
    setIsOpenCartDetails(true)
  }

  function handleCloseModal() {
    setSelectedCart(null)
    setIsOpenCartDetails(false)
  }

  async function handleDeleteOpenedCart() {
    setIsDeletingCart(true)
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      await api.delete(`/cart/opened-carts/${selectedCart.id}`)
      getAllOpenedCarts()
      setIsDeletingCart(false)
      handleCloseModal()
    } catch (error) {
      console.log(error)
      setIsDeletingCart(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText) {
        params.set('name', searchText)
        params.set('page', '1')
        navigate(`?${params.toString()}`)
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  async function handleExportCarts() {
    setIsExportingCarts(true)
    try {
      const response = await api.get('/backoffice/cart/export-carts')
      saveAs(
        `data:text/plain;base64,${response.data.file}`,
        'Relatório de carrinhos.txt',
      )
      setIsExportingCarts(false)
    } catch (error) {
      setIsExportingCarts(false)
      console.log(error)
      window.alert('Erro ao exportar transações.')
    }
  }

  const options: MUIDataTableOptions = {
    viewColumns: false,
    serverSide: true,
    customToolbar: () => <CustomToolbar units={units} params={params} />,
    onRowClick: (_, rowMeta) => {
      const data = openedCarts.data[rowMeta.dataIndex]
      handleShowOpenedCartDetails(data)
    },
    page: Number(params.get('page') ?? 1) - 1,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    count: openedCarts.amount,
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    textLabels: {
      ...textLabels,
      body: {
        noMatch: loading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  const columns = TableColumns(openedCarts.data)

  return (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '90%',
          display: 'flex',
        }}
      >
        <Table
          columns={columns}
          data={loading ? [] : openedCarts.data}
          options={options}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Pesquise pelo nome do usuário"
          onClear={() => {
            setSearchText('')
            params.delete('name')
            navigate(`?${params.toString()}`)
          }}
        />
      </div>
      <Button onClick={handleExportCarts} disabled={isExportingCarts}>
        {isExportingCarts ? 'Exportando carrinhos...' : 'Exportar carrinhos'}
      </Button>
      {isOpenCartDetails && (
        <OpenedCartDetailsModal
          isOpenCartDetails={isOpenCartDetails}
          handleCloseModal={handleCloseModal}
          openedCart={selectedCart}
          handleDeleteOpenedCart={handleDeleteOpenedCart}
          isDeletingCart={isDeletingCart}
        />
      )}
    </>
  )
}

import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  box-shadow: none;
  height: 90%;
  display: flex;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

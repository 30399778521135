import { DoorSliding } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import moment from 'moment'

// interface ITableColumns {
//   handleEdit: { (id: any): void };
//   handleDel: { (id: any): void };
// }
export default function TableColumns(handleEdit, handleDel) {
  const styles = {
    tableText: {
      fontSize: 14,
    },
    btnTable: {
      border: 'none',
      background: 'none',
      paddingTop: '5px',
    },
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: 'CPF',
      label: 'CPF',
      options: {
        filter: false,
        setCellProps: () => ({ style: { maxWidth: '180px' } }),
        customBodyRender: (value: string) => {
          return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        },
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        filter: false,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
        customBodyRender: (value, tableMeta) => {
          const sobrenome = tableMeta.rowData[3]
          return `${value || ''} ${sobrenome || ''}`
        },
      },
    },
    {
      name: 'sobrenome',
      label: 'Sobrenome',
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: 'data_nascimento',
      label: 'Nascimento',
      options: {
        filter: false,
        // setCellProps: () => ({ style: { width: '65%' } }),
        customBodyRender: (value: string) => {
          return moment(value).add({ hours: 3 }).format('DD/MM/YYYY')
        },
      },
    },
    {
      name: 'telefone',
      label: 'Telefone',
      options: {
        filter: false,
        // setCellProps: () => ({ style: { width: '65%' } }),
        customBodyRender: (value) => {
          const valor = value ? value : ''
          const number = valor
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d)(\d{4})$/, '$1-$2')
          return number
        },
      },
    },
    {
      name: 'unidade_nome',
      label: 'Unidade',
      options: {
        customBodyRender: (value: string) => value.replace('UNIDADE', ''),
        setCellProps: () => ({ style: { width: '150px' } }),
      },
    },
    {
      label: 'Excluído',
      name: 'deleted_at',
      options: {
        customBodyRender: (value: string) => (value ? 'Sim' : 'Não'),
      },
    },
    {
      name: 'botoes',
      label: 'Ações',
      options: {
        filter: false,
        setCellProps: () => ({ style: { width: '110px' } }),
        customBodyRender: (value: any, tableMeta: { rowData: any[] }) => {
          const id = tableMeta.rowData[0]
          const cpf = tableMeta.rowData[1]
          const deletedAt = tableMeta.rowData[7]
          return (
            !deletedAt && (
              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="button-link-edit"
                  onClick={() => handleEdit(id)}
                  style={styles.btnTable}
                >
                  <DriveFileRenameOutlineIcon />
                </button>
                <button
                  type="button"
                  className="button-link-delete"
                  onClick={() => handleDel(id)}
                  style={styles.btnTable}
                >
                  <DeleteIcon sx={{ color: '#e30000' }} />
                </button>
                <a
                  href={`/dashboard/acessos?cpf=${cpf}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <DoorSliding color="action" />
                </a>
              </div>
            )
          )
        },
      },
    },
  ]

  return columns
}

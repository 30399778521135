import { convertCentsToLocale } from '../../../utils/money.formater'
import { IProductUnits } from '../types'

export const getAverageValue = (productsPerUnit: IProductUnits[]) => {
  const sumProductsValue = productsPerUnit.reduce(
    (acc, current) => (acc += current.value || 0),
    0,
  )

  const averageValue = sumProductsValue / productsPerUnit.length
  return convertCentsToLocale(averageValue || 0)
}

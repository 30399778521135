import { MUIDataTableOptions } from 'mui-datatables'
import { useEffect, useMemo, useState } from 'react'
import Button from '../../Components/Button'
import DeleteModal from '../../Components/Modal/DeleteModal'
import CustomToolbar from './components/CustomToolbar'
import TableColumns from './components/ProductsTableColumns'
import ProductModal from '../../Components/Modal/ProductModal'
import Table from '../../Components/Table'
import { useProducts } from './hooks/useProducts'
import { IProduct } from './types'
import { ExportPackingListModal } from './components/ExportPackingListModal'
import { ProductQrCodeModal } from './components/QRCodeModal'
import QrGroup from '../../Components/QrGroup/QrGroup'
import { useGlobalInfos } from '../../hooks/useGlobalInfos'
import { Spinner } from 'react-bootstrap'
import * as S from './styles'
import { useParams } from '../../hooks/useParams'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'

export const ProductsPage = () => {
  const { location, params, navigate } = useParams()
  const { units, categories } = useGlobalInfos()
  const {
    productsData,
    getProducts,
    selectedProduct,
    isOpenProductModal,
    setIsOpenProductModal,
    handleExportProductList,
    isExportingProducts,
    isOpenExportPackingListModal,
    setIsOpenExportPackingListModal,
    isOpenQrCodeProductModal,
    setIsOpenQrCodeProductModal,
    isOpenConfirmDelete,
    setisOpenConfirmDelete,
    productsToGenerateQRCode,
    generateAllQrCodes,
    handleDownloadSingleQRCode,
    handleSubmitForm,
    handleOpenEditProductModal,
    handleOpenModalDeleteProduct,
    handleQR,
    handleCreate,
    isLoadingProductList,
    isDownloadingQRCodes,
  } = useProducts()
  const [searchText, setSearchText] = useState(params?.get('name') ?? '')

  useEffect(() => {
    getProducts()
  }, [getProducts, location.search])

  const productTableOptions: MUIDataTableOptions = {
    searchText: searchText,
    customToolbar: () => {
      return (
        <CustomToolbar
          handleCreate={handleCreate}
          units={units}
          categories={categories}
          params={params}
        />
      )
    },
    filter: false,
    serverSide: true,
    page: Number(params.get('page') ?? 1) - 1,
    count: productsData.amount,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoadingProductList ? (
          <Spinner />
        ) : (
          'Ops... Nenhum registro encontrado.'
        ),
      },
    },
  }

  const productTableColumns = useMemo(() => {
    return TableColumns(
      productsData.data,
      handleOpenEditProductModal,
      handleOpenModalDeleteProduct,
      handleQR,
      params,
    )
  }, [
    productsData.data,
    handleOpenEditProductModal,
    handleOpenModalDeleteProduct,
    handleQR,
    params,
  ])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText) {
        params.set('name', searchText)
        params.set('page', '1')
        navigate(`?${params.toString()}`)
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={productTableColumns}
          data={isLoadingProductList ? [] : productsData.data}
          options={productTableOptions}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Pesquise pelo nome do produto"
          onClear={() => {
            setSearchText('')
            params.delete('name')
            params.set('page', '1')
            navigate(`?${params.toString()}`)
          }}
        />
      </S.TableWrapper>
      <S.ButtonsWrapper>
        <S.ExportButtons>
          <Button
            onClick={handleExportProductList}
            disabled={isExportingProducts}
            loading={isExportingProducts}
          >
            Exportar
          </Button>

          <Button onClick={() => setIsOpenExportPackingListModal(true)}>
            Relatório de Romaneio
          </Button>
        </S.ExportButtons>
        <Button
          disabled={isDownloadingQRCodes}
          loading={isDownloadingQRCodes}
          onClick={generateAllQrCodes}
        >
          {isDownloadingQRCodes
            ? 'Gerando lista de QR Codes...'
            : 'Gerar lista de QR Code'}
        </Button>
      </S.ButtonsWrapper>
      {isOpenQrCodeProductModal && (
        <ProductQrCodeModal
          isOpen={isOpenQrCodeProductModal}
          handleCloseModal={() => setIsOpenQrCodeProductModal(false)}
          product={selectedProduct || ({} as IProduct)}
          handleExportQr={handleDownloadSingleQRCode}
        />
      )}
      {isOpenConfirmDelete && (
        <DeleteModal
          data={{
            type: 'Produto',
            text: 'o produto',
            values: { nome: selectedProduct?.name, id: selectedProduct?.id },
          }}
          show={isOpenConfirmDelete}
          onHide={() => setisOpenConfirmDelete(false)}
          parentCallback={() => getProducts()}
        />
      )}
      {isOpenProductModal && (
        <ProductModal
          isOpen={isOpenProductModal}
          handleCloseModal={() => setIsOpenProductModal(false)}
          product={selectedProduct}
          units={units}
          handleSubmitForm={handleSubmitForm}
        />
      )}
      {isOpenExportPackingListModal && (
        <ExportPackingListModal
          isOpen={isOpenExportPackingListModal}
          handleCloseModal={() => setIsOpenExportPackingListModal(false)}
        />
      )}
      <QrGroup novoArray={productsToGenerateQRCode} />
    </>
  )
}

export default ProductsPage

import { Modal, Stack, Row, Col } from 'react-bootstrap'
import { TYPE_OPTIONS, ORIGIN_OPTIONS, STOCK_OPTIONS } from '../../constants'
import { useExportInventoryMovements } from './hooks/useExportMovents'
import Select from '../../../../Components/Forms/Select'
import TextField from '../../../../Components/Forms/TextField'
import Button from '../../../../Components/Button'
import MultiSelect from '../../../../Components/Forms/MultiSelect'
import { useGlobalInfos } from '../../../../hooks/useGlobalInfos'

interface ExportMovementsModalProps {
  isOpenExportModal: boolean
  handleCloseExportModal: () => void
}

export function ExportMovementsModal({
  isOpenExportModal,
  handleCloseExportModal,
}: ExportMovementsModalProps) {
  const { units } = useGlobalInfos()
  const {
    exportInventoryMovementFormMethods: { control, handleSubmit, reset },
    isUnitStock,
    handleExportInventoryMovments,
    isExportingInventoryMovements,
  } = useExportInventoryMovements()

  return (
    <Modal
      show={isOpenExportModal}
      onHide={handleCloseExportModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Exportar movimentações</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack gap={3} direction="vertical">
          <Row>
            <Col>
              <TextField
                control={control}
                label="Data inicial"
                name="initial_date"
                type="date"
              />
            </Col>

            <Col>
              <TextField
                control={control}
                label="Data final"
                name="final_date"
                type="date"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                control={control}
                label="Tipo"
                name="type"
                options={TYPE_OPTIONS}
              />
            </Col>
            <Col>
              <Select
                control={control}
                label="Natureza"
                name="origin"
                options={ORIGIN_OPTIONS}
              />
            </Col>
            <Col>
              <Select
                control={control}
                label="Estoque"
                name="stock"
                options={STOCK_OPTIONS}
              />
            </Col>
          </Row>
          {isUnitStock && (
            <Row>
              <Col>
                <MultiSelect
                  control={control}
                  label="Unidades"
                  name="units"
                  options={units.map((unit) => ({
                    value: unit.id,
                    label: unit.nome,
                  }))}
                  placeholder="Selecione uma ou mais unidades"
                />
              </Col>
            </Row>
          )}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isExportingInventoryMovements}
          onClick={() => {
            reset()
            handleCloseExportModal()
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          color="green"
          onClick={handleSubmit(handleExportInventoryMovments)}
          disabled={isExportingInventoryMovements}
        >
          {isExportingInventoryMovements ? 'Exportando' : 'Exportar'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const textLabels = {
  body: {
    noMatch: 'Ops... Nenhum registro encontrado',
    toolTip: 'Classificar',
  },
  pagination: {
    next: 'Próxima página',
    previous: 'Página anterior',
    rowsPerPage: 'Linhas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Busca',
    downloadCsv: 'Download CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Colunas',
    filterTable: 'Filtrar Tabelas',
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'LIMPAR',
  },
  viewColumns: {
    title: 'Ver Colunas',
    titleAria: 'Ver/Esconder Colunas da Tabela',
  },
  selectedRows: {
    text: 'registros(s) selecionados',
    delete: 'Excluir',
    deleteAria: 'Excluir registros selecionados',
  },
}

import { Row, Stack } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useGlobalInfos } from '../../../../hooks/useGlobalInfos'
import Select from '../../../../Components/Forms/Select'

export function ReportsFilter() {
  const { units } = useGlobalInfos()
  const { control } = useFormContext()
  return (
    <Stack gap={3} direction="vertical">
      <Row>
        <Select
          name="unit"
          control={control}
          label="Unidade"
          options={[
            { value: '', label: 'Todas as unidades' },
            ...units.map((unit) => ({ value: unit.id, label: unit.nome })),
          ]}
          error={null}
        />
      </Row>
    </Stack>
  )
}

import { MUIDataTableOptions } from 'mui-datatables'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import DeleteModal from '../../Components/Modal/DeleteModal'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import api from '../../services/api'
import TableColumns from './CategoriesTableColumns'
import CategoryModal from './CategoryModal'
import CustomToolbar from './CustomToolbar'
import Table from '../../Components/Table'

const categoryInit = {
  id: -1,
  nome: '',
  descricao: '',
}
export const Categories = () => {
  const [modalShow, setModalShow] = useState(false)
  const [delModalShow, setDelModalShow] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryData, setCategoryData] = useState(categoryInit)
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)

  const loadData = async () => {
    try {
      setLoading(false)
      setCategories([])
      await api.get('/categories').then((res) => {
        setCategories(res.data)
        setLoading(true)
      })
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  const handleCreate = () => {
    setCategoryData(categoryInit)
    setEdit(false)
    setModalShow(true)
  }

  const handleEdit = (id: number) => {
    const resId = categories.findIndex((res: any) => res.id === id)
    const tmp = categories[resId]
    setCategoryData(tmp)
    setEdit(true)
    setModalShow(true)
  }

  const handleDel = (id: number) => {
    const resId = categories.findIndex((res: any) => res.id === id)
    const tmp = categories[resId]
    setCategoryData(tmp)
    setDelModalShow(true)
  }

  const options: MUIDataTableOptions = {
    filter: false,
    rowsPerPage: 50,
    selectableRows: 'none',
    responsive: 'standard',
    fixedHeader: true,
    viewColumns: false,
    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar handleCreate={handleCreate} />
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      filename: `${new Date()
        .toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .split('/')
        .reverse()
        .join('-')}_${new Date()
        .toLocaleTimeString('pt-BR', {
          minute: '2-digit',
          hour: '2-digit',
        })
        .replace(':', '-')}-categorias-convenmais.csv`,
    },
    textLabels: {
      ...textLabels,
    },
  }

  const columns = TableColumns(handleEdit, handleDel)

  return !loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
        }}
      >
        <Table data={categories} columns={columns} options={options} />
      </div>
      <DeleteModal
        data={{
          type: 'Categoria',
          text: 'a categoria',
          values: categoryData,
        }}
        show={delModalShow}
        onHide={() => setDelModalShow(false)}
        parentCallback={loadData}
      />
      <CategoryModal
        categoryData={categoryData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        parentCallback={loadData}
        edit={edit}
      />
    </>
  )
}

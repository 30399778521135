/* eslint-disable no-useless-escape */
export const convertCentsToLocale = (priceInCents: number) =>
  Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(priceInCents / 100)

export function convertComa(value: string) {
  return value
    .replace(/(?![,])\D/g, '')
    .replace(/\s/g, '')
    .replace(/,/g, '.')
}

import styled from 'styled-components'

export const TableWrapper = styled.div`
  width: 100%;
  box-shadow: none;
  height: 100%;
`

export const TableActionsButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const TableActionButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
`

import { useFormContext } from 'react-hook-form'
import { Col, Row, Stack } from 'react-bootstrap'
import Select from '../../../../Components/Forms/Select'
import MultiSelect from '../../../../Components/Forms/MultiSelect'
import TextField from '../../../../Components/Forms/TextField'
import { PAYMENT_OPTIONS, TRANSACTION_STATUS } from '../../constants'
import { useMemo } from 'react'
import { IUnit } from '../../../Units/types'

interface TransactionsFilterProps {
  units: IUnit[]
}

export function TransactionsFilter({ units }: TransactionsFilterProps) {
  const { control, watch } = useFormContext()

  const selectedUnits = watch('units')

  const defaultSelectedUnits = useMemo(() => {
    if (selectedUnits?.length > 0) {
      const arrayToMap =
        selectedUnits.length > 1 ? selectedUnits : new Array(selectedUnits)
      return arrayToMap?.map((item: IUnit) => {
        const currentUnit = units.find((unit) => unit.id === Number(item))

        return {
          value: currentUnit?.id,
          label: currentUnit?.nome,
        }
      })
    }
    return []
  }, [selectedUnits, units])

  return (
    <Stack gap={3} direction="vertical">
      <Row>
        <Col>
          <TextField
            control={control}
            label="Data inicial"
            name="initial_date"
            type="date"
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="Data final"
            name="final_date"
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            control={control}
            label="Nome"
            name="name"
            placeholder="Pesquise pelo nome"
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="CPF"
            name="cpf"
            placeholder="Pesquise pelo CPF"
          />
        </Col>
      </Row>
      <Row>
        <MultiSelect
          control={control}
          label="Unidades"
          name="units"
          options={units.map((unit) => ({
            value: unit.id,
            label: unit.nome,
          }))}
          placeholder="Selecione uma ou mais unidades"
          defaultValues={defaultSelectedUnits}
        />
      </Row>
      <Row>
        <Col>
          <Select
            name="status"
            control={control}
            label="Status"
            options={[
              { value: '', label: 'Todos os status' },
              ...TRANSACTION_STATUS,
            ]}
          />
        </Col>
        <Col>
          <Select
            name="payment"
            control={control}
            label="Pagamento"
            options={[
              { value: '', label: 'Todos os pagamentos' },
              ...PAYMENT_OPTIONS,
            ]}
          />
        </Col>
      </Row>
    </Stack>
  )
}

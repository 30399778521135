import { toast } from 'react-toastify'
import api from '../../../services/api'
import { ISupplier } from '../types'

export type IMutateSupplier = Omit<ISupplier, 'id'>

export async function getAllSuppliers() {
  try {
    const response = await api.get<ISupplier[]>(`/backoffice/suppliers`)
    return response.data
  } catch (error) {
    console.log(error)
    toast.error('Houve um erro ao buscar todos os fornecedores')
  }
}

export async function createNewSupplier(data: IMutateSupplier) {
  try {
    const response = await api.post(`/backoffice/suppliers`, data)
    toast.success('Fornecedor criado com sucesso')
    return response.data
  } catch (error) {
    console.log(error)
    toast.error('Houve um criar novo fornecedor')
  }
}

export async function editSupplier(supplierId: number, data: IMutateSupplier) {
  try {
    const response = await api.patch(
      `/backoffice/suppliers/${supplierId}`,
      data,
    )
    toast.success('Informações atualizadas com sucesso')
    return response.data
  } catch (error) {
    console.log(error)
    toast.error('Houve um editar informações do fornecedor')
  }
}

export async function deleteSupplier(supplierId: number) {
  try {
    await api.delete(`/backoffice/suppliers/${supplierId}`)
    toast.success('Fornecedor excluído com sucesso!')
  } catch (error) {
    console.log(error)
    toast.error('Houve um editar excluir o do fornecedor')
  }
}

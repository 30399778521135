import AccountCircle from '@mui/icons-material/AccountCircle'
import { AppBar, Button, Toolbar, Typography, styled } from '@mui/material'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUser } from '../../contexts/auth'
import useAuth from '../../hooks/useAuth'
import { RootState } from '../../redux/store'
import appRoutes from '../../routes/appRoutes'
import Title from '../Title'

import { theme } from '../../styles/theme'

const ButtonSignout = styled(Button)({
  boxShadow: 'none',
  border: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: 0,
  textDecoration: 'underline',
  color: 'rgba(0,0,0,.5)',
  '&:hover': {
    color: '#000',
    backgroundColor: 'transparent',
    textDecoration: 'none',
  },
})

const Topbar = () => {
  const { appState }: any = useSelector((state: RootState) => state.appState)

  const menu = appRoutes.filter((menu) => menu.state === appState)
  const title = menu[0]?.sidebarProps?.displayText

  const getUserAuth = getUser()
  const user = getUserAuth ? JSON.parse(getUserAuth) : null
  const { signout }: any = useAuth()
  const navigate = useNavigate()

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'unset',
        backgroundColor: 'transparent',
        color: theme.colors.blackColor,
        padding: '0',
      }}
    >
      <Toolbar sx={{ alignItems: 'flex-end', padding: '12px 0 !important' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Title underline variant="h1">
            {title}
          </Title>
        </Box>
        <Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              color: '#212529',
            }}
          >
            <AccountCircle sx={{ fontSize: '18px' }} />
            <Typography sx={{ minWidth: 100, color: theme.colors.blackColor }}>
              Olá, {user?.username}
            </Typography>
          </Box>
          <ButtonSignout onClick={() => [signout(), navigate('/')]}>
            (desconectar)
          </ButtonSignout>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar

import api from '../../../services/api'

export async function getAllDoorAccess(params: string) {
  try {
    const response = await api.get(`/backoffice/reports/door-access${params}`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

import { ArrowForward, Delete, FileDownload } from '@mui/icons-material'
import { format } from 'date-fns'
import { IInventory } from '../../types'
import { Tooltip } from '@material-ui/core'

interface TableColumnsProps {
  onDeleteInventory: (id: number) => void
  onExportSingleInventory: (id: number) => void
  inventories: IInventory[]
}

export function TableColumns({
  onDeleteInventory,
  onExportSingleInventory,
  inventories,
}: TableColumnsProps) {
  const columns = [
    { name: 'unit_name', label: 'Unidade' },
    {
      name: 'user_name',
      label: 'Usuário',
      options: {
        filter: false,
      },
    },
    {
      name: 'created_at',
      label: 'Data de criação',
      options: {
        filter: false,
        customBodyRender: (value: string) =>
          format(new Date(value), 'dd/MM/yyyy hh:MM:ss'),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (id: number, tableData: { rowIndex: number }) => {
          const currentLine = inventories[tableData.rowIndex]
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Tooltip title="Baixar relatório">
                <FileDownload
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onExportSingleInventory(id)}
                />
              </Tooltip>
              <Tooltip title="Excluir inventário">
                <Delete
                  sx={{ color: '#e30000', cursor: 'pointer' }}
                  onClick={() => onDeleteInventory(id)}
                />
              </Tooltip>
              <a
                href={`inventario/${id}`}
                style={{
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Tooltip title="Ver detalhes">
                  <ArrowForward />
                </Tooltip>
              </a>
            </div>
          )
        },
        sort: false,
      },
    },
  ]

  return columns
}

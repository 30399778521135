import api from '../../../../services/api'
import { PaginationQuery } from '../../../InventoryMovements/types'
import { PaginatedInventoryListResponse } from '../types'

interface CreateInventoryResponse {
  inventory: number
}

interface ExportSingleInventoryResponse {
  csv: string
  unit_name: string
  data: Date
}

export interface GenerateCompleteInventoryItemsReportDTO {
  initial_date: string | null
  final_date: string | null
  unit_id: number | null
  product_id: number | null
  product_name: string | null
}

export async function getAllInventories({
  page,
  rowsPerPage,
}: PaginationQuery) {
  try {
    const response = await api.get<PaginatedInventoryListResponse>(
      `/backoffice/inventory?page=${page}&rowsPerPage=${rowsPerPage}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao buscar os inventários')
  }
}

export async function createInventory(unit_id: string) {
  try {
    const response = await api.post<CreateInventoryResponse>(
      '/backoffice/inventory',
      { unit_id },
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao criar novo inventário')
  }
}

export async function deleteInventory(id: number) {
  try {
    const response = await api.delete<CreateInventoryResponse>(
      `/backoffice/inventory/${id}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao excluir o inventário')
  }
}

export async function exportSingleInventory(id: number) {
  try {
    const response = await api.get<ExportSingleInventoryResponse>(
      `/backoffice/inventory/item/export/${id}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error(
      'Houve um erro ao gerar relatório do inventário selecionado',
    )
  }
}

export async function exportCompleteInventoryReport(
  data: GenerateCompleteInventoryItemsReportDTO,
) {
  try {
    const response = await api.post<ExportSingleInventoryResponse>(
      `/backoffice/inventory/item/export`,
      data,
    )
    return response.data
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        'Não foi possível gerar o relatório de inventário. Tente novamente',
    )
  }
}

export const theme = {
  colors: {
    primaryColor: '#fd9800',
    secondaryColor: '#ff8728',
    tercColor: '#ffbb00',
    // primaryColor: '#d3a971',
    // secondaryColor: '#A57553',
    primaryColorEfect: '#a88659',
    whiteColor: '#FFFFFF',
    blackColor: '#212529',
    bg: '#ccc',
  },
  fonts: {
    family: {
      default: "'Open Sans', sans-serif",
      secondary: "'Montserrat', sans-serif",
    },
  },
  spacing: {
    padding10: '10px',
  },
}

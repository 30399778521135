import { MUIDataTableOptions } from 'mui-datatables'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'

export const DEFAULT_TABLE_OPTIONS: MUIDataTableOptions = {
  rowsPerPage: 50,
  rowsPerPageOptions: [30, 50, 100],
  selectableRows: 'none',
  responsive: 'standard',
  fixedHeader: true,
  search: false,
  download: false,
  print: false,
  textLabels,
  sort: false,
  viewColumns: true,
  serverSide: true,
  filter: false,
}

import { Stack } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TextField from '../../../../../../Components/Forms/TextField'
import Select from '../../../../../../Components/Forms/Select'
import {
  ORIGIN_OPTIONS,
  STOCK_OPTIONS,
  TYPE_OPTIONS,
} from '../../../../constants'
import MultiSelect from '../../../../../../Components/Forms/MultiSelect'
import InputMoney from '../../../../../../Components/Forms/InputMoney'
import { Autocomplete } from '../../../../../../Components/Forms/Autocomplete'
import { useGlobalInfos } from '../../../../../../hooks/useGlobalInfos'
import { useSuppliers } from '../../../../../Suppliers/hooks/useSuppliers'
import {
  InventoryMovementDTO,
  MovementOriginEnum,
  MovementStockEnum,
  MovementTypeEnum,
} from '../../../../types'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

interface InventoryMovementFormProps {
  products: {
    value: number
    label: string
  }[]
  isLoadingProducts: boolean
}

export function InventoryMovementForm({
  products,
  isLoadingProducts,
}: InventoryMovementFormProps) {
  const { control, formState, setValue, watch } =
    useFormContext<InventoryMovementDTO>()
  const { units } = useGlobalInfos()
  const stock = watch('stock')
  const type = watch('type')
  const origin = watch('origin')
  const isUnitStock = watch('stock') === ('unit' as MovementStockEnum)

  const isInputInGlobalStock = useMemo(() => {
    return (
      stock === ('global' as MovementStockEnum) &&
      type === ('input' as MovementTypeEnum) &&
      origin === ('reposition' as MovementOriginEnum)
    )
  }, [stock, type, origin])

  const { suppliers = [] } = useSuppliers({
    fetchSuppliers: isInputInGlobalStock,
  })

  return (
    <Stack gap={2} direction="vertical">
      <Autocomplete
        control={control}
        label="Produto"
        name="product_name"
        placeholder="Escolha o produto"
        onSelectOption={(option: number) => setValue('product_id', option)}
        options={products || []}
        hintText="Digite o nome do produto..."
        isLoadingInfo={isLoadingProducts}
      />

      <Row>
        <Col>
          <Select
            control={control}
            label="Tipo"
            name="type"
            options={TYPE_OPTIONS}
            error={formState.errors['type']}
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="Quantidade"
            name="quantity"
            placeholder="Insira a quantidade"
            error={formState.errors['quantity']}
            type="number"
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            control={control}
            label="Natureza"
            name="origin"
            options={ORIGIN_OPTIONS}
            error={formState.errors['origin']}
          />
        </Col>
        <Col>
          <Select
            control={control}
            label="Estoque"
            name="stock"
            options={STOCK_OPTIONS}
            error={formState.errors['stock']}
          />
        </Col>
      </Row>
      {isInputInGlobalStock && (
        <>
          <Row>
            <Col>
              <Select
                control={control}
                label="Fornecedor"
                name="supplier_id"
                options={suppliers.map((supplier) => ({
                  label: supplier.name,
                  value: supplier.id,
                }))}
                error={formState.errors['supplier_id']}
              />
            </Col>
            <Col>
              <InputMoney
                control={control}
                label="Custo"
                name="cost"
                placeholder="00,00"
                error={formState.errors['cost']}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                control={control}
                label="Nº da Nota Fiscal"
                placeholder="00000"
                name="invoice_number"
                error={formState.errors['invoice_number']}
              />
            </Col>
            <Col>
              <TextField
                control={control}
                label="Validade"
                name="product_expiration_date"
                type="date"
                error={formState.errors['product_expiration_date']}
              />
            </Col>
          </Row>
        </>
      )}
      {isUnitStock && (
        <Row>
          <Col>
            <MultiSelect
              control={control}
              label="Unidades"
              name="units"
              options={units.map((unit) => ({
                value: unit.id,
                label: unit.nome,
              }))}
              placeholder="Selecione uma ou mais unidades"
            />
          </Col>
        </Row>
      )}
    </Stack>
  )
}

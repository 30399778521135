import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Formik } from 'formik'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import * as yup from 'yup'
import Button from '../../Components/Button'
import api from '../../services/api'

interface Props {
  unitData: any
  show: boolean
  onHide: () => void
  parentCallback: () => Promise<void>
  edit?: boolean
}

export default function UnitModal(props: Props) {
  const schema = yup.object({
    nome: yup.string().min(2).required(),
    rua: yup.string(),
    bairro: yup.string(),
    cidade: yup.string(),
    estado: yup.string(),
    // cep: yup.string(),
    endereco_ip: yup.string(),
  })

  const handleSubmit = (event: any) => {
    function postCategories() {
      api.post('/units', event).then(() => {
        // console.log(response)
        props.parentCallback()
      })
    }

    function patchCategories() {
      api.patch(`/units/${props.unitData.id}`, event).then(() => {
        // console.log(response)
        props.parentCallback()
      })
    }

    if (props.unitData.id === -1) postCategories()
    else patchCategories()
    props.onHide()
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {' '}
          {props.edit ? 'Editar Unidade' : 'Cadastrar Unidade'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={props.unitData}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate id="unitForm" onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} controlId="validacaoNome">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    placeholder="Nome"
                    type="text"
                    name="nome"
                    value={values?.nome}
                    onChange={handleChange}
                    isInvalid={!!errors.nome}
                    isValid={touched.nome && !errors.nome}
                  />
                  <Form.Control.Feedback type="invalid">
                    Nome é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validacaoRua">
                  <Form.Label>Rua</Form.Label>
                  <Form.Control
                    placeholder="Rua"
                    type="text"
                    name="rua"
                    value={values?.rua}
                    onChange={handleChange}
                    isInvalid={!!errors.rua}
                    isValid={touched.rua && !errors.rua}
                  />
                  <Form.Control.Feedback type="invalid">
                    Rua é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validacaoBairro">
                  <Form.Label>Bairro</Form.Label>
                  <Form.Control
                    placeholder="Bairro"
                    type="text"
                    name="bairro"
                    value={values?.bairro}
                    onChange={handleChange}
                    isInvalid={!!errors.bairro}
                    isValid={touched.bairro && !errors.bairro}
                  />
                  <Form.Control.Feedback type="invalid">
                    Bairro é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="6" controlId="validacaoCidade">
                  <Form.Label>Cidade</Form.Label>
                  <Form.Control
                    placeholder="Cidade"
                    type="text"
                    name="cidade"
                    value={values?.cidade}
                    onChange={handleChange}
                    isInvalid={!!errors.cidade}
                    isValid={touched.cidade && !errors.cidade}
                  />
                  <Form.Control.Feedback type="invalid">
                    Cidade é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="validacaoEstado">
                  <Form.Label>Estado</Form.Label>
                  <Form.Control
                    placeholder="AB"
                    type="text"
                    name="estado"
                    value={values?.estado}
                    onChange={handleChange}
                    isInvalid={!!errors.estado}
                    isValid={touched.cep && !errors.cep}
                  />
                  <Form.Control.Feedback type="invalid">
                    Estado é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="validacaoEnderecoIp">
                  <Form.Label>Endereço IP</Form.Label>
                  <Form.Control
                    placeholder="Endereço IP"
                    type="text"
                    name="endereco_ip"
                    value={values?.endereco_ip}
                    onChange={handleChange}
                    isInvalid={!!errors.endereco_ip}
                    isValid={touched.endereco_ip && !errors.endereco_ip}
                  />
                  <Form.Control.Feedback type="invalid">
                    IP é um campo obrigatório!
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancelar</Button>
        <Button form="unitForm" type="submit">
          <SaveAsIcon /> Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

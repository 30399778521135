import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CreateInventoryFromXml,
  InventoryMovementDTO,
  MovementStockEnum,
  PaginatedInventoryMovementsResponse,
  XMLToProductListResponse,
} from '../types'
import { toast } from 'react-toastify'
import {
  CreateInventoryFromXmlProductsProps,
  convertXmlToProductList,
  createInventoryFromXmlProducts,
  createNewInventoryMovement,
  getInventoryMovements,
} from '../services'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  inventoryMovementSchema,
  inventoryMovementsXmlForm,
} from '../validators/inventory-movement.schema'
import { useProducts } from '../../Products/hooks/useProducts'
import { formatProductsToAutocomplete } from '../formaters'
import { DEFAULT_INVENTORY_MOVEMENT_FIELDS } from '../constants'
import { ImportFileStepEnum } from '../components/CreateInventoryModal/constants'

export function useInventoryMovements() {
  const { products, setSearchProductText, isLoadingProductsByName } =
    useProducts()
  const [movements, setMovements] =
    useState<PaginatedInventoryMovementsResponse>(
      {} as PaginatedInventoryMovementsResponse,
    )
  const [
    isOpenExportInventoryMovementsModal,
    setIsOpenExportInventoryMovementsModal,
  ] = useState(false)
  const [isLoadingMovements, setIsLoadingMovements] = useState(false)
  const [isOpenInventoryMovementsModal, setIsOpenInventoryMovementsModal] =
    useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [importFileCurrentStep, setImportFileCurrentStep] = useState(
    ImportFileStepEnum.upload,
  )
  const [convertedProductList, setConvertedProductList] =
    useState<XMLToProductListResponse | null>(null)

  const inventoryMovementFormMethods = useForm<InventoryMovementDTO>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: DEFAULT_INVENTORY_MOVEMENT_FIELDS,
    resolver: yupResolver(inventoryMovementSchema) as any,
  })

  const uploadProductsFromXmlFormMethods = useForm<CreateInventoryFromXml>({
    defaultValues: {
      products: [],
      supplier: {
        document: '',
        name: '',
      },
    },
    resolver: yupResolver(inventoryMovementsXmlForm) as any,
  })

  const productName = inventoryMovementFormMethods.watch('product_name')

  const getAllInventoryMovements = useCallback(async (search: string) => {
    setIsLoadingMovements(true)
    try {
      const data = await getInventoryMovements(search)
      setMovements(data)
      setIsLoadingMovements(false)
    } catch (error: any) {
      setIsLoadingMovements(false)
      return toast.error(error.message)
    }
  }, [])

  async function createInventoryMovement(
    payload: InventoryMovementDTO,
  ): Promise<void> {
    try {
      delete payload.product_name
      const data = await createNewInventoryMovement(payload)
      await getAllInventoryMovements('')
      toast.success(data.message)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function getProductListFromXml() {
    const formData = new FormData()

    if (fileToImport) {
      formData.append('file', fileToImport)
    }

    try {
      const response = await convertXmlToProductList(formData)
      uploadProductsFromXmlFormMethods.setValue('products', response.products)
      uploadProductsFromXmlFormMethods.setValue(
        'supplier',
        response.xml.supplier,
      )
      setConvertedProductList(response)
      setImportFileCurrentStep(ImportFileStepEnum.review)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function createInventoryFromProductList(
    values: CreateInventoryFromXmlProductsProps,
  ) {
    try {
      const data = await createInventoryFromXmlProducts(values)
      setIsOpenInventoryMovementsModal(false)
      await getAllInventoryMovements('')
      toast.success(data.message)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (productName) {
      setSearchProductText(productName)
    }
  }, [productName, setSearchProductText])

  const productsOptions = useMemo(
    () => formatProductsToAutocomplete(productName || '', products),
    [productName, products],
  )

  return {
    movements,
    createInventoryMovement,
    inventoryMovementFormMethods,
    getAllInventoryMovements,
    isOpenExportInventoryMovementsModal,
    setIsOpenExportInventoryMovementsModal,
    productsOptions,
    isLoadingMovements,
    isLoadingProductsByName,
    isOpenInventoryMovementsModal,
    setIsOpenInventoryMovementsModal,
    selectedTab,
    setSelectedTab,
    fileToImport,
    setFileToImport,
    importFileCurrentStep,
    setImportFileCurrentStep,
    getProductListFromXml,
    convertedProductList,
    uploadProductsFromXmlFormMethods,
    createInventoryFromProductList,
  }
}

import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ReorderIcon from '@mui/icons-material/Reorder'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import logoSmall from '../../assets/images/logo-white-short.png'
import logo from '../../assets/images/logo-white.png'
import { RootState } from '../../redux/store'
import appRoutes from '../../routes/appRoutes'
import { theme } from '../../styles/theme'
import useAuth from '../../hooks/useAuth'
import { useMemo } from 'react'

const MiniDrawer = () => {
  const { collapseSidebar, collapsed } = useProSidebar()
  const { user }: any = useAuth()

  const { appState } = useSelector((state: RootState) => state.appState)

  const sidebarOptions = useMemo(() => {
    if (!user) {
      return [appRoutes[0]]
    }

    return appRoutes.filter((route) => {
      if (route.permission.includes(user?.data?.permission)) {
        return route
      }
    })
  }, [user])

  return (
    <div
      id="app"
      style={{ height: '100vh', display: 'flex', flexDirection: 'row' }}
    >
      <Sidebar
        width="225px"
        collapsedWidth="80px"
        breakPoint="sm"
        // transitionDuration={800}
        backgroundColor="#343a40"
        rtl={false}
        style={{ height: '100vh' }}
      >
        <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }: any) => {
              // only apply styles on first level elements of the tree
              if (level === 0)
                return {
                  color: disabled ? '#ccc' : '#fff',
                  backgroundColor: active
                    ? theme.colors.primaryColor
                    : undefined,
                  display: 'flex',
                  height: '45px',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundColor: theme.colors.primaryColor,
                  },
                }
            },
            icon: () => {
              return {
                marginLeft: '18px',
                marginRight: '0',
                svg: {
                  width: '21px',
                },
              }
            },
            label: () => {
              return { flexGrow: 'unset', marginRight: '20px' }
            },
          }}
        >
          <MenuItem
            icon={collapsed ? <ReorderIcon /> : <KeyboardDoubleArrowLeftIcon />}
            onClick={() => {
              collapseSidebar()
            }}
            style={{ textAlign: 'center', textTransform: 'uppercase' }}
          >
            {' '}
            <span>Minimizar</span>
          </MenuItem>
          <Box
            sx={{
              textAlign: 'center',
              backgroundColor: 'rgb(255 255 255 / 5%)',
            }}
          >
            {collapsed ? (
              <img
                style={{ margin: '1rem auto' }}
                height={50}
                src={logoSmall}
                alt="ConVen+"
              />
            ) : (
              <img
                style={{ margin: '1rem' }}
                src={logo}
                height={50}
                alt="ConVen+"
              />
            )}
          </Box>
          {sidebarOptions.map((route, index) =>
            route.sidebar ? (
              route.child ? (
                <SubMenu
                  key={index}
                  style={{
                    padding: 0,
                    margin: '.5rem .7rem .25rem',
                    borderRadius: '7px',
                  }}
                  label={route.sidebarProps?.displayText}
                  icon={route.sidebarProps?.icon}
                  component={
                    <Link to={route.child[0].path ? route.child[0].path : ''} />
                  }
                >
                  {route.child?.map((routeChild, index) => (
                    <MenuItem
                      key={index}
                      style={{ padding: 0, margin: '.5rem .7rem .25rem' }}
                      component={
                        <Link to={routeChild.path ? routeChild.path : ''} />
                      }
                    >
                      {routeChild.sidebarProps?.displayText}
                    </MenuItem>
                  ))}
                </SubMenu>
              ) : (
                <MenuItem
                  key={index}
                  component={
                    <Link to={route.path ? route.path : '/dashboard'} />
                  }
                  active={
                    appState === route.state || appState.includes(route.state)
                  }
                  style={{
                    padding: 0,
                    margin: '.5rem .7rem .25rem',
                    borderRadius: '7px',
                  }}
                  icon={route.sidebarProps?.icon}
                >
                  {route.sidebarProps?.displayText}
                </MenuItem>
              )
            ) : null,
          )}
        </Menu>
      </Sidebar>
    </div>
  )
}

export default MiniDrawer

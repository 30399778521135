import api from 'services/api'

export interface IPackingListResponse {
  id: number
  unit_id: number
  unit_name: string
  items: IPackingListItems[]
}

export interface IPackingListItems {
  name: string
  product_id: number
  category_name: string
  available_quantity: number
  registered_quantity: number
  quantityForReposition: number
  checked: boolean
}

export async function getPackingListItems(id: string) {
  try {
    const response = await api.get<IPackingListResponse>(
      `/backoffice/packing-list/${id}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao buscar a lista de romaneio')
  }
}

export async function updatePackingListItems(
  id: number,
  items: IPackingListItems[],
) {
  try {
    const response = await api.patch(`/backoffice/packing-list/${id}`, {
      items,
    })
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao atualizar a quantidade')
  }
}

export async function finishiPacking(id: string) {
  try {
    const response = await api.delete(`/backoffice/packing-list/${id}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao finalizar o romaneio')
  }
}

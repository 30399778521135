export interface IInventoryMovements {
  type: MovementTypeEnum | null
  origin: MovementOriginEnum | null
  stock: MovementStockEnum | null
  unit_price: number | null
  cost: number | null
  quantity: number | null
  unit_name: string | null
  product_name?: string
  product_id: number | null
  category_name: string
  category_id: string | null
  transaction_id: number | null
  user: string | null
  customer: string | null
  sale_price: number | null
  supplier_id: number | null
  supplier_name: string | null
  invoice_number: string | null
  product_expiration_date: string | null
}

export enum MovementTypeEnum {
  input = 'Entrada',
  output = 'Saida',
}

export enum MovementOriginEnum {
  sell = 'Venda',
  reposition = 'Reposição',
  manual = 'Manual',
  new = 'Novo produto',
}

export enum MovementStockEnum {
  global = 'Principal',
  unit = 'Unidade',
}

export type InventoryMovementDTO = Omit<
  IInventoryMovements,
  | 'unit_name'
  | 'category_name'
  | 'category_id'
  | 'transaction_id'
  | 'user'
  | 'customer'
  | 'supplier_name'
>

export type ExportInventoryMovementsDTO = Omit<
  InventoryMovementDTO,
  'product_id' | 'product_name'
> & {
  initial_date: string | null
  final_date: string | null
  units: number[]
}

export interface PaginatedInventoryMovementsResponse {
  amount: number
  current_page: number
  data: IInventoryMovements[]
  last_page: number
  per_page: number
}

export interface PaginationQuery {
  page: number
  rowsPerPage: number
}

interface XMLProductInterface {
  name: string
  type: string
  quantity: number
  cost: number
  total: number
}

export interface XMLProductsUploadInfo {
  info: {
    chNFe: string
    nNF: string
    cNF: string
  }
  supplier: {
    document: string
    name: string
  }
}

export interface XMLToProductListResponse {
  products: XMLProductInterface[]
  xml: XMLProductsUploadInfo
}
export interface CreateInventoryFromXmlProducts extends XMLProductInterface {
  product_id?: number
  product_name?: string
  amount?: number
  gtin?: string
}

export interface CreateInventoryFromXml {
  products: CreateInventoryFromXmlProducts[]
  supplier: {
    document: string
    name: string
  }
}

import { Stack, Row, Col } from 'react-bootstrap'
import { useGlobalInfos } from '../../../../hooks/useGlobalInfos'
import { useFormContext } from 'react-hook-form'
import Select from '../../../../Components/Forms/Select'
import TextField from '../../../../Components/Forms/TextField'
import { UserNatureOfIdentificationEnum } from 'pages/Users/constants'

const NATURE_OF_IDENTIFICATION_OPTIONS = [
  { label: UserNatureOfIdentificationEnum.Morador, value: 'morador' },
  { label: UserNatureOfIdentificationEnum.Visitante, value: 'visitante' },
  {
    label: UserNatureOfIdentificationEnum.Prestador,
    value: 'prestador_de_servicos',
  },
]

interface UsersFilterProps {
  params: URLSearchParams
}

export default function UsersFilter({ params }: UsersFilterProps) {
  const { units } = useGlobalInfos()

  const { control } = useFormContext()

  return (
    <Stack gap={3} direction="vertical">
      <Row>
        <Col>
          <TextField
            control={control}
            placeholder="Digite o nome do usuário"
            label="Nome"
            name="name"
          />
        </Col>
        <Col>
          <TextField
            control={control}
            placeholder="Digite o CPF do usuário"
            label="CPF"
            name="cpf"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            name="unit"
            control={control}
            label="Unidade"
            options={[
              { value: '', label: 'Todas as unidades' },
              ...units.map((unit) => ({ value: unit.id, label: unit.nome })),
            ]}
            error={null}
          />
        </Col>
        <Col>
          <Select
            name="nature_identification"
            control={control}
            label="Tipo de Cadastro"
            options={[
              { value: '', label: 'Todos' },
              ...NATURE_OF_IDENTIFICATION_OPTIONS,
            ]}
            error={null}
          />
        </Col>
      </Row>
    </Stack>
  )
}

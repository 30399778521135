import { ChangeEvent, type InputHTMLAttributes } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import {
  type Control,
  Controller,
  FieldError,
  Path,
  useController,
} from 'react-hook-form'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
  useFieldAs?: 'input' | 'textarea'
  startIcon?: JSX.Element | null
  endIcon?: JSX.Element | null
  callbackOnChange?: (value: string) => void
}

export default function TextField({
  name,
  control,
  label,
  error,
  useFieldAs = 'input',
  type = 'text',
  callbackOnChange,
  startIcon: StartIcon = null,
  endIcon: EndIcon = null,
  ...rest
}: TextFieldProps) {
  const {
    field: { onChange, ...fieldProps },
  } = useController({
    name,
    control,
  })

  function handleChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event)
    callbackOnChange?.(event.target.value)
  }

  return (
    <Form.Group>
      {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      <InputGroup>
        {StartIcon && <InputGroup.Text>{StartIcon}</InputGroup.Text>}
        <Form.Control
          isInvalid={!!error}
          {...fieldProps}
          {...rest}
          onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeInput(value)
          }
          type={type}
          as={useFieldAs}
          {...(useFieldAs === 'textarea' && {
            rows: 2,
          })}
          size="sm"
        />
        {EndIcon && (
          <InputGroup.Text
            style={{
              padding: '5px',
              border: error ? '1px solid #dc3545' : '1px solid #dee2e6',
            }}
          >
            {EndIcon}
          </InputGroup.Text>
        )}
        {!!error && (
          <Form.Control.Feedback type="invalid">
            {error.message}
          </Form.Control.Feedback>
        )}
      </InputGroup>
    </Form.Group>
  )
}

import AddIcon from '@mui/icons-material/Add'
import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { theme } from '../../styles/theme'

interface Props {
  handleCreate: () => void
}

export default function CustomToolbar({ handleCreate }: Props) {
  return (
    <React.Fragment>
      <Tooltip title={'adicionar unidade'}>
        <IconButton
          className="button-link"
          style={{
            order: -1,
            fontSize: '16px',
            borderRadius: '12px',
            // padding: '0 14px',
            backgroundColor: theme.colors.primaryColor,
            color: theme.colors.whiteColor,
          }}
          onClick={() => handleCreate()}
        >
          <AddIcon />
          Adicionar Unidade
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

import * as yup from 'yup'
import { cnpj } from 'cpf-cnpj-validator'

export const supplierFormSchema = yup.object({
  name: yup.string().min(2).required('Digite o nome do fornecedor.'),
  document: yup
    .string()
    .test({
      name: 'cnpj-validator',
      test: (document) => cnpj.isValid(document || ''),
      message: 'CNPJ inválido.',
    })
    .required('Digite o CNPJ do fornecedor.'),
  email: yup.string().email('Digite um email válido.'),
  cellphone: yup.string().min(14, 'Campo inválido'),
  seller_name: yup.string().min(2),
  seller_cellphone: yup.string().min(10),
})

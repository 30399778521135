import { MUIDataTableOptions } from 'mui-datatables'
import Table from '../../Components/Table'
import { useParams } from '../../hooks/useParams'
import { CustomTableFilter } from '../../Components/CustomTableFilter'
import { useGlobalInfos } from '../../hooks/useGlobalInfos'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import { Spinner } from 'react-bootstrap'
import { AccessControlFilter } from './components/AccessControlFilters'
import { AccessControlTableColumns } from './components/AccessControlTableColumns'
import { useAccessControl } from './hooks/useAccessControl'
import * as S from './styles'

export function AccessControl() {
  const { data, isLoading } = useAccessControl()
  const { units } = useGlobalInfos()
  const { params, navigate } = useParams()
  const columns = AccessControlTableColumns()

  const options: MUIDataTableOptions = {
    page: Number(params.get('page') ?? 1) - 1,
    count: data?.amount,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    customToolbar: () => (
      <S.TopBar>
        <CustomTableFilter>
          <AccessControlFilter units={units} />
        </CustomTableFilter>
      </S.TopBar>
    ),
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  return (
    <div
      style={{
        width: '100%',
        boxShadow: 'none',
        height: '90%',
        display: 'flex',
      }}
    >
      <Table
        columns={columns}
        data={isLoading ? [] : data?.data}
        options={options}
        showSearchComponent={false}
      />
    </div>
  )
}

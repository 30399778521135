/* eslint-disable no-useless-escape */
import { Col, Row, Stack } from 'react-bootstrap'
import TextField from '../../../Components/Forms/TextField'
import InputMoney from '../../../Components/Forms/InputMoney'
import Select from '../../../Components/Forms/Select'
import { CST_CFOP_VALUES, CST_CSOSN_VALUES, CST_OPTIONS } from '../constants'
import { useFormContext } from 'react-hook-form'
import { IProduct } from '../types'
import { ICategory } from '../../Categories/types'
import Checkbox from '../../../Components/Forms/Checkbox'
import { ProductImage } from './ProductImage'

interface ProductFormProps {
  product?: IProduct | null
  categories: ICategory[]
}

export default function ProductForm({ product, categories }: ProductFormProps) {
  const { setValue, control, formState, watch } = useFormContext<IProduct>()

  function handleChangeCstCallback(value: string) {
    setValue('icms', value)
    setValue('cfop', CST_CFOP_VALUES[value], {
      shouldTouch: true,
      shouldValidate: false,
      shouldDirty: true,
    })
    setValue('csosn', CST_CSOSN_VALUES[value], {
      shouldTouch: true,
      shouldValidate: false,
      shouldDirty: true,
    })
  }

  function calcMarginOfSales() {
    const cost = watch('cost')

    const units = watch('units') ?? []

    for (let i = 0; i < units.length; i++) {
      const price = units[i].value ?? 0
      const profit = price - cost
      const margin = (((price - cost) / cost) * 100).toFixed(2)

      setValue(`units.${i}.margin`, margin)
      const profitPercentage = ((profit / price) * 100).toFixed(2)
      setValue(`units.${i}.profit`, profitPercentage)
    }
  }

  return (
    <Stack gap={3} direction="vertical">
      <ProductImage url={product?.image_url ?? null} />
      <TextField
        control={control}
        label="Nome"
        name="name"
        placeholder="Digite o nome do produto"
        error={formState.errors['name']}
      />

      <TextField
        control={control}
        label="Descrição"
        name="description"
        placeholder="Digite a descrição do produto"
        useFieldAs="textarea"
        error={formState.errors['description']}
      />

      <Row>
        <Col>
          <InputMoney
            control={control}
            label="Custo"
            name="cost"
            placeholder="00,00"
            error={formState.errors['cost']}
            onChangeCallback={() => calcMarginOfSales()}
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="Estoque global"
            name="global_amount"
            placeholder="0"
            error={formState.errors['global_amount']}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Select
            control={control}
            label="Categoria"
            name="category_id"
            options={categories.map((categorie) => ({
              value: categorie.id,
              label: categorie.nome,
            }))}
            error={formState.errors['category_id']}
          />
        </Col>
        <Col>
          <Select
            control={control}
            label="CST"
            name="cst"
            options={CST_OPTIONS}
            error={formState.errors['cst']}
            onChangeCallback={handleChangeCstCallback}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextField
            control={control}
            label="NCM"
            name="ncm"
            placeholder="00000000"
            error={formState.errors['ncm']}
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="GTIN"
            name="gtin"
            placeholder="0000000000000"
            error={formState.errors['gtin']}
          />
        </Col>
        <Col>
          <TextField
            control={control}
            label="CEST"
            name="cest"
            placeholder="0000000"
            error={formState.errors['cest']}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TextField
            control={control}
            label="PIS"
            name="pis"
            placeholder="00"
            error={formState.errors['pis']}
          />
        </Col>

        <Col>
          <TextField
            control={control}
            label="COFINS"
            name="cofins"
            placeholder="00"
            error={formState.errors['cofins']}
          />
        </Col>

        <Col>
          <TextField
            control={control}
            label="IPI"
            name="ipi"
            placeholder="00"
            error={formState.errors['ipi']}
          />
        </Col>

        <Col>
          <TextField
            control={control}
            label="ICMS"
            name="icms"
            placeholder="00"
            error={formState.errors['icms']}
          />
        </Col>

        <Col>
          <TextField
            control={control}
            label="CFOP"
            name="cfop"
            placeholder="00"
            error={formState.errors['cfop']}
          />
        </Col>

        <Col>
          <TextField
            control={control}
            label="CSOSN"
            name="csosn"
            placeholder="00"
            error={formState.errors['csosn']}
          />
        </Col>
      </Row>

      <Checkbox
        control={control}
        label="Produto para maior de idade"
        name="is_product_for_adult"
      />
    </Stack>
  )
}

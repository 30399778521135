import { Col } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledCol = styled(Col)`
  display: flex;
  padding: 0;

  svg {
    margin-top: 35px;
    cursor: pointer;
    color: red;
    font-size: 20px;
  }
`

import AddButton from '../../AddButon'
import * as S from './styles'

interface EmptyUnitProps {
  onClick: () => void
}

export default function EmptyUnit({ onClick }: EmptyUnitProps) {
  return (
    <S.Container>
      <p>Nenhuma unidade selecionada</p>
      <p>Clique no botão abaixo para adicionar uma</p>
      <AddButton onClick={onClick} />
    </S.Container>
  )
}

import { useEffect, useMemo, useState } from 'react'
import Table from '../../../Components/Table'
import { useInventoryItems } from './hooks/useInventoryItem'
import { TableColumns } from './components/TableColumns'
import * as S from './styles'
import Button from '../../../Components/Button'
import { useParams } from 'hooks/useParams'
import { useGlobalInfos } from 'hooks/useGlobalInfos'
import CustomToolbar from './components/CustomToolbar'
import { Spinner } from 'react-bootstrap'

export function InventoryItems() {
  const {
    inventoryItems,
    isLoadingMovementItems,
    getAllInventoryMovementItems,
    onChangeInputAmount,
    onChangeInputObservation,
    saveAndQuitFromInventoryItem,
    updateProductField,
    isSavingInventoryItems,
  } = useInventoryItems()
  const { params, navigate } = useParams()
  const { categories } = useGlobalInfos()

  const filteredProductName = params?.get('name') ?? ''
  const filteredProductCategory = params?.get('category') ?? ''

  const [searchText, setSearchText] = useState(filteredProductName)

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchText) {
        params.set('name', searchText)
      } else {
        params.delete('name')
      }
      navigate(`?${params.toString()}`)
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  const filteredProducts = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let products = inventoryItems

    if (filteredProductCategory) {
      const currentCategory = categories.find(
        (category) => category.id === Number(filteredProductCategory),
      )
      products = products?.filter(
        (product) => product.category_name === currentCategory?.nome,
      )
    }

    if (filteredProductName) {
      products = products?.filter((product) =>
        product['product_name']
          ?.toLowerCase()
          ?.includes(filteredProductName.toLowerCase()),
      )
    }

    return products
  }, [filteredProductName, inventoryItems, filteredProductCategory, categories])

  const tableOptions = {
    customToolbar: () => {
      return <CustomToolbar categories={categories} params={params} />
    },
    searchText,
    filter: false,
    viewColumns: false,
    // rowsPerPage: 99999,
    serverSide: false,
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: isLoadingMovementItems ? (
          <Spinner />
        ) : (
          'Ops... Nenhum registro encontrado.'
        ),
      },
    },
  }

  useEffect(() => {
    getAllInventoryMovementItems()
  }, [getAllInventoryMovementItems])

  const tableColumns = TableColumns({
    onChangeInputAmount,
    onChangeInputObservation,
    filteredProducts,
    updateProductField,
  })

  return (
    <>
      <S.TableWrapper>
        <Table
          columns={tableColumns}
          data={isLoadingMovementItems ? [] : filteredProducts}
          options={tableOptions}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Pesquise pelo nome do produto"
          onClear={() => {
            setSearchText('')
            params.delete('name')
            navigate(`?${params.toString()}`)
          }}
        />
      </S.TableWrapper>
      <S.ButtonsWrapper>
        <Button
          onClick={saveAndQuitFromInventoryItem}
          disabled={isSavingInventoryItems}
          loading={isSavingInventoryItems}
        >
          Finalizar inventário
        </Button>
      </S.ButtonsWrapper>
    </>
  )
}

import api from '../../../../services/api'
import { IInventoryItems } from '../types'

export type UpdateInventoryItemDTO = {
  [key: string]: string | number
}

export async function getAllInventoryItems(inventory_id: number) {
  try {
    const response = await api.get<IInventoryItems[]>(
      `/backoffice/inventory/item?inventory_id=${inventory_id}`,
    )

    return response.data
  } catch (error) {
    throw new Error('Houve um erro ao listar os itens do inventário')
  }
}

export async function finishInventoryItemInfo(id: string) {
  try {
    const response = await api.patch(`/backoffice/inventory/item/finish/${id}`)
    return response.data
  } catch (error: any) {
    throw new Error(
      error.response.data.message || 'Houve um erro ao finalizar o inventário',
    )
  }
}

export async function updateInventoryItemInfo(
  id: number,
  data: UpdateInventoryItemDTO,
) {
  try {
    const response = await api.patch(`/backoffice/inventory/item/${id}`, data)
    return response.data
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        'Houve um erro ao excluir o item ao inventário',
    )
  }
}

/* eslint-disable no-useless-escape */
export const formatCPF = (cpf = '') =>
  cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

export const formatCNPJ = (value = '') => {
  return String(value || '').replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5',
  )
}

export const formatPhone = (phone = '') => {
  return phone
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const removeSpecialCharactersFromString = (value) =>
  value?.replace(/\D+/g, '')

export function formatPercentage(margin) {
  const formattedValue = String(margin).replace(/\,/, '.')

  return formattedValue
    .replace(/(?![.])(\D)/g, '')
    .replace(/(\d*\.\d*)(.*)/, '$1')
    .replace(new RegExp(`(\\d*\\\.\\d{2})(.*)`), '$1')
}

export const CST_OPTIONS = [
  { label: '00', value: '00' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '40', value: '40' },
  { label: '60', value: '60' },
  { label: '70', value: '70' },
]

export const CST_CFOP_VALUES = {
  10: '5405',
  60: '5405',
  70: '5405',
  '00': '5102',
  20: '5102',
  40: '5102',
}

export const CST_CSOSN_VALUES = {
  10: '500',
  60: '500',
  70: '500',
  '00': '102',
  20: '102',
  40: '102',
}

import api from '../../../services/api'
import {
  CreateInventoryFromXml,
  ExportInventoryMovementsDTO,
  InventoryMovementDTO,
  PaginatedInventoryMovementsResponse,
  XMLProductsUploadInfo,
  XMLToProductListResponse,
} from '../types'

export interface CreateInventoryFromXmlProductsProps
  extends CreateInventoryFromXml {
  xml?: XMLProductsUploadInfo
}

export async function getInventoryMovements(search: string) {
  try {
    const response = await api.get<PaginatedInventoryMovementsResponse>(
      `/backoffice/inventory-movements${search}`,
    )

    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao listar as movimentações de estoque')
  }
}

export async function createNewInventoryMovement(
  payload: InventoryMovementDTO,
) {
  try {
    const response = await api.post('backoffice/inventory-movements', payload)
    return response.data
  } catch (error: any) {
    console.log(error)
    throw new Error(
      error.response.data.message ||
        'Houve um erro ao criar uma movimentação de estoque',
    )
  }
}

export async function handleExportInventoryMovements(
  payload: ExportInventoryMovementsDTO,
) {
  try {
    const response = await api.post(
      'backoffice/inventory-movements/export',
      payload,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao exportar as movimentações de estoque')
  }
}

export async function convertXmlToProductList(form: FormData) {
  try {
    const response = await api.post<XMLToProductListResponse>(
      'backoffice/inventory-movements/convert-xml',
      form,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao exportar as movimentações de estoque')
  }
}

export async function createInventoryFromXmlProducts(
  payload: CreateInventoryFromXmlProductsProps,
) {
  try {
    const response = await api.post(
      'backoffice/inventory-movements/create-from-xml',
      payload,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao exportar as movimentações de estoque')
  }
}

import { createContext, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'

export const AuthContext = createContext({})

export const getToken = () => localStorage.getItem('CONVEN@jwt')
export const getUser = () => localStorage.getItem('CONVEN@user')

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState()

  const login = (token, data) => {
    localStorage.setItem('CONVEN@jwt', token)
    localStorage.setItem('CONVEN@user', JSON.stringify(data))
    setUser({ token, data })
  }

  const signout = () => {
    setUser(null)
    localStorage.removeItem('CONVEN@user')
    localStorage.removeItem('CONVEN@jwt')
  }

  useEffect(() => {
    const userInfo = localStorage.getItem('CONVEN@user')
    const userToken = localStorage.getItem('CONVEN@jwt')
    if (userInfo && userToken) {
      setUser({
        token: userToken ?? null,
        data: JSON.parse(userInfo) ?? null,
      })
    }
  }, [])

  const isAuthenticated = () => {
    if (localStorage.getItem('CONVEN@jwt') !== null) {
      const { exp } = jwt_decode(localStorage.getItem('CONVEN@jwt'))
      const expDate = new Date(exp * 1000)
      const currentTime = new Date().getTime()
      // console.log(expDate, currentTime)
      if (currentTime > expDate) {
        // console.log('expirou')
        return false
      }
      // console.log('não expirou')
      return true
    }
    return false
  }

  return (
    // <AuthContext.Provider value={{ user, signed: isAuthenticated(), signin: login, signout }}>
    <AuthContext.Provider
      value={{ user, signed: isAuthenticated(), signin: login, signout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

import { useFormContext } from 'react-hook-form'
import { Autocomplete } from '../../../../../../../../Components/Forms/Autocomplete'
import { CreateInventoryFromXml } from '../../../../../../types'
import { useEffect, useMemo } from 'react'
import { useProducts } from '../../../../../../../Products/hooks/useProducts'
import { formatProductsToAutocomplete } from '../../../../../../formaters'

interface SelectProductProps {
  index: number
}

export function SelectProduct({ index }: SelectProductProps) {
  const { products, setSearchProductText, isLoadingProductsByName } =
    useProducts()
  const { control, setValue, watch } = useFormContext<CreateInventoryFromXml>()

  const productName = watch(`products.${index}.product_name`)

  useEffect(() => {
    if (productName) {
      setSearchProductText(productName)
    }
  }, [productName, setSearchProductText])

  const productsOptions = useMemo(
    () => formatProductsToAutocomplete(productName || '', products),
    [productName, products],
  )

  return (
    <Autocomplete
      control={control}
      label="Produto"
      name={`products.${index}.product_name`}
      placeholder="Selecione o produto"
      onSelectOption={(option: number) =>
        setValue(`products.${index}.product_id`, option)
      }
      options={productsOptions || []}
      hintText="Digite o nome do produto..."
      isLoadingInfo={isLoadingProductsByName}
    />
  )
}

import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import basket from '../../../assets/images/basketIcon.png'
import { IProduct } from '../types'
import Button from '../../../Components/Button'

interface ProductImageProps {
  url: string | null
}

export function ProductImage({ url }: ProductImageProps) {
  const { watch, setValue } = useFormContext<IProduct>()

  const newImageField = watch('image')

  const IMAGE = useMemo(() => {
    if (newImageField) {
      return URL.createObjectURL(newImageField)
    }

    if (url) return url

    return basket
  }, [url, newImageField])

  function handleUploadImage() {
    const inputFileEllement = document.createElement('input')
    inputFileEllement.setAttribute('type', 'file')
    inputFileEllement.setAttribute('accept', 'image/*')

    inputFileEllement.click()

    inputFileEllement.addEventListener('change', (event: any) => {
      const file = event.target.files[0]
      if (file) {
        setValue('image', file)
      }
    })
  }
  return (
    <div>
      <img src={IMAGE} style={{ width: 'auto', maxWidth: '90px' }} />
      <Button onClick={handleUploadImage}>Alterar foto</Button>
    </div>
  )
}

import { Modal } from 'react-bootstrap'
import QRCode from 'qrcode.react'
import Button from '../../../Components/Button'
import { IProduct } from '../types'

interface ProductQrCodeModalProps {
  isOpen: boolean
  handleCloseModal: () => void
  product: IProduct
  handleExportQr: () => void
}

export function ProductQrCodeModal({
  isOpen,
  handleCloseModal,
  product,
  handleExportQr,
}: ProductQrCodeModalProps) {
  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{product.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <QRCode value={`/products/scan/${product.id}`} level="H" size={256} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Cancelar</Button>
        <Button color="green" onClick={handleExportQr}>
          Exportar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import CreditCardIcon from '@mui/icons-material/CreditCard'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PixIcon from '@mui/icons-material/Pix'
import { TableCell, Tooltip } from '@mui/material'
import { paymentStatusMap } from '../../utils/transactionStatus'
import { convertCentsToLocale } from '../../../../utils/money.formater'
import { Edit } from '@material-ui/icons'

export default function TableColumns(transactions, setModalShow, setSelection) {
  const colorStatus = (status) => {
    // '#1976d2';
    if (status === 'waiting_payment') return '#ff9800'
    if (status === 'paid') return '#4caf50'
    if (status === 'pending_refund') return '#ff9800'
    if (status === 'refunded') return '#ef5350'
    if (status === 'failed') return '#c62828'
    if (status === 'with_error') return 'transparent'
    if (status === 'gave_up') return '#858181'
    if (status === 'chargedback') return 'transparent'
    if (status === 'refused') return '#ef5350'
    if (status === 'processing') return '#ff9800'
    if (status === 'canceled') return '#ef5350'
  }
  const columns = [
    {
      name: 'data',
      label: 'Data',
      options: {
        filter: false,
        sort: true,
        // sortDirection: 'desc',
        customBodyRender: (value: string) => {
          const date = new Date(value)
          const day = date.getDate().toString().padStart(2, '0')
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const year = date.getFullYear()
          const hours = date.getHours().toString().padStart(2, '0')
          const minutes = date.getMinutes().toString().padStart(2, '0')
          return `${day}/${month}/${year} ${hours}:${minutes}`
        },
      },
    },
    {
      name: 'nome_usuario',
      label: 'Usuário',
      options: {
        filter: false,
      },
    },
    {
      name: 'CPF',
      label: 'CPF',
      options: {
        filter: false,
        display: true,
      },
    },
    {
      name: 'telefone_usuario',
      label: 'Telefone',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'unidade_nome',
      label: 'Unidade',
    },
    {
      type: 'number',
      name: 'total',
      label: 'Total (R$)',
      options: {
        filter: false,
        customBodyRender: (value: {
          toFixed: (arg0: number) => {
            (): any
            new (): any
            toLocaleString: { (): any; new (): any }
          }
        }) => {
          const valueFormatted = value.toFixed(2).toLocaleString()
          return `R$${valueFormatted}`
        },
      },
    },
    {
      name: 'cost_of_products_sold',
      label: 'CMV',
      options: {
        customHeadRender: () => {
          return (
            <TableCell
              className="tss-10syd3x-MUIDataTableHeadCell-root tss-1bc37mg-MUIDataTableHeadCell-fixedHeader"
              data-colindex="1"
              data-tableid="02948779819077627"
            >
              <div style={{ display: 'flex' }}>
                CMV
                <Tooltip title="Custo da mercadoria vendida">
                  <InfoOutlinedIcon sx={{ paddingLeft: '5px' }} />
                </Tooltip>
              </div>
            </TableCell>
          )
        },
        customBodyRender: (value: number) => convertCentsToLocale(value),
      },
    },
    {
      name: 'status',
      label: 'Status',
      flex: 1,
      options: {
        customBodyRender: (value: string) => {
          return (
            <div
              style={{
                backgroundColor: colorStatus(value),
                color: '#fff',
                padding: '6px 10px',
                display: 'inline-block',
                borderRadius: '12px',
                boxShadow: 'rgb(0 0 0 / 58%) 0px 2px 3px 0px',
              }}
            >
              {paymentStatusMap.get(value)}
            </div>
          )
        },
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      },
    },
    {
      name: 'number',
      label: 'N.º Nota',
      options: {
        customBodyRender: (value: string) => {
          return value || ''
        },
        display: false,
      },
    },
    {
      name: 'tipo',
      label: 'Pagamento',
      options: {
        display: true,
        customBodyRender: (value: string) => {
          return (
            <div
              style={{
                textTransform: 'uppercase',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '2px',
              }}
            >
              {value === 'pix' ? (
                <PixIcon sx={{ fontSize: '20px' }} />
              ) : value === 'card' ? (
                <CreditCardIcon sx={{ fontSize: '20px' }} />
              ) : null}
              {value}
            </div>
          )
        },
        setCellProps: () => ({ style: { textAlign: 'center' } }),
      },
    },
    {
      name: 'id_pagarme',
      label: 'Pedido',
      options: {
        display: false,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value: number) => (
          <Tooltip title="Detalhes da transação">
            <Edit
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const selectedTransaction = transactions.find(
                  (transaction) => transaction.id === value,
                )
                setModalShow(true)
                setSelection(selectedTransaction)
              }}
            />
          </Tooltip>
        ),
      },
    },
  ]

  return columns
}

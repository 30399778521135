import { MUIDataTableOptions } from 'mui-datatables'
import { Spinner } from 'react-bootstrap'
import Table from '../../Components/Table'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import { TableColumns } from './components/TableColumns'
import { useSuppliers } from './hooks/useSuppliers'
import * as S from './styles'
import { IconButton } from '@mui/material'
import { theme } from '../../styles/theme'
import { Add } from '@material-ui/icons'
import { SupplierModal } from './components/SupplierModal'
import { DeleteSupplierModal } from './components/DeleteSupplierModal'

export function Suppliers() {
  const {
    suppliers = [],
    isLoading,
    isOpenSupplierModal,
    setIsOpenSupplierModal,
    selectedSupplier,
    setSelectedSupplier,
    isOpenDeleteSupplierModal,
    setIsOpenDeleteSupplierModal,
  } = useSuppliers({})

  const productTableOptions: MUIDataTableOptions = {
    customToolbar: () => {
      return (
        <IconButton
          style={{
            fontSize: '16px',
            borderRadius: '12px',
            backgroundColor: theme.colors.primaryColor,
            color: theme.colors.whiteColor,
          }}
          onClick={() => setIsOpenSupplierModal(true)}
        >
          <Add />
          Novo fornecedor
        </IconButton>
      )
    },
    filter: false,
    textLabels: {
      ...textLabels,
      body: {
        noMatch: isLoading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  const onEditSupplier = (supplierId: number) => {
    setSelectedSupplier(suppliers.find(({ id }) => id === supplierId) ?? null)
    setIsOpenSupplierModal(true)
  }

  const onDeleteSupplier = (supplierId: number) => {
    setSelectedSupplier(suppliers.find(({ id }) => id === supplierId) ?? null)
    setIsOpenDeleteSupplierModal(true)
  }

  const suppliersColumns = TableColumns({
    onDelete: onDeleteSupplier,
    onEdit: onEditSupplier,
  })

  return (
    <>
      <S.TableWrapper>
        <Table
          options={productTableOptions}
          columns={suppliersColumns}
          data={suppliers}
          showSearchComponent={false}
        />
      </S.TableWrapper>
      {isOpenSupplierModal && (
        <SupplierModal
          isOpen={isOpenSupplierModal}
          onClose={() => setIsOpenSupplierModal(false)}
          supplier={selectedSupplier}
        />
      )}
      {isOpenDeleteSupplierModal && (
        <DeleteSupplierModal
          isOpen={isOpenDeleteSupplierModal}
          onClose={() => setIsOpenDeleteSupplierModal(false)}
          supplier={selectedSupplier}
        />
      )}
    </>
  )
}

import { useCallback, useState } from 'react'
import { IInventoryItems } from '../types'
import { toast } from 'react-toastify'
import {
  getAllInventoryItems,
  updateInventoryItemInfo,
  finishInventoryItemInfo,
} from '../services'
import { useNavigate, useParams } from 'react-router-dom'

export function useInventoryItems() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [inventoryItems, setInventoryItems] = useState<IInventoryItems[]>([])
  const [isLoadingMovementItems, setIsLoadingMovementItems] = useState(false)
  const [isSavingInventoryItems, setIsSavingInventoryItems] = useState(false)

  const getAllInventoryMovementItems = useCallback(async () => {
    setIsLoadingMovementItems(true)
    try {
      const data = await getAllInventoryItems(Number(id))
      setInventoryItems(data)
      setIsLoadingMovementItems(false)
    } catch (error: any) {
      setIsLoadingMovementItems(false)
      return toast.error(error.message)
    }
  }, [id])

  async function onChangeInputAmount(id: number, value: string) {
    try {
      await updateInventoryItemInfo(id, {
        amount: value,
      })
      toast.success('Quantidade atualizada com sucesso')
    } catch (error: any) {
      toast.error(error.message || 'Erro ao atualizar a quantidade')
    }
  }

  async function onChangeInputObservation(id: number, observation: string) {
    try {
      await updateInventoryItemInfo(id, {
        observation,
      })
      toast.success('Quantidade atualizada com sucesso')
    } catch (error: any) {
      toast.error(error.message || 'Erro ao atualizar a quantidade')
    }
  }

  async function saveAndQuitFromInventoryItem() {
    setIsSavingInventoryItems(true)
    try {
      await finishInventoryItemInfo(id ?? '')
      toast.success('Inventário finalizado com sucesso!')
      setIsSavingInventoryItems(false)
      setTimeout(() => {
        navigate(-1)
      }, 1000)
    } catch (error: any) {
      setIsSavingInventoryItems(false)
      toast.error(error?.message)
    }
  }

  function updateProductField(id: number, payload: any) {
    const updatedProductsList = inventoryItems.map((product) => {
      if (product.id === id) {
        return {
          ...product,
          ...payload,
        }
      }

      return product
    })

    setInventoryItems(updatedProductsList)
  }

  return {
    inventoryItems,
    isLoadingMovementItems,
    getAllInventoryMovementItems,
    onChangeInputAmount,
    onChangeInputObservation,
    saveAndQuitFromInventoryItem,
    updateProductField,
    isSavingInventoryItems,
  }
}

import { ReactNode, createContext, useState } from 'react'
import { IUnit } from '../pages/Units/types'
import { ICategory } from '../pages/Categories/types'

interface GlobalInfosProviderProps {
  children: ReactNode
}

interface GlobalContextInfoProps {
  units: IUnit[]
  setUnits: (units: IUnit[]) => void
  categories: ICategory[]
  setCategories: (categories: ICategory[]) => void
}

export const GlobalInfosContext = createContext({} as GlobalContextInfoProps)

export const GlobalInfosProvider = ({ children }: GlobalInfosProviderProps) => {
  const [units, setUnits] = useState<IUnit[]>([])
  const [categories, setCategories] = useState<ICategory[]>([])

  return (
    <GlobalInfosContext.Provider
      value={{ units, setUnits, categories, setCategories }}
    >
      {children}
    </GlobalInfosContext.Provider>
  )
}

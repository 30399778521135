import { IProduct } from '../../Products/types'

export function formatProductsToAutocomplete(
  productName: string,
  products: IProduct[],
) {
  return !productName
    ? products.map((product) => ({
        value: product.id,
        label: product.name,
      }))
    : products
        .filter((product) =>
          product['name']?.toLowerCase()?.includes(productName?.toLowerCase()),
        )
        .map((product) => ({ value: product.id, label: product.name }))
}

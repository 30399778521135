import * as yup from 'yup'

export const productFormSchema = yup.object({
  name: yup.string().min(2).required('Digite um nome para o produto.'),
  description: yup.string().nullable(),
  cost: yup
    .number()
    .required('Digite o custo do produto')
    .moreThan(0, 'O custo precisa ser maior que R$ 0,00.'),
  global_amount: yup.number().required('Digite a quantidade'),
  category_id: yup.string().required('Selecione a categoria'),
  is_product_for_adult: yup.boolean().default(false),
  cst: yup.string().required('Informe o CST'),
  ncm: yup.string().required('Informe o NCM'),
  gtin: yup.string().required('Informe o GTIN'),
  cest: yup.string().required('Informe o CEST'),
  pis: yup.string().required('Informe o PIS'),
  cofins: yup.string().required('Informe o COFINS'),
  ipi: yup.string().required('Informe o IPI'),
  icms: yup.string().required('Informe o ICMS'),
  cfop: yup.string().required('Informe o CFOP'),
  csosn: yup.string().required('Informe o CSOSN'),
  units: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          disabled: yup.boolean().notRequired(),
          unit: yup.number().required('Selecione a unidade'),
          value: yup
            .number()
            .required('Digite o preço')
            .moreThan(99, 'O preço precisa ser maior ou igual a R$ 1,00.'),
          amount: yup.number().when('disabled', (option, field) => {
            const [disabled] = option
            return !disabled ? field.required('Digite a quantidade') : field
          }),
          registered_quantity: yup
            .number()
            .when('disabled', (option, field) => {
              const [disabled] = option
              return !disabled
                ? field
                    .required('Digite a quantidade controle')
                    .moreThan(0, 'Controle deve ser maior que zero.')
                : field
            }),
        })
        .required('Todos os campos são obrigatórios'),
    )
    .required('Pelo menos uma unidade deve ser selecionada')
    .min(1),
})

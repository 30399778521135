import moment from 'moment/moment'
import { Modal } from 'react-bootstrap'
import Button from '../../../../Components/Button'
import { formatCPF, formatPhone } from '../../../../utils/formats'
import { IProductUnits } from '../../../Products/types'
moment.locale('pt-br')

interface IOpenedCartDetailsModal {
  openedCart: {
    user_name: string
    user_last_name: string
    cellphone: string
    document: string
    opened_cart_date: string
    expiress_cart_date: string
    total_value: string
    items: any[]
  } | null
  isOpenCartDetails: boolean
  handleCloseModal: () => void
  handleDeleteOpenedCart: () => void
  isDeletingCart: boolean
}

export default function OpenedCartDetailsModal({
  openedCart,
  isOpenCartDetails,
  handleCloseModal,
  handleDeleteOpenedCart,
  isDeletingCart,
}: IOpenedCartDetailsModal) {
  return (
    <Modal
      show={isOpenCartDetails}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      closeButton
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Detalhes do carrinho
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>Nome:</b> {openedCart?.user_name}{' '}
          {openedCart?.user_last_name || ''}
        </p>
        <p>
          <b>CPF:</b> {formatCPF(openedCart?.document)}
        </p>
        <p>
          <b>Telefone:</b> {formatPhone(openedCart?.cellphone)}
        </p>
        <p>
          <b>Data de abertura:</b>{' '}
          {moment(openedCart?.opened_cart_date).format('DD/MM/YYYY - HH:mm')}
        </p>
        <p>
          <b>Data de expiração:</b>{' '}
          {moment(openedCart?.expiress_cart_date).format('DD/MM/YYYY - HH:mm')}
        </p>
        <p>
          <b>Valor total:</b> {openedCart?.total_value}
        </p>
        <p>
          <b>Produtos: </b>
          {openedCart?.items?.map(
            (item: { name: string; amount: number }, index) => (
              <span key={item.name}>
                {item.amount}x {item.name}
                {index + 1 < openedCart.items.length && ', '}
              </span>
            ),
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Fechar</Button>
        <Button
          color="error"
          onClick={handleDeleteOpenedCart}
          disabled={isDeletingCart}
        >
          {isDeletingCart ? 'Excluindo...' : 'Excluir'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { Button, ButtonProps } from '@material-ui/core'
import { Add } from '@material-ui/icons'

interface AddButtonProps extends ButtonProps {}

export default function AddButton({
  color = 'primary',
  ...rest
}: AddButtonProps) {
  return (
    <Button startIcon={<Add />} color={color} {...rest}>
      Adicionar
    </Button>
  )
}

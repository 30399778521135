import AssessmentIcon from '@mui/icons-material/Assessment'
import ReceiptIcon from '@mui/icons-material/Receipt'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import HomeIcon from '@mui/icons-material/Home'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import DescriptionIcon from '@mui/icons-material/Description'
import PersonIcon from '@mui/icons-material/Person'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import StoreIcon from '@mui/icons-material/Store'
import { Categories } from '../pages/Categories/Categories'
import Home from '../pages/Home'
import { Invoices } from '../pages/Invoices/Invoices'
import { OpenedCarts } from '../pages/OpenedCarts'
import ProductsPage from '../pages/Products'
import { Reports } from '../pages/Reports/Reports'
import { Transaction } from '../pages/Transactions'
import { Units } from '../pages/Units/Units'
import { Users } from '../pages/Users/Users'
import { RouteType } from './config'
import { Notifications, Person } from '@material-ui/icons'
import NewNotification from '../pages/NewNotification'
import { DoorSliding, Inventory, NoteAlt } from '@mui/icons-material'
import InventoryMovements from '../pages/InventoryMovements'
import { InventoryList } from '../pages/Inventory/InventoryList'
import { InventoryItems } from '../pages/Inventory/InventoryItem'
import { Suppliers } from '../pages/Suppliers'
import { AccessControl } from '../pages/AccessControl'
import { PackingList } from 'pages/Products/screens/PackingList'

const appRoutes: RouteType[] = [
  {
    sidebar: true,
    index: true,
    element: <Home />,
    state: 'home',
    sidebarProps: {
      displayText: 'Dashboard',
      icon: <HomeIcon />,
    },
    permission: [999, 2],
  },
  {
    sidebar: true,
    path: '/dashboard/acessos',
    element: <AccessControl />,
    state: 'acessos',
    sidebarProps: {
      displayText: 'Controle de Acessos',
      icon: <DoorSliding />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/categorias',
    element: <Categories />,
    state: 'categorias',
    sidebarProps: {
      displayText: 'Categorias',
      icon: <FormatListBulletedOutlinedIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/carrinhos-abertos',
    element: <OpenedCarts />,
    state: 'carrinhos-abertos',
    sidebarProps: {
      displayText: 'Carrinhos',
      icon: <ShoppingCartIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/suppliers',
    element: <Suppliers />,
    state: 'suppliers',
    sidebarProps: {
      displayText: 'Fornecedores',
      icon: <Person />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/inventario',
    element: <InventoryList />,
    state: 'inventory',
    sidebarProps: {
      displayText: 'Inventário',
      icon: <NoteAlt />,
    },
    permission: [999, 2],
  },
  {
    sidebar: false,
    path: '/dashboard/inventario/:id',
    state: 'inventory-items',
    element: <InventoryItems />,
    sidebarProps: {
      displayText: 'Itens do inventário',
    },
    permission: [999, 2],
  },
  {
    sidebar: true,
    path: '/dashboard/movimentacao-de-estoque',
    element: <InventoryMovements />,
    state: 'stock_movements',
    sidebarProps: {
      displayText: 'Movimentação de estoque',
      icon: <Inventory />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/notas-fiscais',
    element: <Invoices />,
    state: 'notas-fiscais',
    sidebarProps: {
      displayText: 'Notas Fiscais',
      icon: <ReceiptIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/notificacoes',
    element: <NewNotification />,
    state: 'notificacoes',
    sidebarProps: {
      displayText: 'Notificações',
      icon: <Notifications />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/produtos',
    element: <ProductsPage />,
    state: 'produtos',
    sidebarProps: {
      displayText: 'Produtos',
      icon: <ShoppingBasketIcon />,
    },
    permission: [999, 2],
  },
  {
    sidebar: false,
    path: '/dashboard/produtos/romaneio/:id',
    state: 'packing-list',
    element: <PackingList />,
    sidebarProps: {
      displayText: 'Lista de Romaneio',
    },
    permission: [999, 2],
  },
  {
    sidebar: true,
    path: '/dashboard/relatorios',
    element: <Reports />,
    state: 'relatorios',
    sidebarProps: {
      displayText: 'Relatórios',
      icon: <AssessmentIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/transacoes',
    element: <Transaction />,
    state: 'transacoes',
    sidebarProps: {
      displayText: 'Transações',
      icon: <DescriptionIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/unidades',
    element: <Units />,
    state: 'unidades',
    sidebarProps: {
      displayText: 'Unidades',
      icon: <StoreIcon />,
    },
    permission: [999],
  },
  {
    sidebar: true,
    path: '/dashboard/usuarios',
    element: <Users />,
    state: 'usuarios',
    sidebarProps: {
      displayText: 'Usuários',
      icon: <PersonIcon />,
    },
    permission: [999],
  },
  // {
  //   sidebar: true,
  //   title: 'Notificações',
  //   path: '/dashboard/notification',
  //   icon: <FontAwesomeIcon icon={faBell} />,
  //   component: NewNotification,
  // },
  // {
  //   sidebar: true,
  //   title: 'Controle de Acesso',
  //   path: '/dashboard/logs',
  //   icon: <FontAwesomeIcon icon={faCamera} />,
  //   component: Logs,
  // },
  // {
  //   sidebar: false,
  //   path: '/dashboard/dashboard',
  //   element: <DashboardPageLayout />,
  //   state: 'dashboard',
  //   sidebarProps: {
  //     displayText: 'Dashboard',
  //     icon: <DashboardOutlinedIcon />,
  //   },
  //   child: [
  //     {
  //       index: true,
  //       path: '/dashboard/dashboard/index',
  //       element: <DashboardIndex />,
  //       state: 'dashboard.index',
  //       sidebar: true,
  //       sidebarProps: {
  //         displayText: 'Index',
  //       },
  //     },
  //   ],
  // },
]

export default appRoutes

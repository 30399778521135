export const PAYMENT_OPTIONS = [
  {
    label: 'Cartão de crédito',
    value: 'card',
  },
  {
    label: 'PIX',
    value: 'pix',
  },
]

export const TRANSACTION_STATUS = [
  {
    label: 'Aguardando pagamento',
    value: 'waiting_payment',
  },
  {
    label: 'Processando',
    value: 'processing',
  },
  {
    label: 'Pago',
    value: 'paid',
  },
  {
    label: 'Recusada',
    value: 'refused',
  },
  {
    label: 'Aguardando estorno',
    value: 'pending_refund',
  },
  {
    label: 'Estornado',
    value: 'refunded',
  },
  {
    label: 'Chargedback',
    value: 'chargedback',
  },
  {
    label: 'Falha',
    value: 'failed',
  },
  {
    label: 'Com erro',
    value: 'with_error',
  },
  {
    label: 'Desistência',
    value: 'gave_up',
  },
  {
    label: 'Cancelado',
    value: 'canceled',
  },
]

import { Col, Row, Stack } from 'react-bootstrap'
import EmptyUnit from './Empty'
import { FieldError, useFieldArray, useFormContext } from 'react-hook-form'
import AddButton from '../AddButon'
import { Delete } from '@material-ui/icons'
import InputMoney from '../Forms/InputMoney'
import Select from '../Forms/Select'
import TextField from '../Forms/TextField'
import * as S from './styles'
import { IUnit } from '../../pages/Units/types'
import { IProduct, IProductUnits } from '../../pages/Products/types'
import { useProducts } from '../../pages/Products/hooks/useProducts'
import RemoveProductFromUnitModal from '../../pages/Products/components/RemoveProductFromUnit'
import { useState } from 'react'
import { useParams } from '../../hooks/useParams'
import { Separator } from '../Separator'
import { formatPercentage } from '../../utils/formats'
import { Percent } from '@mui/icons-material'

export const EMPTY_UNIT_FIELDS = {
  amount: null,
  control: null,
  value: null,
  unit: null,
}

interface SelectUnitsProps {
  units: IUnit[]
  product?: IProduct | null
}

export default function SelectUnits({ units, product }: SelectUnitsProps) {
  const { params } = useParams()
  const { deleteProductFromUnit } = useProducts()
  const [
    isOpenRemoveProductFromUnitModal,
    setIsOpenRemoveProductFromUnitModal,
  ] = useState(false)
  const [unitToRemoveProduct, setUnitToRemoveProduct] = useState<number | null>(
    null,
  )
  const { control, formState, watch, setValue } = useFormContext<any>()
  const { fields, append, remove } = useFieldArray({
    name: 'units',
    control,
  })
  const isFilterByUnit = params.get('unit')

  const watchFieldArray = watch('units')
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    }
  })
  const productCost = watch('cost')

  function handleAddNewUnitField() {
    append(EMPTY_UNIT_FIELDS)
  }

  if (fields.length === 0) {
    return <EmptyUnit onClick={handleAddNewUnitField} />
  }

  function removeUnit(field: IProductUnits, index: number) {
    if (typeof field.id === 'number') {
      setUnitToRemoveProduct(field.id)
      return setIsOpenRemoveProductFromUnitModal(true)
    }
    remove(index)
  }

  async function handleDeleteProductFromUnit() {
    const index = controlledFields.findIndex(
      (field) => field.id === unitToRemoveProduct,
    )

    try {
      deleteProductFromUnit(unitToRemoveProduct ?? 0)
      remove(index)
      setUnitToRemoveProduct(null)
      setIsOpenRemoveProductFromUnitModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  function handleChangePercentage(value: string, index: number) {
    const formatedMargin = Number(formatPercentage(value))
    const percentageValue = (formatedMargin / 100) * productCost
    const productPrice =
      Number(((productCost + percentageValue) / 100).toFixed(2)) * 100

    setValue(`units.${index}.margin`, formatedMargin)
    setValue(`units.${index}.value`, productPrice)
    handleCalcProfitPercentage(index)
  }

  function handleCalcSalesMargin(index: number) {
    const price = watch(`units.${index}.value`)
    const margin = (((price - productCost) / productCost) * 100).toFixed(2)
    setValue(`units.${index}.margin`, margin)
    handleCalcProfitPercentage(index)
  }

  function handleCalcProfitPercentage(index: number) {
    const price = watch(`units.${index}.value`)
    const profit = price - productCost

    const profitPercentage = ((profit / price) * 100).toFixed(2)
    setValue(`units.${index}.profit`, profitPercentage)
  }

  return (
    <>
      <Stack gap={3} direction="vertical">
        {controlledFields.map((field, index) => {
          const isLastItem = index + 1 === controlledFields.length
          return (
            <>
              <Row
                key={field.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Col md={isFilterByUnit ? 12 : 11}>
                  <Stack gap={2} direction="vertical">
                    <Row>
                      <Col md={6}>
                        <Select
                          control={control}
                          label="Unidade"
                          name={`units.${index}.unit`}
                          options={units.map((unit) => ({
                            label: unit.nome,
                            value: unit.id,
                          }))}
                          error={
                            (formState?.errors?.units?.[index]
                              ?.unit as FieldError) ?? ''
                          }
                          disabled={field?.disabled || false}
                        />
                      </Col>

                      <Col md={3}>
                        <TextField
                          control={control}
                          name={`units.${index}.amount`}
                          type="number"
                          label="Qtd"
                          error={
                            (formState?.errors?.units?.[index]
                              ?.amount as FieldError) ?? ''
                          }
                          // disabled={field?.disabled || false}
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          control={control}
                          name={`units.${index}.registered_quantity`}
                          type="number"
                          label="Controle"
                          error={
                            (formState?.errors?.units?.[index]
                              ?.registered_quantity as FieldError) ?? ''
                          }
                          // disabled={field?.disabled || false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputMoney
                          control={control}
                          name={`units.${index}.value`}
                          label="Valor"
                          placeholder="00,00"
                          error={
                            (formState?.errors?.units?.[index]
                              ?.value as FieldError) ?? ''
                          }
                          onChangeCallback={() => handleCalcSalesMargin(index)}
                        />
                      </Col>
                      <Col>
                        <TextField
                          control={control}
                          name={`units.${index}.margin`}
                          type="number"
                          label="Margem"
                          callbackOnChange={(value: string) =>
                            handleChangePercentage(value, index)
                          }
                          endIcon={
                            <Percent
                              style={{ width: '16px', height: '16px' }}
                            />
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          control={control}
                          name={`units.${index}.profit`}
                          label="Lucro %"
                          disabled
                          endIcon={
                            <Percent
                              style={{ width: '16px', height: '16px' }}
                            />
                          }
                        />
                      </Col>
                    </Row>
                  </Stack>
                </Col>
                {!isFilterByUnit && (
                  <Col md={1}>
                    <S.StyledCol md={1}>
                      <Delete onClick={() => removeUnit(field, index)} />
                    </S.StyledCol>
                  </Col>
                )}
              </Row>
              {!isLastItem && <Separator />}
            </>
          )
        })}
        {!isFilterByUnit && <AddButton onClick={handleAddNewUnitField} />}
      </Stack>
      {isOpenRemoveProductFromUnitModal && (
        <RemoveProductFromUnitModal
          isOpenRemoveProductFromUnitModal={isOpenRemoveProductFromUnitModal}
          setIsOpenRemoveProductFromUnitModal={
            setIsOpenRemoveProductFromUnitModal
          }
          handleDelete={handleDeleteProductFromUnit}
          product={product}
          unitToRemove={
            controlledFields.find((unit) => unit.id === unitToRemoveProduct)
              ?.unit_name
          }
        />
      )}
    </>
  )
}

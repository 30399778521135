import { theme } from '../../styles/theme'
import * as C from './styles'

interface ITitle {
  children: string | undefined
  variant?: any
  underline?: boolean
}

function Title({ children, variant, underline = false }: ITitle) {
  return (
    <C.Title
      sx={{
        borderBottom: underline
          ? `3px solid ${theme.colors.primaryColor}`
          : 'none',
      }}
      variant={variant}
    >
      {children}
    </C.Title>
  )
}

export default Title

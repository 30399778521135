import {
  BrowserRouter,
  Navigate,
  Outlet,
  Routes,
  Route,
} from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import PageWrapper from '../Components/layout/PageWrapper'
import { ReactNode } from 'react'
import MainLayout from '../Components/layout/MainLayout'
import appRoutes from './appRoutes'
import { RouteType } from './config'
import Login from '../pages/Login'

const Private = () => {
  const { signed }: any = useAuth()

  return signed ? <Outlet /> : <Navigate to="/login" />
}

const generateRoute = (routes: RouteType[]): ReactNode => {
  return routes.map((route, index) =>
    route.index ? (
      <Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>{route.element}</PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
        key={index}
      >
        {route.child && generateRoute(route.child)}
      </Route>
    ),
  )
}

export const routes: ReactNode = generateRoute(appRoutes)

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/dashboard" element={<Private />}>
          <Route path="/dashboard" element={<MainLayout />}>
            {routes}
          </Route>
        </Route>

        <Route path="/" element={<Private />}>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Route>

        {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesApp

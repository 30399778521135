import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: black transparent;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #f0f2f5;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
  
  ul{
    list-style-type: circle!important;
  }

  /* h1{
    font-family: 'Pompiere', cursive;
  } */

  a{
    text-decoration: none;
  }


.table td,
.table th {
  vertical-align: middle;
}


.css-181rqw8-MuiPaper-root fieldset {
  padding: 0 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8.5px 14px;
}

td.MuiTableCell-root.MuiTableCell-footer.MuiTableCell-sizeMedium.tss-1ork7hi-MUIDataTablePagination-tableCellContainer.css-hz6bww-MuiTableCell-root{
  border: none;
}

.modal-content {
  max-height: 92vh;
  min-width: 35vw;
  scrollbar-width: thin;
  scrollbar-color: black transparent;
}

.modal-body {
  overflow-y: auto;
  max-height: 600px;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

*::-webkit-scrollbar-thumb {
  background-color: #858585;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}

`

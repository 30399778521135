import moment from 'moment'
import { convertCentsToLocale } from '../../../../utils/money.formater'
import {
  MovementOriginEnum,
  MovementStockEnum,
  MovementTypeEnum,
} from '../../types'
import { format } from 'date-fns'

export default function InventoryMovementsTableColumns() {
  return [
    {
      name: 'created_at',
      label: 'Data',
      options: {
        customBodyRender: (value: string) =>
          moment(value).format('DD/MM/yyyy HH:mm:ss'),
        viewColumns: false,
      },
    },
    {
      name: 'stock',
      label: 'Estoque',
      options: {
        customBodyRender: (value: string) => MovementStockEnum[value],
      },
    },
    {
      name: 'type',
      label: 'Tipo',
      options: {
        customBodyRender: (value: string) => MovementTypeEnum[value],
        viewColumns: false,
      },
    },
    {
      name: 'origin',
      label: 'Origem',
      options: {
        customBodyRender: (value: string) => MovementOriginEnum[value],
        viewColumns: false,
      },
    },
    {
      name: 'product_name',
      label: 'Produto',
    },
    {
      name: 'quantity',
      label: 'Qtde',
    },
    {
      name: 'cost',
      label: 'Custo',
      options: {
        customBodyRender: (value: number) => convertCentsToLocale(value || 0),
      },
    },
    {
      name: 'unit_price',
      label: 'Preço unitário',
      options: {
        customBodyRender: (value: number) => convertCentsToLocale(value || 0),
      },
    },
    {
      name: 'unit_name',
      label: 'Unidade',
    },
    {
      name: 'supplier_name',
      label: 'Fornecedor',
      options: {
        display: false,
      },
    },
    {
      name: 'invoice_number',
      label: 'Nº Nota',
      options: {
        display: false,
      },
    },
    {
      name: 'product_expiration_date',
      label: 'Data de validade',
      options: {
        customBodyRender: (value: string) =>
          value ? format(new Date(value), 'dd/MM/yyyy') : '',
        display: false,
      },
    },
    {
      name: 'transaction_id',
      label: 'Transação',
      options: {
        display: false,
      },
    },
  ]
}

import { Modal } from 'react-bootstrap'
import Button from '../../../../Components/Button'
import { useInventoryMovements } from '../../hooks/useInventoryMovements'
import { InventoryMovementDTO } from '../../types'
import { TabContent } from '../../../../Components/Tabs/TabContent'
import Tabs from '../../../../Components/Tabs'
import { DragAndDropFile } from '../../../../Components/DragAndDropFile'
import { CreateInventoryMovementsTab, ImportFileStepEnum } from './constants'
import { InventoryMovementForm } from './components/Form'
import { FormProvider } from 'react-hook-form'
import { ReviewProductsFromXmlImport } from './components/ReviewProducts'
import { FormEvent } from 'react'
import { CreateInventoryFromXmlProductsProps } from '../../services'

interface InventoryMovementsModal {
  isOpen: boolean
  handleCloseModal: () => void
  handleCreateNewInventory: (payload: InventoryMovementDTO) => Promise<void>
  onImportXmlFile: (
    payload: CreateInventoryFromXmlProductsProps,
  ) => Promise<void>
}

export default function InventoryMovementModal({
  isOpen,
  handleCloseModal,
  handleCreateNewInventory,
  onImportXmlFile,
}: InventoryMovementsModal) {
  const {
    inventoryMovementFormMethods,
    selectedTab,
    setSelectedTab,
    fileToImport,
    setFileToImport,
    importFileCurrentStep,
    getProductListFromXml,
    uploadProductsFromXmlFormMethods,
    convertedProductList,
    productsOptions,
    isLoadingProductsByName,
  } = useInventoryMovements()

  async function onSubmit(payload: InventoryMovementDTO) {
    await handleCreateNewInventory(payload)
    inventoryMovementFormMethods.setValue('quantity', 0)
  }

  const isManualInventory = selectedTab === CreateInventoryMovementsTab.manual
  const isAutomaticInventory =
    selectedTab === CreateInventoryMovementsTab.automatic

  async function onCreateAutomaticInventory(event: FormEvent) {
    if (importFileCurrentStep === ImportFileStepEnum.upload) {
      await getProductListFromXml()
    } else {
      await uploadProductsFromXmlFormMethods.handleSubmit((values) =>
        onImportXmlFile({
          products: values.products,
          supplier: values.supplier,
          xml: convertedProductList?.xml,
        }),
      )(event)
    }
  }

  const isLoading =
    uploadProductsFromXmlFormMethods.formState.isSubmitting ||
    inventoryMovementFormMethods.formState.isSubmitting

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Nova movimentação</Modal.Title>
      </Modal.Header>
      <Tabs
        handleChange={setSelectedTab}
        tabs={['Manual', 'Automático']}
        value={selectedTab}
      />
      <Modal.Body>
        <TabContent index={selectedTab} value={0}>
          <FormProvider {...inventoryMovementFormMethods}>
            <InventoryMovementForm
              products={productsOptions}
              isLoadingProducts={isLoadingProductsByName}
            />
          </FormProvider>
        </TabContent>
        <TabContent index={selectedTab} value={1}>
          {importFileCurrentStep === ImportFileStepEnum.upload ? (
            <DragAndDropFile setFile={setFileToImport} file={fileToImport} />
          ) : (
            <FormProvider {...uploadProductsFromXmlFormMethods}>
              <ReviewProductsFromXmlImport />
            </FormProvider>
          )}
        </TabContent>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Cancelar</Button>
        <Button
          type="submit"
          color="green"
          onClick={(event) =>
            isManualInventory
              ? inventoryMovementFormMethods.handleSubmit(onSubmit)(event)
              : onCreateAutomaticInventory(event)
          }
          disabled={isLoading || (isAutomaticInventory && !fileToImport)}
          loading={isLoading}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

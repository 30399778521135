import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  createNewProduct,
  getPackingList,
  exportProductList,
  getAllProducts,
  getAllProductsByName,
  getQrCodeProductsList,
  removeProductFromUnit,
  updateProduct,
} from '../services'
import { IProduct, PaginatedProductListResponse } from '../types'
import saveAs from 'file-saver'
import { handleDownloadPdf } from '../utils/downloadPdf'
import { generateProductFormData } from '../utils/format'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'hooks/useParams'

export function useProducts(unit_id?: string) {
  const navigate = useNavigate()
  const { params } = useParams()
  const [products, setProducts] = useState<IProduct[]>([])
  const [productsData, setProductsData] =
    useState<PaginatedProductListResponse>({} as PaginatedProductListResponse)
  const [searchProductText, setSearchProductText] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null)
  const [isLoadingProductList, setIsLoadingProductList] = useState(false)
  const [isLoadingProductsByName, setIsLoadingProductsByName] = useState(false)
  const [isOpenProductModal, setIsOpenProductModal] = useState(false)
  const [isOpenExportPackingListModal, setIsOpenExportPackingListModal] =
    useState(false)
  const [isOpenQrCodeProductModal, setIsOpenQrCodeProductModal] =
    useState(false)
  const [isExportingProducts, setIsExportingProducts] = useState(false)
  const [isExportingPackingList, setIsExportingPackingList] = useState(false)
  const [productsToGenerateQRCode, setproductsToGenerateQRCode] = useState([])
  const [isDownloadingQRCodes, setIsDownloadingQRCodes] = useState(false)
  const [isOpenConfirmDelete, setisOpenConfirmDelete] = useState(false)

  const getProducts = useCallback(async () => {
    const { search } = window.location
    setIsLoadingProductList(true)
    try {
      const response = await getAllProducts(search)
      setProductsData(response)
      setIsLoadingProductList(false)
    } catch (error: any) {
      setIsLoadingProductList(false)
      return toast.error(error.message)
    }
    setIsLoadingProductList(false)
  }, [])

  const getProdyctByName = useCallback(
    async (name: string) => {
      setIsLoadingProductsByName(true)
      try {
        const response = await getAllProductsByName(name, unit_id)
        setProducts(response)
        setIsLoadingProductsByName(false)
      } catch (error: any) {
        setIsLoadingProductsByName(false)
        return toast.error(error.message)
      }
    },
    [unit_id],
  )

  async function handleSubmitForm(data: IProduct) {
    if (selectedProduct) {
      await editProduct(data)
    } else {
      await createProduct(data)
    }

    setIsOpenProductModal(false)
    await getProducts()
  }

  async function createProduct(product: IProduct) {
    const formData = generateProductFormData(product)

    try {
      await createNewProduct(formData)
      toast.success('Produto criado com sucesso!')
      setSelectedProduct(null)
    } catch (error: any) {
      console.log(error)
      return toast.error(error.message)
    }
  }

  async function editProduct(product: IProduct) {
    const formData = generateProductFormData(product)
    try {
      await updateProduct(product.id, formData)
      return toast.success('Produto editado com sucesso!')
    } catch (error: any) {
      console.log(error)
      return toast.error(error.message)
    }
  }

  async function deleteProductFromUnit(unitToRemoveProduct: number) {
    try {
      await removeProductFromUnit(unitToRemoveProduct || 0)
      return toast.success('Produto removido da unidade com sucesso!')
    } catch (error: any) {
      console.log(error)
      return toast.error(error.message)
    }
  }

  async function handleExportProductList() {
    const unit_id = params.get('unit') ?? null
    setIsExportingProducts(true)
    try {
      const response = await exportProductList(unit_id)

      saveAs(
        `data:application/vnd.ms-excel;base64,${response}`,
        'Lista de produtos.csv',
      )
      setIsExportingProducts(false)
    } catch (error: any) {
      setIsExportingProducts(false)
      return toast.error(error.message)
    }
  }

  async function handleExportPackingList(unit_id: string) {
    setIsExportingPackingList(true)
    try {
      const response = await getPackingList(unit_id)
      setIsExportingPackingList(false)

      if (response.message) {
        return toast.error(response.message)
      }

      navigate(`romaneio/${response.id}`)
    } catch (error: any) {
      setIsExportingPackingList(false)
      return toast.error(error.message)
    }
  }

  const handleOpenEditProductModal = (id: number) => {
    const productToEdit = productsData?.data?.find(
      (product) => product.id === id,
    )
    setSelectedProduct(productToEdit ?? null)
    setIsOpenProductModal(true)
  }

  const handleOpenModalDeleteProduct = (id: number) => {
    const productToDelete = productsData?.data?.find(
      (product) => product.id === id,
    )
    setSelectedProduct(productToDelete || null)
    setisOpenConfirmDelete(true)
  }

  const handleQR = (id: number) => {
    const productToDelete = productsData?.data?.find(
      (product) => product.id === id,
    )
    setSelectedProduct(productToDelete || null)
    setIsOpenQrCodeProductModal(true)
  }

  const handleCreate = () => {
    setSelectedProduct(null)
    setIsOpenProductModal(true)
  }

  async function generateAllQrCodes() {
    setIsDownloadingQRCodes(true)

    try {
      const qrCodeList = await getQrCodeProductsList()
      saveAs(`data:application/pdf;base64,${qrCodeList}`, `Etiquetas.pdf`)
      setIsDownloadingQRCodes(false)
      toast.success('Lista de etiquetas gerada com sucesso!')
    } catch (error: any) {
      console.log(error)
      setIsDownloadingQRCodes(false)
      toast.error(error.message || 'Houve um erro ao gerar lista de QR Codes')
    }
  }

  async function handleDownloadSingleQRCode() {
    const newArray = Array.from({ length: 1 }).map(() => [
      { ...selectedProduct },
    ])

    setproductsToGenerateQRCode(newArray as any)
    await handleDownloadPdf()
    setproductsToGenerateQRCode([])
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchProductText) {
        getProdyctByName(searchProductText)
      }
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [searchProductText, getProdyctByName])

  return {
    products,
    productsData,
    setProducts,
    searchProductText,
    setSearchProductText,
    isLoadingProductsByName,
    selectedProduct,
    setSelectedProduct,
    isOpenProductModal,
    setIsOpenProductModal,
    getProducts,
    deleteProductFromUnit,
    getProdyctByName,
    handleExportProductList,
    isExportingProducts,
    handleExportPackingList,
    isExportingPackingList,
    isOpenExportPackingListModal,
    setIsOpenExportPackingListModal,
    isOpenQrCodeProductModal,
    setIsOpenQrCodeProductModal,
    isOpenConfirmDelete,
    setisOpenConfirmDelete,
    productsToGenerateQRCode,
    generateAllQrCodes,
    handleDownloadSingleQRCode,
    handleSubmitForm,
    handleOpenEditProductModal,
    handleOpenModalDeleteProduct,
    handleQR,
    handleCreate,
    isLoadingProductList,
    isDownloadingQRCodes,
  }
}

import { Box, Card, TextField } from '@mui/material/'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../../assets/images/logo.png'
// import Button from '../../components/Button';
import LoginIcon from '@mui/icons-material/Login'
import fundo from '../../assets/images/fundo2.png'
import Button from '../../Components/Button'
import Title from '../../Components/Title'
import useAuth from '../../hooks/useAuth'
import axios from '../../services/api'
import './styles.css'

// import Input from '../../components/Input';
// import * as C from './styles';
// import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
// import * as C from "./styles";
// import { useHistory } from 'react-router-dom';
// const CssTextField = styled(TextField)({
//   '& label.Mui-focused': {
//     color: 'green',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: 'green',
//     backgroundColor: 'yellow',
//   },
//   '& .MuiOutlinedInput-root': {
//     '& root': {
//       borderColor: 'red',
//       backgroundColor: 'transparent!important',
//       // backgroundColor: 'pink',
//     },
//     '& fieldset': {
//       borderColor: 'red',
//       backgroundColor: 'transparent!important',
//       // backgroundColor: 'pink',
//     },
//     // '&:hover fieldset': {
//     //   borderColor: 'yellow',
//     // },
//     '&.Mui-focused fieldset': {
//       borderColor: 'green',
//     },
//   },
// });

function Login() {
  const { signin }: any = useAuth()
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [senha, setSenha] = useState('')
  const [error, setError] = useState('')

  const [credential, setCredential] = useState({
    // email e password
    email: false,
    senha: false,
  })

  const handleInputChange = (event: { target: { name: any; value?: any } }) => {
    const { name } = event.target
    const { value } = event.target
    setCredential((oldCredential) => {
      const newCredential = oldCredential
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      newCredential[name] = value
      return newCredential
    })
  }

  const authUser = async () => {
    try {
      const { data } = await axios.post('account/auth', { ...credential })
      if (data.success === true) {
        signin(data.token, {
          username: data.user.nome,
          permission: data?.user?.nivel_acesso,
        })
        axios.defaults.headers.Authorization = `${data.token}`
        navigate('/dashboard')
        toast.success('Bem-vindo!')
      } else toast.error('Credenciais inválidas')
    } catch (error) {
      const { response }: any = error
      if (response && response.status === 500) {
        toast.error('Credenciais inválidas')
      } else {
        toast.error(
          'Houve um erro interno no servidor, tente novamente mais tarde.',
        )
      }
    }
  }

  const handleLogin = (e: Event) => {
    e.preventDefault()
    if (!email || !senha) {
      setError('Preencha todos os campos')
      return
    }
    authUser()
  }

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `url(${fundo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="fundo"
    >
      <Card
        sx={{
          py: 3,
          width: 600,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '24px',
          boxShadow:
            '6px 6px 6px 3px rgb(0 0 0 / 46%), -6px 8px 9px 5px rgb(251 251 251 / 29%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <img
          src={logo}
          alt="ConVen+"
          style={{ maxWidth: 250, marginBottom: 15 }}
        />
        <Title>Dashboard</Title>
        <TextField
          sx={{ width: '80%', mb: '1rem' }}
          label="Digite seu E-mail"
          variant="outlined"
          name="email"
          type="email"
          value={email}
          onChange={(e) => (
            setEmail(e.target.value), setError(''), handleInputChange(e)
          )}
        />
        <TextField
          sx={{ width: '80%', mb: '1.5rem' }}
          label="Digite sua Senha"
          variant="outlined"
          name="senha"
          type="password"
          inputProps={{
            autoComplete: 'new-password',
          }}
          value={senha}
          onChange={(e) => (
            setSenha(e.target.value), setError(''), handleInputChange(e)
          )}
        />
        <Button type="submit" width={'fullWidth'} onClick={handleLogin}>
          <LoginIcon /> Entrar
        </Button>
      </Card>
    </Box>
  )
}

export default Login

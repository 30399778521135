import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'

interface ITabs {
  tabs: string[]
  value: number
  handleChange: (state: number) => void
  variant?: 'fullWidth' | 'scrollable' | 'standard'
}

export default function Tabs({
  tabs,
  value,
  handleChange,
  variant = 'fullWidth',
}: ITabs) {
  return (
    <MaterialTabs
      value={value}
      onChange={(_, newValue) => handleChange(newValue)}
      variant={variant}
      textColor="primary"
      indicatorColor="primary"
    >
      {tabs.map((tab, index) => (
        <MaterialTab
          key={tab}
          label={tab}
          value={index}
          style={{ borderBottom: '1px solid #dee2e6' }}
        />
      ))}
    </MaterialTabs>
  )
}

import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'

interface ITableColumns {
  handleEdit: { (id: any): void }
  handleDel: { (id: any): void }
}
export default function TableColumns(
  handleEdit: { (id: any): void },
  handleDel: { (id: any): void },
) {
  const styles = {
    tableText: {
      fontSize: 14,
    },
    btnTable: {
      border: 'none',
      background: 'none',
      paddingTop: '5px',
    },
  }

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
      },
    },
    {
      name: 'nome',
      label: 'Nome',
      options: {
        // setCellProps: () => ({ style: { minWidth: '150px' } }),
      },
    },
    {
      name: 'descricao',
      label: 'Descrição',
      options: {
        setCellProps: () => ({ style: { width: '65%' } }),
      },
    },
    {
      name: 'botoes',
      label: 'Ações',
      options: {
        filter: false,
        setCellProps: () => ({ style: { width: '110px' } }),
        customBodyRender: (value: any, tableMeta: { rowData: any[] }) => {
          const id = tableMeta.rowData[0]
          return (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="button-link-edit"
                onClick={() => handleEdit(id)}
                style={styles.btnTable}
              >
                <DriveFileRenameOutlineIcon />
              </button>
              <button
                type="button"
                className="button-link-delete"
                onClick={() => handleDel(id)}
                style={styles.btnTable}
              >
                <DeleteIcon sx={{ color: '#e30000' }} />
              </button>
            </div>
          )
        },
      },
    },
  ]

  return columns
}

import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import MiniDrawer from '../common/Sidebar'
import Topbar from '../common/Topbar'

const MainLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component="nav"
        sx={{
          flexShrink: 0,
        }}
      >
        <MiniDrawer />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: '0 .7rem',
          width: `calc(100% - 240px)`,
          minHeight: '100vh',
          backgroundColor: '#eaeaea',
        }}
      >
        <Topbar />
        <Box
          sx={{
            backgroundColor: '#fff',
            height: 'calc(100vh - 100px)',
            padding: '0.5rem 0.7rem',
            marginTop: '8px',
            borderRadius: '5px',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default MainLayout

import { Form } from 'react-bootstrap'
import basket from '../../../../../assets/images/basketIcon.png'
import { IInventoryItems } from '../../types'

interface TableColumnsProps {
  onChangeInputAmount: (index: number, value: string) => void
  onChangeInputObservation: (index: number, value: string) => void
  filteredProducts: IInventoryItems[]
  updateProductField: (id: number, payload: any) => void
}

export function TableColumns({
  onChangeInputAmount,
  onChangeInputObservation,
  filteredProducts,
  updateProductField,
}: TableColumnsProps) {
  return [
    {
      name: 'img_url',
      label: 'Imagem',
      options: {
        customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
          const rowName = tableMeta.rowData[2]
          return <img src={value || basket} alt={rowName} height="50px" />
        },
      },
    },
    {
      name: 'product_name',
      label: 'Produto',
    },
    {
      name: 'category_name',
      label: 'Categoria',
    },
    {
      name: 'control',
      label: 'Controle',
    },
    {
      name: 'amount',
      label: 'Quantidade Disponível',
      options: {
        customBodyRender: (_, tableMeta: { rowIndex: number }) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return (
            <Form.Control
              type="number"
              size="sm"
              placeholder="Quantidade"
              onBlur={(e) =>
                onChangeInputAmount(currentProduct.id, e?.target.value)
              }
              value={currentProduct?.amount}
              onChange={(event) =>
                updateProductField(currentProduct.id, {
                  amount: event.target.value,
                })
              }
            />
          )
        },
      },
    },
    {
      name: 'observation',
      label: 'Obersvação',
      options: {
        customBodyRender: (_, tableMeta: { rowIndex: number }) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return (
            <Form.Control
              type="text"
              size="sm"
              placeholder="Observação"
              onBlur={(e) =>
                onChangeInputObservation(currentProduct.id, e?.target.value)
              }
              value={currentProduct?.observation}
              onChange={(event) =>
                updateProductField(currentProduct.id, {
                  observation: event.target.value,
                })
              }
            />
          )
        },
      },
    },
  ]
}

import { Col, Modal, Row, Stack } from 'react-bootstrap'
import { ISupplier } from '../types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { supplierFormSchema } from '../validators'
import TextField from '../../../Components/Forms/TextField'
import InputMask from '../../../Components/Forms/InputMask'
import { removeSpecialCharactersFromString } from '../../../utils/formats'
import Button from '../../../Components/Button'
import { useMutation } from '@tanstack/react-query'
import { IMutateSupplier, createNewSupplier, editSupplier } from '../services'
import { queryClient } from 'lib/queryClient'

interface SupplierModalProps {
  isOpen: boolean
  onClose: () => void
  supplier?: ISupplier | null
}

export function SupplierModal({
  isOpen,
  onClose,
  supplier,
}: SupplierModalProps) {
  const { control, formState, watch, handleSubmit } = useForm<ISupplier>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: supplier ?? {},
    resolver: yupResolver(supplierFormSchema) as any,
  })

  const mutateSupplier = useMutation((data: IMutateSupplier) => {
    return supplier
      ? editSupplier(supplier.id ?? 0, data)
      : createNewSupplier(data)
  })

  const supplierPhone = watch('cellphone')
  const sellerPhone = watch('seller_cellphone')

  const phoneMask = (phone = '') => {
    const str = removeSpecialCharactersFromString(phone)
    return str[2] === '9' ? '(99) 99999-9999' : '(99) 9999-9999'
  }

  async function onSubmit(data: IMutateSupplier) {
    mutateSupplier.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries(['/backoffice/suppliers'])
        onClose()
      },
    })
  }

  const isLoading = mutateSupplier.isLoading || formState.isSubmitting

  const isEditSupplier = !!supplier
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>
          {isEditSupplier ? 'Editar' : 'Cadastrar'} Fornecedor
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Stack gap={3} direction="vertical">
            <Row>
              <Col>
                <TextField
                  control={control}
                  name="name"
                  label="Nome do fornecedor"
                  placeholder="Digite o nome do fornecedor"
                  error={formState.errors['name']}
                />
              </Col>
              <Col>
                <InputMask
                  control={control}
                  name="document"
                  label="CNPJ"
                  placeholder="Digite o CNPJ"
                  error={formState.errors['document']}
                  mask="99.999.999/9999-99"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  control={control}
                  name="email"
                  label="Email"
                  placeholder="Digite o email do fornecedor"
                  error={formState.errors['email']}
                />
              </Col>
              <Col>
                <InputMask
                  control={control}
                  mask={phoneMask(supplierPhone)}
                  label="Telefone do fornecedor"
                  name="cellphone"
                  error={formState.errors['cellphone']}
                  placeholder="Digite o telefone do fornecedor"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextField
                  control={control}
                  name="seller_name"
                  label="Nome do vendedor"
                  placeholder="Digite o nome do vendedor"
                  error={formState.errors['seller_name']}
                />
              </Col>
              <Col>
                <InputMask
                  control={control}
                  mask={phoneMask(sellerPhone)}
                  label="Telefone do vendedor"
                  name="seller_cellphone"
                  error={formState.errors['seller_cellphone']}
                  placeholder="Digite o telefone do vendedor"
                />
              </Col>
            </Row>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={isLoading}>
            Cancelar
          </Button>
          <Button
            type="submit"
            color="green"
            disabled={isLoading}
            loading={isLoading}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

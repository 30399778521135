import { IProduct } from '../types'

export function generateProductFormData(data: IProduct) {
  const formData = new FormData()
  formData.append('name', data.name)
  formData.append('description', data.description || '')
  formData.append('cost', String(data.cost))
  formData.append('category_id', data.category_id)
  formData.append('global_amount', String(data.global_amount))
  formData.append('category_name', data.category_name || '')
  formData.append('is_product_for_adult', data.is_product_for_adult ? '1' : '0')
  formData.append('image_url', data.image_url || '')
  formData.append('image_ref', data.image_ref || '')
  formData.append('image', data.image ?? ('' as string))
  formData.append('units', JSON.stringify(data.units))
  formData.append('csosn', data.csosn)
  formData.append('cfop', data.cfop)
  formData.append('icms', data.icms)
  formData.append('ipi', data.ipi)
  formData.append('cofins', data.cofins)
  formData.append('pis', data.pis)
  formData.append('cest', data.cest)
  formData.append('gtin', data.gtin)
  formData.append('ncm', data.ncm)
  formData.append('cst', data.cst)
  return formData
}

import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import saveAs from 'file-saver'
import {
  IPackingListResponse,
  finishiPacking,
  getPackingListItems,
  updatePackingListItems,
} from '../services'

export function usePackingList() {
  const { id = '' } = useParams()
  const navigate = useNavigate()

  const [isLoadingPackingListItems, setIsLoadingPackingListItems] =
    useState(false)
  const [packingListItems, setPackingListItems] =
    useState<IPackingListResponse>({} as IPackingListResponse)
  const [isSavingPackingList, setIsSavingPackingList] = useState(false)

  const getPackingList = useCallback(async () => {
    setIsLoadingPackingListItems(true)
    try {
      const data = await getPackingListItems(id)
      setPackingListItems(data)
      setIsLoadingPackingListItems(false)
    } catch (error: any) {
      setIsLoadingPackingListItems(false)
      return toast.error(error.message)
    }
  }, [id])

  async function handleDeleteProduct(id: number) {
    const remainingItems = packingListItems?.items?.filter(
      (item) => item.product_id !== id,
    )

    try {
      await updatePackingListItems(packingListItems.id ?? 0, remainingItems)
      setPackingListItems({ ...packingListItems, items: remainingItems })
      toast.success('Produto excluído com sucesso')
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function onChangeControl(id: number, value: string) {
    const index = packingListItems.items.findIndex(
      (product) => product.product_id === id,
    )
    const newPackingListItems = { ...packingListItems }
    try {
      newPackingListItems.items[index].registered_quantity = value as any
      setPackingListItems(newPackingListItems)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function onChangeAvailableAmount(id: number, value: string) {
    const index = packingListItems.items.findIndex(
      (product) => product.product_id === id,
    )
    const newPackingListItems = { ...packingListItems }
    try {
      newPackingListItems.items[index].available_quantity = value as any
      setPackingListItems(newPackingListItems)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function onChangeRepositionAmount(id: number, value: string) {
    const index = packingListItems.items.findIndex(
      (product) => product.product_id === id,
    )
    const newPackingListItems = { ...packingListItems }
    try {
      newPackingListItems.items[index].quantityForReposition = value as any
      setPackingListItems(newPackingListItems)
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function onChangeStatus(id: number, value: boolean) {
    const index = packingListItems.items.findIndex(
      (product) => product.product_id === id,
    )

    const newPackingListItems = { ...packingListItems }
    try {
      newPackingListItems.items[index].checked = value
      setPackingListItems(newPackingListItems)
      toast.success('Informação alterada com sucesso')
      updatePackingListItems(
        packingListItems.id ?? 0,
        newPackingListItems.items,
      )
    } catch (error: any) {
      toast.error(error.message)
    }
  }

  async function onUpdatePackingListItem() {
    await updatePackingListItems(
      packingListItems.id ?? 0,
      packingListItems.items,
    )

    toast.success('Informação alterada com sucesso')
  }

  async function handleFinishPacking() {
    setIsSavingPackingList(true)
    try {
      await finishiPacking(id)
      toast.success('Romaneio finalizado com sucesso')
      navigate('/dashboard/produtos')
      setIsSavingPackingList(false)
    } catch (error: any) {
      setIsSavingPackingList(false)
      toast.error(error.message)
    }
  }

  const hasSomeProductUnchecked = packingListItems?.items?.some(
    (item) => !item.checked,
  )

  async function handleControllCheck() {
    const newPackingListItems = { ...packingListItems }

    if (hasSomeProductUnchecked) {
      newPackingListItems.items.forEach((product) => (product.checked = true))
    } else {
      newPackingListItems.items.forEach((product) => (product.checked = false))
    }
    setPackingListItems(newPackingListItems)
    toast.success('Informação alterada com sucesso')
    await updatePackingListItems(
      packingListItems.id ?? 0,
      newPackingListItems.items,
    )
  }

  async function handlePrint() {
    // eslint-disable-next-line prefer-const
    let csv =
      'Produto;Categoria;Controle;Quantidade Disponível;Quantidade a ser levada;\n'
    try {
      // eslint-disable-next-line prefer-const
      for (let product of packingListItems.items) {
        csv += `${product.name};${product.category_name};${product.registered_quantity};${product.available_quantity};${product.quantityForReposition};\n`
      }

      const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      saveAs(csvData, `Lista de Romaneio - ${packingListItems.unit_name}.csv`)
      toast.success('Lista de romaneio salva com sucesso!')
    } catch (error: any) {
      toast.error('Houve um erro ao imprimir a lista de romaneio.')
    }
  }

  return {
    getPackingList,
    isLoadingPackingListItems,
    packingListItems,
    handleDeleteProduct,
    onChangeControl,
    onChangeRepositionAmount,
    onChangeAvailableAmount,
    handleFinishPacking,
    handlePrint,
    onChangeStatus,
    handleControllCheck,
    hasSomeProductUnchecked,
    onUpdatePackingListItem,
    isSavingPackingList,
  }
}

import moment from 'moment'

export default function TableColumns(carts: any) {
  const columns = [
    {
      name: 'user_name',
      label: 'Usuário',
      options: {
        customBodyRender: (_, table) => {
          const { user_name, user_last_name } = carts[table.rowIndex]
          return `${user_name} ${user_last_name ?? ''}`
        },
      },
    },
    {
      name: 'nome_unidade',
      label: 'Unidade',
    },
    {
      name: 'opened_cart_date',
      label: 'Data de abertura',
      options: {
        customBodyRender: (value) => {
          return moment(value).format('DD/MM/YYYY - HH:mm')
        },
      },
    },
    {
      name: 'expiress_cart_date',
      label: 'Data de expiração',
      options: {
        customBodyRender: (value) => {
          return moment(value).format('DD/MM/YYYY - HH:mm')
        },
      },
    },
    {
      name: 'total_value',
      label: 'Valor total',
    },
  ]

  return columns
}

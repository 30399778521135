export const multiSelectStyles = {
  placeholder: (styles) => ({ ...styles, fontSize: '0.875rem' }),
  input: (styles) => ({
    ...styles,
    padding: '0',
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #dee2e6',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #dee2e6',
    },
    '&:focus-within': {
      border: '1px solid #86b7fe',
      outline: 'none',
      boxShadow: '0 0 0 0.25rem rgba(13,110,253,.25)',
    },
  }),
  noOptionsMessage: (styles) => ({ ...styles, fontSize: '0.875rem' }),
}

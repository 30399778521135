import SearchIcon from '@mui/icons-material/Search'
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
} from 'mui-datatables'
import { Form } from 'react-bootstrap'
import { DEFAULT_TABLE_OPTIONS } from './config'
import { Clear } from '@material-ui/icons'
import { getMuiTheme } from '../../styles/MuiTheme'
import { MuiThemeProvider } from '@material-ui/core'

interface ITableProps {
  data: any[]
  columns: MUIDataTableColumn[]
  options?: Partial<MUIDataTableOptions>
  searchText?: string
  setSearchText?: (text: string) => void
  showSearchComponent?: boolean
  searchPlaceholder?: string
  onClear?: () => void
}

export default function Table({
  columns,
  data,
  options,
  searchText,
  setSearchText,
  showSearchComponent = true,
  searchPlaceholder = 'Pesquisar',
  onClear,
}: ITableProps) {
  const theme = getMuiTheme()
  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{ ...DEFAULT_TABLE_OPTIONS, ...options }}
        title={
          showSearchComponent && (
            <>
              <div
                style={{
                  order: -1,
                  maxWidth: '400px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                <Form.Control
                  placeholder={searchPlaceholder}
                  type="text"
                  size="sm"
                  value={searchText}
                  onChange={(e) =>
                    setSearchText && setSearchText(e.target.value)
                  }
                />
                {searchText && (
                  <Clear
                    style={{ color: 'rgba(0, 0, 0, 0.54)', cursor: 'pointer' }}
                    onClick={onClear}
                  />
                )}
              </div>
            </>
          )
        }
      />
    </MuiThemeProvider>
  )
}

import { Modal } from 'react-bootstrap'
import Button from '../../../Components/Button'
import { ISupplier } from '../types'
import { useMutation } from '@tanstack/react-query'
import { deleteSupplier } from '../services'
import { queryClient } from 'lib/queryClient'

interface DeleteSupplierModalProps {
  isOpen: boolean
  onClose: () => void
  supplier: ISupplier | null
}

export function DeleteSupplierModal({
  isOpen,
  onClose,
  supplier,
}: DeleteSupplierModalProps) {
  const mutateDeleteSupplier = useMutation((id: number) => deleteSupplier(id))

  function handleDeleteSupplier() {
    mutateDeleteSupplier.mutate(supplier?.id || 0, {
      onSuccess: () => {
        queryClient.invalidateQueries(['/backoffice/suppliers'])
        onClose()
      },
    })
  }

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Excluir fornecedor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja excluir o fornecedor {supplier?.name}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} disabled={mutateDeleteSupplier.isLoading}>
          Cancelar
        </Button>
        <Button
          color="error"
          onClick={handleDeleteSupplier}
          loading={mutateDeleteSupplier.isLoading}
          disabled={mutateDeleteSupplier.isLoading}
        >
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import styled from 'styled-components'

export const DragAndDropContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dashed #dee2e6;
  border-spacing: 5px;
  min-height: 200px;

  & label {
    width: 100%;
    heigth: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    text-align: center;

    & > svg {
      width: 36px;
      height: 36px;
    }

    & > span {
      font-size: 18px;
      margin-top: 10px;
    }
  }
`

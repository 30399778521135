import styled from 'styled-components'

export const Button = styled.button`
  color: #fff !important;
  transition: all 0.3s ease 0s;
  margin: 0.5em;
  background: ${(props) => props.theme.colors.primaryColor};
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

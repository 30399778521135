import { Control, useController } from 'react-hook-form'
import { UncontrolledAutocomplete } from '../Uncontrolled/Autocomplete'

interface AutocompleteOption {
  value: number
  label: string
}

interface AutocompleteProps {
  label?: string
  name: string
  control: Control<any, any>
  placeholder: string
  onSelectOption: (value: number) => void
  options: AutocompleteOption[]
  hintText: string
  isLoadingInfo: boolean
}

export function Autocomplete({
  label,
  name,
  control,
  placeholder,
  onSelectOption,
  options,
  hintText = 'Digite alguma coisa para continuar',
  isLoadingInfo,
  ...rest
}: AutocompleteProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })

  return (
    <UncontrolledAutocomplete
      hintText={hintText}
      isLoadingInfo={isLoadingInfo}
      onSelectOption={onSelectOption}
      options={options}
      placeholder={placeholder}
      error={error}
      label={label}
      {...field}
      {...rest}
    />
  )
}

import styled from 'styled-components'

export const MainInfoContainer = styled.div`
  margin: 10px;

  > div {
    margin-bottom: 15px;
  }
`

export const ProductsInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  > div {
    display: flex;
    gap: 5px;

    > div > span:first-child {
      margin-right: 5px;
      color: red;
    }

    & span:last-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const HighLightProducts = styled.ul`
  padding: 0;
  margin: 15px 0 5px 0;
  max-height: 160px;
  width: 500px;
  overflow: auto;
  padding-left: 30px;

  li {
    margin-bottom: 5px;

    a {
      text-decoration: none;
      color: initial;
    }
  }
`

import { Form, Modal } from 'react-bootstrap'
import { FormEvent } from 'react'
import { useGlobalInfos } from '../../../../../hooks/useGlobalInfos'
import Button from '../../../../../Components/Button'
import { useInventoryList } from '../../hooks/useInventoryList'

interface CreateInventoryModalProps {
  isOpen: boolean
  handleCloseModal: () => void
}

export function CreateInventoryModal({
  isOpen,
  handleCloseModal,
}: CreateInventoryModalProps) {
  const { units } = useGlobalInfos()
  const { isCreatingNewInventory, handleCreateNewInventory } =
    useInventoryList()

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const unit_id = formData.get('unit-select')

    await handleCreateNewInventory(unit_id as string)
  }

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Criar novo inventário</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Label htmlFor="unit-select">Selecione a unidade</Form.Label>
          <Form.Select size="sm" name="unit-select">
            <option disabled selected value={-1}>
              Selecione uma opção
            </option>
            {units.map((option) => (
              <option value={option.id} key={option.id}>
                {option.nome}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            type="submit"
            color="green"
            loading={isCreatingNewInventory}
            disabled={isCreatingNewInventory}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

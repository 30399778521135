import moment from 'moment/moment'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  notaFiscalStatusMap,
  paymentStatusMap,
} from '../../utils/transactionStatus'
moment.locale('pt-br')

interface ITransitionsModal {
  transaction: any
  onHide: () => void
  show: boolean
  handleEmitNFce: (currentTransaction) => Promise<void>
  isEmitNFceRequestActive: boolean
  handleChangeTransactionStatus: (transaction_id, status) => Promise<void>
  isChangingTransactionStatus: boolean
  handleDownloadXml: (url: string) => Promise<void>
  // handleCloseModal: () => void;
}

const styles = {
  modal: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: 'auto',
    // textAlign: 'left',
  },
  text: {
    marginLeft: '10px',
  },
}

export default function TransModal({
  transaction,
  onHide,
  show,
  handleEmitNFce,
  isEmitNFceRequestActive,
  handleChangeTransactionStatus,
  isChangingTransactionStatus,
  handleDownloadXml,
}: ITransitionsModal) {
  const canShowPayButton = (status: string) =>
    status === 'waiting_payment' ||
    status === 'refused' ||
    status === 'canceled'

  const canShowCancelButton = (status: string) => status !== 'canceled'

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Dados da Transação
        </Modal.Title>
      </Modal.Header>
      <div style={styles.modal}>
        <Modal.Body>
          <Form noValidate id="productForm">
            <Row>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>ID Pagarme:</strong>{' '}
                </Form.Label>
                <span style={styles.text}>{transaction.id_pagarme}</span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Nome:</strong>
                </Form.Label>
                <span style={styles.text}>
                  {transaction.nome} {transaction?.sobrenome || ''}
                </span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>CPF:</strong>
                </Form.Label>
                <span style={styles.text}>{transaction.CPF}</span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Email:</strong>
                </Form.Label>
                <span style={styles.text}>{transaction.email_usuario}</span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Telefone:</strong>
                </Form.Label>
                <span style={styles.text}>{transaction.telefone_usuario}</span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <div style={{ display: 'flex' }}>
                  <div>
                    <Form.Label>
                      <strong>Status:</strong>{' '}
                    </Form.Label>
                    <span style={styles.text}>
                      {paymentStatusMap.get(transaction.status)}
                    </span>
                  </div>
                  <div style={{ marginLeft: '5px', marginRight: '5px' }} />
                  {canShowPayButton(transaction.status) && (
                    <Button
                      variant="success"
                      onClick={() =>
                        handleChangeTransactionStatus(transaction.id, 'paid')
                      }
                      size="sm"
                      disabled={isChangingTransactionStatus}
                    >
                      {isChangingTransactionStatus
                        ? 'Dando baixa...'
                        : 'Dar baixa'}
                    </Button>
                  )}
                  <div style={{ marginLeft: '5px', marginRight: '5px' }} />
                  {canShowCancelButton(transaction.status) && (
                    <Button
                      variant="danger"
                      onClick={() =>
                        handleChangeTransactionStatus(
                          transaction.id,
                          'canceled',
                        )
                      }
                      size="sm"
                      disabled={isChangingTransactionStatus}
                    >
                      {isChangingTransactionStatus
                        ? 'Cancelando...'
                        : 'Cancelar'}
                    </Button>
                  )}
                </div>
              </Form.Group>{' '}
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Data:</strong>{' '}
                </Form.Label>
                <span style={styles.text}>
                  {moment(transaction.data).format('DD/MM/yyyy')}
                </span>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Lista de produtos:</strong>{' '}
                </Form.Label>
              </Form.Group>
              {transaction?.descricao?.split('||').map((product, i) => {
                return <p key={product}>{product}</p>
              })}
              <Form.Group as={Col} md="12">
                <Form.Label>
                  <strong>Status Fiscal:</strong>{' '}
                </Form.Label>
                <span style={styles.text}>
                  {notaFiscalStatusMap.get(transaction.nota_status) ||
                    'Nota não emitida'}
                </span>
              </Form.Group>
              <Form.Group as={Col}>
                <div style={{ display: 'flex' }}>
                  {!transaction.nota_status && (
                    <Button
                      variant="success"
                      onClick={() => handleEmitNFce(transaction)}
                      size="sm"
                      disabled={isEmitNFceRequestActive}
                    >
                      {isEmitNFceRequestActive
                        ? 'Emitindo nota...'
                        : 'Emitir nota'}
                    </Button>
                  )}
                  {transaction.nota_pdf && (
                    <Button
                      variant="success"
                      onClick={() =>
                        window.open(transaction.nota_pdf, '_blank')
                      }
                      size="sm"
                    >
                      Visualizar pdf
                    </Button>
                  )}
                  <div style={{ marginLeft: '5px', marginRight: '5px' }} />
                  {transaction.nota_pdf && (
                    <Button
                      variant="success"
                      onClick={() => handleDownloadXml(transaction.nota_xml)}
                      size="sm"
                    >
                      Visualizar xml
                    </Button>
                  )}
                </div>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button onClick={onHide} variant="custom">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { PaginatedCartsListResponse } from 'pages/NewNotification/types'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import api from 'services/api'

export function useOpenedCarts() {
  const [loading, setLoading] = useState(false)
  const [openedCarts, setOpenedCarts] = useState(
    {} as PaginatedCartsListResponse,
  )
  const [isOpenCartDetails, setIsOpenCartDetails] = useState(false)
  const [selectedCart, setSelectedCart] = useState(null)
  const [isDeletingCart, setIsDeletingCart] = useState(false)
  const [isExportingCarts, setIsExportingCarts] = useState(false)

  const getAllOpenedCarts = useCallback(async () => {
    const { search } = window.location
    try {
      setLoading(true)
      const response = await api.get(`/cart/opened-carts${search}`)
      setOpenedCarts(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      toast.error('Erro ao listar carrinhos abertos.')
    }
  }, [])

  return {
    getAllOpenedCarts,
    openedCarts,
    loading,
    setIsOpenCartDetails,
    isOpenCartDetails,
    selectedCart,
    setSelectedCart,
    isDeletingCart,
    setIsDeletingCart,
    isExportingCarts,
    setIsExportingCarts,
  }
}

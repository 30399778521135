import api from '../../../services/api'
import {
  IPackingListResponse,
  IProduct,
  PaginatedProductListResponse,
} from '../types'

export async function getAllProducts(params?: string) {
  try {
    const response = await api.get<PaginatedProductListResponse>(
      `/backoffice/products${params}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao buscar produtos')
  }
}

export async function getQrCodeProductsList() {
  try {
    const response = await api.get(`/backoffice/products/qr-code`, {
      // headers: {
      //   responseType: 'arraybuffer',
      //   'Content-Type': 'application/octet-stream',
      // },
    })
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao buscar produtos')
  }
}

export async function getAllProductsByName(name: string, unit_id = '') {
  const query = `name=${name}&unit_id=${unit_id}`
  try {
    const response = await api.get<IProduct[]>(
      `/backoffice/products/get-by-name?${query}`,
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao buscar produtos')
  }
}

export async function createNewProduct(data: FormData) {
  try {
    const response = await api.post('/backoffice/products', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return response.data
  } catch (error) {
    console.log('error')
    throw new Error('Houve um erro ao criar novo produto')
  }
}

export async function updateProduct(id: number, data: FormData) {
  try {
    const response = await api.patch<IProduct[]>(
      `/backoffice/products/${id}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao editar o produto')
  }
}

export async function deleteProduct(id: number) {
  try {
    return await api.delete(`/backoffice/products/${id}`)
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao excluir o produto')
  }
}

export async function removeProductFromUnit(id: number) {
  try {
    return await api.delete(`/backoffice/products/remove-from-unit/${id}`)
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao excluir o produto da unidade selecionada')
  }
}

export async function exportProductList(unit_id: string | null) {
  try {
    const response = await api.post('/backoffice/products/export', {
      unit_id: unit_id ? Number(unit_id) : null,
    })
    return response.data
  } catch (error) {
    console.log(error)
    throw new Error('Houve um erro ao exportar a lista de produtos')
  }
}

export async function getPackingList(unit_id: string) {
  try {
    const response = await api.post<IPackingListResponse>(
      `backoffice/packing-list/create`,
      { unit_id },
    )

    return response.data
  } catch (error: any) {
    throw new Error(
      error.message || 'Houve um erro ao exportar a lista de produtos',
    )
  }
}

import { useEffect, useState } from 'react'
import { MUIDataTableOptions } from 'mui-datatables'
import { Spinner } from 'react-bootstrap'
import DeleteModal from '../../Components/Modal/DeleteModal'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import api from '../../services/api'
import CustomToolbar from './CustomToolbar'
import UnitModal from './UnitModal'
import TableColumns from './UnitsTableColumns'
import Table from '../../Components/Table'

const unitInit = {
  id: -1,
  nome: '',
  rua: '',
  bairro: '',
  cidade: '',
  estado: '',
  endereco_ip: '',
}

export const Units = () => {
  const [modalShow, setModalShow] = useState(false)
  const [delModalShow, setDelModalShow] = useState(false)
  const [units, setUnits] = useState<any[]>([])
  const [unitData, setUnitData] = useState({})
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [searchText, setSearchText] = useState('')

  const loadData = async () => {
    try {
      setLoading(false)
      setUnits([])
      await api.get('/units').then((res) => {
        setUnits(res.data)
        setLoading(true)
      })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleCreate = () => {
    setUnitData(unitInit)
    setEdit(false)
    setModalShow(true)
  }

  const handleEdit = (id: any) => {
    const resId = units.findIndex((res: any) => res.id === id)
    const tmp = units[resId]
    setUnitData(tmp)
    setEdit(true)
    setModalShow(true)
  }

  const handleDel = (id: number) => {
    const resId = units.findIndex((res: any) => res.id === id)
    const tmp = { ...units[resId], index: resId }
    setUnitData(tmp)
    setDelModalShow(true)
  }

  const options: MUIDataTableOptions = {
    filter: false,
    rowsPerPage: 50,
    selectableRows: 'none',
    responsive: 'standard',
    fixedHeader: true,
    viewColumns: false,

    searchText: searchText,
    search: false,

    print: false,
    download: false,
    customToolbar: () => {
      return <CustomToolbar handleCreate={handleCreate} />
    },
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      filename: `${new Date()
        .toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
        .split('/')
        .reverse()
        .join('-')}_${new Date()
        .toLocaleTimeString('pt-BR', {
          minute: '2-digit',
          hour: '2-digit',
        })
        .replace(':', '-')}-categorias-convenmais.csv`,
    },
    textLabels: {
      ...textLabels,
    },
  }

  const columns = TableColumns(units, handleEdit, handleDel)

  return !loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner animation="border" />
    </div>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '100%',
          display: 'flex',
        }}
      >
        <Table
          columns={columns}
          options={options}
          data={units}
          searchText={searchText}
          setSearchText={setSearchText}
          searchPlaceholder="Pesquisar unidade"
        />
      </div>
      <DeleteModal
        data={{ type: 'Unidade', text: 'a unidade', values: unitData }}
        show={delModalShow}
        onHide={() => setDelModalShow(false)}
        parentCallback={loadData}
      />
      <UnitModal
        unitData={unitData}
        show={modalShow}
        onHide={() => setModalShow(false)}
        parentCallback={loadData}
        edit={edit}
      />
    </>
  )
}

import { Form } from 'react-bootstrap'
import DeleteIcon from '@mui/icons-material/Delete'
import basket from '../../../../../assets/images/basketIcon.png'
import { Checkbox } from '@mui/material'
import { IPackingListItems } from '../services'

interface TableColumnsProps {
  onChangeControl: (index: number, value: string) => void
  onChangeRepositionAmount: (index: number, value: string) => void
  onChangeAvailableAmount: (index: number, value: string) => void
  handleDeleteProduct: (id: number) => void
  onChangeStatus: (index: number, checked: boolean) => void
  onUpdatePackingListItem: () => void
  filteredProducts: IPackingListItems[]
  isAdmin: boolean
}

export function TableColumns({
  onChangeControl,
  onChangeRepositionAmount,
  handleDeleteProduct,
  onChangeAvailableAmount,
  onChangeStatus,
  onUpdatePackingListItem,
  filteredProducts,
  isAdmin,
}: TableColumnsProps) {
  return [
    {
      name: 'checked',
      label: 'Status',
      options: {
        customBodyRender: (
          checked: boolean,
          tableMeta: { rowIndex: number },
        ) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return (
            <Checkbox
              onChange={(event) =>
                onChangeStatus(currentProduct.product_id, event.target.checked)
              }
              checked={currentProduct.checked}
            />
          )
        },
      },
    },
    {
      name: 'image_url',
      label: 'Imagem',
      options: {
        customBodyRender: (value: string, tableMeta: { rowData: any[] }) => {
          const rowName = tableMeta.rowData[2]
          return <img src={value || basket} alt={rowName} height="50px" />
        },
      },
    },
    {
      name: 'name',
      label: 'Produto',
    },
    {
      name: 'category_name',
      label: 'Categoria',
    },
    {
      name: 'registered_quantity',
      label: 'Controle',
      options: {
        customBodyRender: (value: string, tableMeta: { rowIndex: number }) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return !isAdmin ? (
            value
          ) : (
            <Form.Control
              type="number"
              size="sm"
              placeholder="Controle"
              onBlur={onUpdatePackingListItem}
              onChange={(e) =>
                onChangeControl(currentProduct.product_id, e?.target.value)
              }
              value={value}
            />
          )
        },
      },
    },
    {
      name: 'available_quantity',
      label: 'Quantidade Disponível',
      options: {
        customBodyRender: (value: string, tableMeta: { rowIndex: number }) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return (
            <Form.Control
              size="sm"
              type="number"
              placeholder="Quantidade Disponível"
              onBlur={onUpdatePackingListItem}
              onChange={(e) =>
                onChangeAvailableAmount(
                  currentProduct.product_id,
                  e?.target.value,
                )
              }
              value={value}
            />
          )
        },
      },
    },
    {
      name: 'quantityForReposition',
      label: 'Quantidade a ser levada',
      options: {
        customBodyRender: (value: string, tableMeta: { rowIndex: number }) => {
          const currentProduct = filteredProducts[tableMeta.rowIndex]
          return (
            <Form.Control
              type="number"
              size="sm"
              placeholder="Quantidade a ser levada"
              onBlur={onUpdatePackingListItem}
              onChange={(e) =>
                onChangeRepositionAmount(
                  currentProduct.product_id,
                  e?.target.value,
                )
              }
              value={value}
            />
          )
        },
      },
    },
    {
      name: 'product_id',
      label: 'Ações',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (id: string) => {
          return (
            <button
              type="button"
              onClick={() => handleDeleteProduct(Number(id))}
              style={{
                border: 'none',
                background: 'none',
                paddingTop: '5px',
              }}
            >
              <DeleteIcon sx={{ color: '#e30000' }} />
            </button>
          )
        },
      },
    },
  ]
}

import { AuthProvider } from './contexts/auth'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './styles/global'
import RoutesApp from './routes'
import { theme } from './styles/theme'
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { getMuiTheme } from './styles/MuiTheme'
import { GlobalInfosProvider } from './contexts/Global'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClient } from 'lib/queryClient'

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <MaterialThemeProvider theme={getMuiTheme}>
        <AuthProvider>
          <GlobalInfosProvider>
            <RoutesApp />
            <ReactQueryDevtools />
          </GlobalInfosProvider>
        </AuthProvider>
        <GlobalStyles />
      </MaterialThemeProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

export default App

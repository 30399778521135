export const paymentStatusMap = new Map([
  ['waiting_payment', 'Aguardando pagamento'],
  ['paid', 'Pago'],
  ['pending_refund', 'Aguardando estorno'],
  ['refunded', 'Estornado'],
  ['failed', 'Falha'],
  ['with_error', 'Com erro'],
  ['gave_up', 'Desistência'],
  ['chargedback', 'Chargedback'],
  ['refused', 'Recusada'],
  ['processing', 'Processando'],
  ['canceled', 'Cancelada'],
])

export const notaFiscalStatusMap = new Map([
  ['1', 'Cadastrada'],
  ['2', 'Emitida'],
  ['3', 'Cancelada'],
  ['3', 'Em processamento'],
  ['4', 'Rejeitada'],
])

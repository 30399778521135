import { Modal } from 'react-bootstrap'
import Button from '../../../../../Components/Button'

interface DeleteInventoryModalProps {
  isOpen: boolean
  handleCloseModal: () => void
  inventory: number
  handleDeleteInventory: (id: number) => void
}

export function DeleteInventoryModal({
  isOpen,
  handleCloseModal,
  inventory,
  handleDeleteInventory,
}: DeleteInventoryModalProps) {
  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Excluir inventário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja excluir o inventário selecionado? Está ação é
        irreversível
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Cancelar</Button>
        <Button color="red" onClick={() => handleDeleteInventory(inventory)}>
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

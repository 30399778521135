import { Modal } from 'react-bootstrap'
import Button from '../Button'
import { IProduct } from '../../pages/Products/types'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import { productFormSchema } from '../../pages/Products/validators/product.schema'
import Tabs from '../Tabs'
import { TabContent } from '../Tabs/TabContent'
import ProductForm from '../../pages/Products/components/ProductForm'
import SelectUnits, { EMPTY_UNIT_FIELDS } from '../SelectUnits'
import { IUnit } from '../../pages/Units/types'
import { toast } from 'react-toastify'
import { useGlobalInfos } from '../../hooks/useGlobalInfos'

interface ProductModalProps {
  isOpen: boolean
  handleCloseModal: () => void
  product?: IProduct | null
  units: IUnit[]
  handleSubmitForm: (data: IProduct) => void
}

export default function ProductModal({
  isOpen,
  handleCloseModal,
  product,
  units,
  handleSubmitForm,
}: ProductModalProps) {
  const { categories } = useGlobalInfos()
  const [selectedTab, setSelectedTab] = useState(0)
  const { ...methods } = useForm<IProduct>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: product ?? {
      ...(product !== null && { product }),
      pis: '49',
      cofins: '49',
      ipi: '53',
      icms: '90',
      units: [EMPTY_UNIT_FIELDS],
    },
    resolver: yupResolver(productFormSchema) as any,
  })

  const UNITS = methods.watch('units')

  function validateProductForm() {
    if (UNITS?.length === 0) {
      setSelectedTab(1)
      return toast.error(
        'É necessário escolher pelo menos 1 unidade para o produto',
      )
    }
  }

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{product ? 'Editar' : 'Cadastrar'} Produto</Modal.Title>
      </Modal.Header>
      <Tabs
        handleChange={setSelectedTab}
        tabs={['Informações principais', 'Unidades']}
        value={selectedTab}
      />
      <Modal.Body>
        <FormProvider {...methods}>
          <TabContent index={selectedTab} value={0}>
            <ProductForm product={product} categories={categories} />
          </TabContent>

          <TabContent index={selectedTab} value={1}>
            <SelectUnits units={units} product={product} />
          </TabContent>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseModal}>Cancelar</Button>
        <Button
          type="submit"
          color="green"
          onClick={methods.handleSubmit(handleSubmitForm, validateProductForm)}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

import { convertCentsToLocale } from '../../utils/money.formater'

export default function TableColumns() {
  const columns = [
    {
      name: 'unidade_nome',
      label: 'Unidade',
    },
    {
      name: 'formated_date',
      label: 'Data',
      options: {
        customBodyRender: (value) => {
          return value.toUpperCase()
        },
      },
    },
    {
      name: 'total',
      label: 'Total (R$)',
      options: {
        filter: false,
        // setCellProps: () => ({ style: { width: '65%' } }),
        customBodyRender: (value: number) => convertCentsToLocale(value),
      },
    },
  ]

  return columns
}

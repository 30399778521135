import * as yup from 'yup'

export const inventoryMovementSchema = yup.object({
  product_name: yup.string().required('Selecione o produto'),
  quantity: yup
    .number()
    .required('Digite a quantidade')
    .moreThan(0, 'Quantidade precisa ser maior que zero.')
    .typeError('Campo inválido'),
  type: yup.string().required('Selecione o tipo'),
  origin: yup.string().required('Selecione a origem'),
  stock: yup.string().required('Escolha o estoque'),
  cost: yup
    .number()
    .moreThan(0, 'Custo deve ser maior que 0')
    .typeError('Campo inválido'),
  supplier_id: yup.number(),
  invoice_number: yup.string(),
  product_expiration_date: yup.string(),
})

export const inventoryMovementsXmlForm = yup.object({
  products: yup
    .array()
    .of(
      yup.object().shape({
        product_name: yup.string().required('Selecione o produto'),
        amount: yup.number().required('Digite a quantidade'),
      }),
    )
    .required('Pelo menos uma produto deve ser adicionado')
    .min(1),
})

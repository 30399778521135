import { Form, Modal } from 'react-bootstrap'
import { useProducts } from '../hooks/useProducts'
import Button from '../../../Components/Button'
import { useGlobalInfos } from '../../../hooks/useGlobalInfos'
import { FormEvent } from 'react'

interface ExportPackingListModalProps {
  isOpen: boolean
  handleCloseModal: () => void
}

export function ExportPackingListModal({
  isOpen,
  handleCloseModal,
}: ExportPackingListModalProps) {
  const { handleExportPackingList, isExportingPackingList } = useProducts()
  const { units } = useGlobalInfos()

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const unit_id = formData.get('unit-select')

    await handleExportPackingList(unit_id as string)
  }

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Gerar Relatório de Romaneio</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Label htmlFor="unit-select">Selecione a unidade</Form.Label>
          <Form.Select size="sm" name="unit-select">
            <option disabled selected value={-1}>
              Selecione uma opção
            </option>
            {units.map((option) => (
              <option value={option.id} key={option.id}>
                {option.nome}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            type="submit"
            color="green"
            loading={isExportingPackingList}
            disabled={isExportingPackingList}
          >
            Gerar lista
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import api from '../../services/api'
import { Check, Block } from '@mui/icons-material'
import { IReports } from './types'
import { toast } from 'react-toastify'
import * as S from './styles'

const Home = () => {
  const [reportsInfo, setReportsInfo] = useState<IReports | null>(null)
  const [
    isOpenAccordionOutOfStockProducts,
    setIsOpenAccordionOutOfStockProducts,
  ] = useState(false)
  const [indexToShowProducts, setIndexToShowProducts] = useState<number | null>(
    null,
  )
  const [loading, setLoading] = useState(false)

  const getMainInfo = async () => {
    setLoading(true)
    try {
      const response = await api.get<IReports>('/backoffice/reports/main-info')
      setReportsInfo(response.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
      toast.error('Erro ao carregar informações do sistema')
    }
  }

  useEffect(() => {
    getMainInfo()
  }, [])

  return (
    <div
      style={{
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
      }}
    >
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}
          className="center"
        >
          <Spinner animation="border" style={{ marginRight: 10 }} />
          Carregando informações do sistema...
        </div>
      ) : (
        <S.MainInfoContainer>
          <div>
            <h1>Informações principais:</h1>
            <p>
              Usuários cadastrados: <strong>{reportsInfo?.usersAmount}</strong>
            </p>
            <p>
              Produtos cadastrados:{' '}
              <strong>{reportsInfo?.productsAmount}</strong>
            </p>
            <p>
              Vendas hoje: <strong>{reportsInfo?.salesOfTheDay}</strong>
            </p>
          </div>

          <div>
            <h1>Informações dos produtos:</h1>
            {reportsInfo?.productsPerUnit.map((product, index) => {
              const isOpenProductList =
                isOpenAccordionOutOfStockProducts &&
                indexToShowProducts === index
              return (
                <S.ProductsInfosContainer key={product.unit_id}>
                  <div>
                    <span>
                      <strong>{product.registered_products}</strong> produtos
                      cadastrados em {product.nome}.{' '}
                    </span>
                    {Number(product.running_out_products) > 0 && (
                      <div>
                        <span style={{ color: 'red' }}>
                          <strong>{product.running_out_products} </strong>
                          produto(s) com contagem negativa nesta unidade.
                        </span>
                        <span
                          onClick={() => {
                            if (isOpenProductList) {
                              setIsOpenAccordionOutOfStockProducts(false)
                              setIndexToShowProducts(null)
                            } else {
                              setIsOpenAccordionOutOfStockProducts(true)
                              setIndexToShowProducts(index)
                            }
                          }}
                        >
                          {isOpenProductList ? 'Ocultar' : 'Ver mais'}
                        </span>
                      </div>
                    )}
                  </div>
                  {isOpenProductList && (
                    <S.HighLightProducts>
                      {product.negative_products.map((item) => (
                        <li key={item}>
                          <a
                            href={`/dashboard/produtos?name=${item}`}
                            target="_blank"
                            rel="noreferrer noopen"
                          >
                            {item}
                          </a>
                        </li>
                      ))}
                    </S.HighLightProducts>
                  )}
                </S.ProductsInfosContainer>
              )
            })}
          </div>

          <div>
            <h1>Status de conexão dos aparelhos:</h1>
            {reportsInfo?.connectionStatus.map((connection) => (
              <Fragment key={connection.unit}>
                <p>
                  <strong>{connection.unit}:</strong>{' '}
                  <span>{connection.message}</span>{' '}
                  {connection.status === 'success' ? (
                    <Check sx={{ color: '#008000' }} />
                  ) : (
                    <Block sx={{ color: '#ff0000' }} />
                  )}
                </p>
              </Fragment>
            ))}
          </div>
        </S.MainInfoContainer>
      )}
    </div>
  )
}

export default Home

import { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { MUIDataTableOptions } from 'mui-datatables'
import { Spinner } from 'react-bootstrap'
import api from '../../services/api'
import Button from '../../Components/Button'
import TableColumns from './components/TransactionsTableColumns'
import TransModal from './components/TransactionDetails'
import Table from '../../Components/Table'
import { useParams } from '../../hooks/useParams'
import { textLabels } from '../../configs/MuiTableOptionsTextLabels'
import { CustomTableFilter } from '../../Components/CustomTableFilter'
import * as S from './styles'
import { TransactionsFilter } from './components/TransactionsFilter'
import { useGlobalInfos } from '../../hooks/useGlobalInfos'

export const Transaction = () => {
  const { units } = useGlobalInfos()
  const { location, navigate, params } = useParams()
  const [transactions, setTransactions] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [selection, setSelection] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [isEmitNFceRequestActive, setIsEmitNFceRequestActive] = useState(false)
  const [isChangingTransactionStatus, setIsChangingTransactionStatus] =
    useState(false)
  const [count, setCount] = useState(0)
  const [isExportingTransactions, setIsExportingTransactions] = useState(false)

  useEffect(() => {
    const { search } = window.location
    const fetchTransactions = async () => {
      setLoading(true)
      const { data } = await api.get(`/backoffice/transactions${search}`)
      setTransactions(data.data)
      setCount(data.amount)
      setLoading(false)
    }
    fetchTransactions()
  }, [location.search])

  async function handleEmitNFce(currentTransaction) {
    setIsEmitNFceRequestActive(true)

    try {
      const response = await api.post('/notafiscal', {
        transaction: currentTransaction,
      })
      const transactionsInfo = transactions.filter(
        (transaction) => transaction.id !== currentTransaction.id,
      )
      setTransactions([...transactionsInfo, response.data.transaction])
      setSelection(response.data.transaction)
      alert('Sucesso. Nota enviada para emissão.')
      setIsEmitNFceRequestActive(false)
    } catch (error) {
      alert('Erro ao emitir nota, tente novamente')
    }
  }

  async function handleExportTransactions() {
    setIsExportingTransactions(true)
    try {
      const response = await api.get('/backoffice/transactions/export-all')
      saveAs(
        `data:application/vnd.ms-excel;base64,${response.data.csv}`,
        'Transações.csv',
      )
      setIsExportingTransactions(false)
    } catch (error) {
      setIsExportingTransactions(false)
      console.log(error)
      window.alert('Erro ao exportar transações.')
    }
  }

  async function handleDownloadXml(url) {
    try {
      const response = await api.get(url)

      const buffer = Buffer.from(response.data.xml.data)

      const blob = new Blob([buffer], { type: 'application/xml' })
      saveAs(blob, response.data.fileName)
    } catch (error) {
      console.log(error)
      window.alert('Erro ao fazer o download do XML.')
    }
  }

  async function handleChangeTransactionStatus(transaction_id, status) {
    setIsChangingTransactionStatus(true)
    try {
      const response = await api.patch(
        `/transaction/change_status/${transaction_id}`,
        { status },
      )
      const transactionsInfo = transactions.filter(
        (transaction) => transaction.id !== transaction_id,
      )
      setTransactions([...transactionsInfo, response.data.transaction])
      setSelection(response.data.transaction)
      alert('Status alterado com sucesso!')
      setIsChangingTransactionStatus(false)
    } catch (error) {
      setIsChangingTransactionStatus(false)
      console.log(error)
    }
  }

  const options: MUIDataTableOptions = {
    page: Number(params.get('page') ?? 1) - 1,
    count: count,
    rowsPerPage: Number(params.get('rowsPerPage')) || 50,
    onChangeRowsPerPage: (value) => {
      params.set('rowsPerPage', String(value))
      navigate(`?${params.toString()}`)
    },
    onChangePage: (page: number) => {
      params.set('page', String(page + 1))
      navigate(`?${params.toString()}`)
    },
    customToolbar: () => (
      <S.TopBar>
        <CustomTableFilter>
          <TransactionsFilter units={units} />
        </CustomTableFilter>
      </S.TopBar>
    ),
    textLabels: {
      ...textLabels,
      body: {
        noMatch: loading ? <Spinner /> : 'Ops... Nenhum registro encontrado.',
      },
    },
  }

  const columns = TableColumns(transactions, setModalShow, setSelection)

  return (
    <>
      <div
        style={{
          width: '100%',
          boxShadow: 'none',
          height: '90%',
          display: 'flex',
        }}
      >
        <Table
          columns={columns}
          data={loading ? [] : transactions}
          options={options}
          showSearchComponent={false}
        />
      </div>
      <Button
        onClick={handleExportTransactions}
        disabled={isExportingTransactions}
      >
        {isExportingTransactions
          ? 'Exportando transações...'
          : 'Exportar transações'}
      </Button>
      {modalShow && (
        <TransModal
          transaction={selection}
          show={modalShow}
          onHide={() => setModalShow(false)}
          handleEmitNFce={handleEmitNFce}
          isEmitNFceRequestActive={isEmitNFceRequestActive}
          handleChangeTransactionStatus={handleChangeTransactionStatus}
          isChangingTransactionStatus={isChangingTransactionStatus}
          handleDownloadXml={handleDownloadXml}
          // handleCancelNfce={handleCancelNfce}
        />
      )}
    </>
  )
}

import { ReactNode } from 'react'
import * as C from './styles'
import { Spinner } from 'react-bootstrap'
interface IButton {
  children: ReactNode
  onClick?: (arg0: any) => void
  type?: 'button' | 'submit' | 'reset' | undefined
  width?: string
  color?: string
  form?: string
  disabled?: boolean
  loading?: boolean
}

function Button({
  children,
  onClick,
  type = 'button',
  width,
  color,
  form,
  disabled,
  loading = false,
}: IButton) {
  return (
    <C.Button
      style={{
        width: width === 'fullWidth' ? '80%' : '',
        background: color === 'error' ? 'red' : color,
      }}
      type={type}
      color={color}
      onClick={onClick}
      form={form}
      disabled={disabled}
    >
      {loading ? (
        <Spinner
          animation="border"
          color="white"
          size="sm"
          style={{ width: '24px', height: '24px' }}
        />
      ) : (
        children
      )}
    </C.Button>
  )
}

export default Button

import { formatCNPJ, formatPhone } from '../../../utils/formats'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import * as S from '../styles'

interface TableColumnsProps {
  onEdit: (id: number) => void
  onDelete: (id: number) => void
}

export function TableColumns({ onEdit, onDelete }: TableColumnsProps) {
  return [
    {
      name: 'name',
      label: 'Nome',
    },
    {
      name: 'document',
      label: 'CNPJ',
      options: {
        customBodyRender: (value: string) => formatCNPJ(value || ''),
      },
    },
    {
      name: 'cellphone',
      label: 'Telefone',
      options: {
        customBodyRender: (value: string) => formatPhone(value || ''),
      },
    },
    {
      name: 'email',
      label: 'Email',
    },
    {
      name: 'seller_name',
      label: 'Vendedor',
    },
    {
      name: 'seller_cellphone',
      label: 'Telefone vendedor',
      options: {
        customBodyRender: (value: string) => formatPhone(value || ''),
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        customBodyRender: (value: number) => {
          return (
            <S.TableActionsButton>
              <S.TableActionButton onClick={() => onEdit(value)}>
                <EditIcon />
              </S.TableActionButton>
              <S.TableActionButton onClick={() => onDelete(value)}>
                <DeleteIcon color="error" />
              </S.TableActionButton>
            </S.TableActionsButton>
          )
        },
      },
    },
  ]
}

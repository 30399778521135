import { format } from 'date-fns'
import { formatCPF } from '../../../../utils/formats'

export function AccessControlTableColumns() {
  return [
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        customBodyRender: (value) =>
          format(new Date(value), 'dd/MM/yyyy H:mm:s'),
      },
    },
    {
      name: 'name',
      label: 'Usuário',
      options: {
        filter: false,
      },
    },
    {
      name: 'cpf',
      label: 'CPF',
      options: {
        customBodyRender: (value) => formatCPF(value),
        filter: false,
      },
    },
    {
      name: 'unit_name',
      label: 'Unidade',
      options: {
        filter: false,
      },
    },
    {
      name: 'access',
      label: 'Situação',
      options: {
        filter: false,
      },
    },
    {
      name: 'picture',
      label: 'Foto',
      options: {
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noreferrer">
            Ver Foto
          </a>
        ),
        filter: false,
      },
    },
  ]
}

import { Modal } from 'react-bootstrap'
import api from '../../services/api'
import Button from '../Button'
export default function DeleteModal(props: any) {
  // reutilizar o mesmo form para deletar qualquer elemento do banco de dados!
  const handleDelete = () => {
    function deleteProduct() {
      api.delete(`backoffice/products/${props.data.values.id}`).then(() => {
        // console.log(res)
        props.parentCallback(props.data.values)
      })
    }

    function deleteCategory() {
      api.delete(`/categories/${props.data.values.id}`).then(() => {
        // console.log(res)
        props.parentCallback()
      })
    }

    function deleteUnit() {
      api.delete(`/units/${props.data.values.id}`).then(() => {
        // console.log(res)
        props.parentCallback()
      })
    }

    function deletePromo() {
      api
        .patch(`products-promo/${props.data.values.id}`, {
          promo_preco: null,
          promo_inicio: null,
          promo_fim: null,
        })
        .then(() => {
          // console.log(res)
          props.parentCallback()
        })
    }

    if (props.data.type === 'Produto') deleteProduct()
    else if (props.data.type === 'Categoria') deleteCategory()
    else if (props.data.type === 'Unidade') deleteUnit()
    else if (props.data.type === 'Promocao') deletePromo()

    props.onHide()
  }

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title>Deletar {props.data.type}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza que deseja excluir {props.data.text} &apos;
        {props.data.values?.nome}&apos;?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancelar</Button>
        <Button color="error" onClick={handleDelete}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

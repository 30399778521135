import styled from 'styled-components'
import { Typography } from '@mui/material'

export const Title = styled(Typography)`
  && {
    font-size: 3rem;
    font-family: 'Pompiere', cursive;
    font-weight: 500;
    line-height: 1.2;
    display: inline-block;
  }
`

import React, { InputHTMLAttributes } from 'react'
import { Form } from 'react-bootstrap'
import { Control, FieldError, Path, useController } from 'react-hook-form'
import {
  convertCentsToLocale,
  convertComa,
} from '../../../utils/money.formater'

interface InputMoneyProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
  onChangeCallback?: () => void
}

export default function InputMoney({
  name,
  control,
  label,
  error,
  onChangeCallback,
  ...rest
}: InputMoneyProps) {
  const {
    field: { onChange, ...fieldProps },
  } = useController({
    name,
    control,
  })

  const formatBRL = (value: string) => {
    const onlyDigits = Number(value.toString().replace(/\D+/g, ''))

    return convertCentsToLocale(onlyDigits)
  }

  const formatValue = (valueFormat: string) => {
    const rawValue = convertComa(valueFormat)
    const reApplyedMask = formatBRL(rawValue)
    return convertComa(reApplyedMask)
  }

  function handleChange({ target: { value } }) {
    const formatedValue = formatValue(value)
    onChange(Math.round(Number(formatedValue) * 100))
    return onChangeCallback?.()
  }

  return (
    <Form.Group>
      {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      <Form.Control
        isInvalid={!!error}
        {...fieldProps}
        {...rest}
        type="text"
        size="sm"
        onChange={handleChange}
        value={formatBRL(fieldProps.value || 0)}
      />
      {!!error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

import { type InputHTMLAttributes } from 'react'
import { Form } from 'react-bootstrap'
import {
  type Control,
  Controller,
  type FieldError,
  Path,
} from 'react-hook-form'

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<any>
  control: Control<any, any>
  label: string
  error?: FieldError | null
  options: { [key: string]: any }[]
  onChangeCallback?: (value) => void
}

export default function Select({
  name,
  control,
  label,
  error,
  options,
  onChangeCallback,
  ...rest
}: TextFieldProps) {
  return (
    <Form.Group>
      {!!label && <Form.Label htmlFor={name}>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Form.Select
            isInvalid={!!error}
            {...(field as any)}
            onChange={(event) => {
              field.onChange(event)
              onChangeCallback?.(event.target.value)
            }}
            {...(rest as any)}
            size="sm"
          >
            <option disabled selected value={-1}>
              Selecione uma opção
            </option>
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        )}
      />
      {!!error && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

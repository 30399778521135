import { useCallback, useContext, useEffect } from 'react'
import { GlobalInfosContext } from '../contexts/Global'
import { toast } from 'react-toastify'
import api from '../services/api'

export function useGlobalInfos() {
  const { units, setUnits, categories, setCategories } =
    useContext(GlobalInfosContext)

  const getAllUnits = useCallback(async () => {
    if (units.length === 0) {
      try {
        const response = await api.get('/units')
        setUnits(response.data)
      } catch (error) {
        return toast.error('Erro ao listar todas as unidades')
      }
    }
  }, [setUnits, units])

  const getAllCategories = useCallback(async () => {
    if (categories.length === 0) {
      try {
        const response = await api.get('/categories')
        setCategories(response.data)
      } catch (error) {
        return toast.error('Erro ao listar todas as unidades')
      }
    }
  }, [categories, setCategories])

  useEffect(() => {
    getAllUnits()
  }, [getAllUnits])

  useEffect(() => {
    getAllCategories()
  }, [getAllCategories])

  return {
    units,
    categories,
  }
}
